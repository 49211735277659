/**
 * Used to load files in the system in a collection called a library.
 * The loading / cleanup of the library operates in a reference counting
 * garabage collection fashion.  A file is only removed from the system
 * once there are no longer any libraries referencing that file.
 *
 * To load a library you call loadLibraryDependencies passing in
 * an array of dependencies and a callback that you want executed
 * once all of the libraries have loaded.  The function returns
 * a token that represents that collection of files.  When you no longer
 * need the library you must call removeDependenciesLoaded and pass in
 * the token that you were given.
 *
 * This operates similar to C''s malloc and free principle.
 * Example:
 * <code>
 * function runCkEditor(libraryResult) {
 * 		if (libraryResult.status == 'SUCCESS')
 * 		{
 * 			var ckeditorLoader = new CKEditorLoader();
 * 			// execute rest of code here...
 * 		}
 * 		else
 * 		{
 * 			var fileResults = libraryResult.files;
 * 			gaerdvark.utils.forEachArray(filterResults, function(item) {
 * 				console.log(item.getStatus()); // returns SUCCESS or FAILED
 * 				console.log(item.getFile()); // returns name of the file
 * 				console.log(item.getErrorMessage()); // returns the error message if available.
 * 				// you can also call item.failed() to know if it failed to
 * 				// load or not instead of checking the status
 * 			});
 * 		}
 * }
 *
 * var libraryToken = ...;
 *
 * me.construct = function() {
 *
 *  // load up the files.
 * 	var dependencies = ['/_library/' + _SESSION.currentVersion + '/ckeditor/ckeditor.js',
 * 					 '/_library/' + _SESSION.currentVersion + '/ckeditor/CKEditorLoader.js'];
 *	var libraryToken = FileLoadingManager.loadLibraryDependencies(dependencies, runCkEditor);
 *
 * }
 *
 *  me.destruct = function() {
 *      // make sure the files are removed.
 *  	FileLoadingManager.removeDependenciesLoaded(libraryToken);
 *  }
 * </code>
 *
 * Note: javascript files will have their script tags removed but
 * 		 for now, their actual code continues to reside in memory.
 */
var FileLoadingManager = new function() {

	/**
	 * Keeps track of files it is currently attempting to load
	 */
	var pendingRequestItems = {};

	/**
	 * Keeps track of files it has already loaded.
	 */
	var filesLoaded = {};

	/**
	 * Pointers to the currently loading requests.
	 */
	var pendingLoadRequests = {};

	// save this off so that we can reference it.
	var head = document.getElementsByTagName('head')[0];

	/**
	 * Keeps track of the library requests that have been loaded.
	 * which then allows the entire collection to be unloaded when finished.
	 */
	var loadedRequests = {};

	/**
	 * Constant for the stylesheet file type being requested
	 */
	var REQUEST_TYPE_STYLESHEET = 'style';

	/**
	 * Constant for the script file type being requested
	 */
	var REQUEST_TYPE_SCRIPT = 'script';

	/**
	 * Failure error status constant
	 */
	var STATUS_LOAD_FAILED = 'failed';
	this.FAILED = STATUS_LOAD_FAILED;

	/**
	 * Success error status constant
	 */
	var STATUS_LOAD_SUCCESS = 'success';
	this.SUCCESS = STATUS_LOAD_SUCCESS;


	//
	//
	/**
	 * take a src url for a script and make it a valid javascript resource.
	 * also make it fixed to a absolute path.
	 */
	function formatScriptSrc(src)
	{
		if (src.search(/\.js/) == -1) {
			src = src + '.js';
		}

		if (src.indexOf("http") != 0
			&& src.indexOf('/') != 0)
		{
			src = '/' + src;
		}
		return src;
	}

	/**
	 * take a src url for a script and make it fixed to a absolute path.
	 */
	function formatStylesheetHref(href)
	{
		if (href.indexOf("http") != 0
				&& href.indexOf('/') != 0)
		{
			href = '/' + href;
		}

		return href;
	}

	/**
	 * Add the load events for the dom node that is loading the passed
	 * in src parameter.  When complete pass a FileLoadResult object to the callBack
	 * function passed in.
	 * @param src the URL of the resource being loaded
	 * @param domNode the dom node representing the resource being loaded such as a link or script tag.
	 * @param callBack the function call to call once loading has completed or failed.
	 */
	function addLoadEventsToNode(src, domNode, callBack)
	{
		/**
		 * function can be called as just an event, or a message
		 * if it is called using onerror it has three parameters
		 * otherwise just the one
		 * see https://developer.mozilla.org/en-US/docs/DOM/window.onerror
		 */
		domNode.scriptLoaded = function(evt, url, lineNumber) {

			var errorMessage = null;

			if (evt.type == null)
			{
				errorMessage = evt;
			}

			var status = STATUS_LOAD_FAILED;

			if(evt == null || evt.type == null) {
				throwError(evt + " " + src);
			}else if(evt.type == "error") {
				errorMessage = "Failed to retrieve resource " + src;

				if (url) {
					errorMessage += " in " + url;
				}

				if (lineNumber)
				{
					errorMessage += " line " + lineNumber;
				}

				throwError(errorMessage);
			}
			else
			{
				status = STATUS_LOAD_SUCCESS;
			}

			var result = new FileLoadResult(src, status, errorMessage);

			callBack(result);
		};


		// Can read more cross browser loading of scripts/resources at this blog
		// http://www.nczonline.net/blog/2009/06/23/loading-javascript-without-blocking/

		// setup onload listener
		if (domNode.readyState) {
			domNode.onreadystatechange = function() {
				if (/loaded|complete/.test(this.readyState)) {
					//event is a global in IE for the current event
					domNode.onreadystatechange = null; // remove the event handler so we don't process it twice.
					this.scriptLoaded(event);
				}
			};
		}
		else
		{
			domNode.onload = domNode.scriptLoaded;
			domNode.onerror = domNode.scriptLoaded;
		}
	}

	/**
	 * Returns a function callback to be used in loading the raw script or stylesheet
	 * file.  It curries the originalFileLoadCallback in order to clean up the dom
	 * if the file fails to load, or setup our LoadedFile result when the file finishes.
	 * @param the raw fileURL that is being requested,
	 * @param the dom node that was appended to the DOM for the resource to load
	 * @param the original file callback that was asked to be executed when loading this file.
	 * @return function
	 */
	function getRawFileLoadCallback(fileURL, domNode, originalFileLoadCallback)
	{
		return function(loadResult) {
			if (loadResult.getStatus() == STATUS_LOAD_SUCCESS)
			{
				// store off the fully qualified file name.
				var loadedFile = new LoadedFile(loadResult.getFile(), domNode);
				filesLoaded[fileURL] = loadedFile;
			}
			else
			{
				// clean up the script tag that failed to load.
				domNode.parentNode.removeChild(domNode);
			}
			originalFileLoadCallback(loadResult);
		}
	}

	/**
	 * @private
	 * Used to append a script tag to the document
	 */
	function addScript(requestItem, callBack) {

		var src = formatScriptSrc(requestItem.src);

		var currentScript = document.createElement('script');
		currentScript.type = 'application/javascript';
		currentScript.src = src;

		// create an object to track this css
		head.appendChild(currentScript);

		addLoadEventsToNode(currentScript.src, currentScript,
				getRawFileLoadCallback(src, currentScript, callBack));
	}

	/**
	 * Appends a style tag to the dom and sets up event listeners
	 * to call the callBack when it loads / fails to load.
	 */
	 function addStylesheet(requestItem, callBack) {

		 var cssURL = formatStylesheetHref(requestItem.src);

		// add the css link to the end of head
		var newCSSLink = document.createElement('link');
		newCSSLink.setAttribute('href', cssURL);
		newCSSLink.setAttribute('rel', 'stylesheet');
		newCSSLink.setAttribute('type', 'text/css');

		addLoadEventsToNode(newCSSLink.href, newCSSLink,
				getRawFileLoadCallback(cssURL, newCSSLink, callBack));

		// create an object to track this css
		head.appendChild(newCSSLink);
	}

	/**
	 * Reference Count tracker for a loaded file
	 * Keeps track of how many times the file is being referenced by libraries.
	 */
	function LoadedFile(fileURL, domNode)
	{
		var referenceCount = 0;

		/**
		 * Increment the reference count for the file we have loaded.
		 */
		function incrementCount()
		{
			referenceCount++;
		}

		/**
		 * Decrement the reference count for the loaded file.
		 */
		function decrementCount()
		{
			referenceCount--;
		}

		function getCount()
		{
			return referenceCount;
		}

		/**
		 * Can the file be removed, ie there are no other resources referencing this file.
		 */
		function canBeRemoved()
		{
			return referenceCount < 1;
		}

		/**
		 * Grab the url that this file corresponds to.
		 * Note this is the fully qualified url that contains the schema and domain name.
		 */
		function getURL()
		{
			return fileURL;
		}

		/**
		 * returns the dom node that was loaded for this file.
		 */
		function getDomNode()
		{
			return domNode;
		}

		/**
		 * clears the domNode
		 */
		function clearDomNode() {
			domNode = null;
		}

		return {
			incrementCount: incrementCount
			,decrementCount: decrementCount
			,getCount: getCount
			,getURL: getURL
			,getDomNode: getDomNode
			,canBeRemoved: canBeRemoved
			,clearDomNode: clearDomNode
		};
	}

	/**
	 * Object for holding the status result of a file that has loaded.
	 * If the file has failed to load then getErrorMessage will be populated.
	 * You can call failed() as a shortcut instead of directly checking the status variable
	 * to know if the file failed to load.
	 */
	function FileLoadResult(fileURL, status, errorMessage)
	{
		return {
			getFile: function() { return fileURL; }
			,getStatus: function() { return status }
			,failed: function() { return status !== STATUS_LOAD_SUCCESS }
			,getErrorMessage: function() { return errorMessage; }
		}
	}


	/**
	 * Represents a resource request item.  Used for holding information about the
	 * individual item requested.
	 */
	function RequestItem(src, type)
	{
		/**
		 * Dom node of the item that was loaded for this request.
		 */
		var domNode;

		/**
		 * Tracks the callbacks that will be executed
		 * when the request has been loaded.
		 */
		var callbacks = [];

		/**
		 * Keep track of how many times the request has been made
		 */
		var requestCount = 1;

		/**
		 * FileLoadResult object that contains status and error information for the load.
		 */
		var fileLoadResult = null;

		/**
		 * LoadedFile object that is a reference pointer to the unique resource
		 */
		var loadedFileReference = null;

		/**
		 * Whether the request item has already finished processing or not, such as failing to load,
		 * or load was successful.
		 */
		var processed = false;

		return {
			src: src
			,processed: processed
			,type: type
			,callbacks: callbacks
			,requestCount: requestCount
			,fileLoadResult: fileLoadResult
			,loadedFileReference: loadedFileReference
		};
	}

	/**
	 * Represents the entire collection that was asked to be loaded.  Contains
	 * information such as the callback to call when the entire request has finished
	 * processing.  Has commands for loading and unloading the request.
	 */
	function LoadRequest(requestItems, callBack)
	{
		var me = this;

		/**
		 * have a unique id we can refer to.
		 */
		this.id = gaerdvark.utils.uuid();

		/**
		 * array of Request Items
		 */
		var items = requestItems;

		/**
		 * final request to notify when the file has loaded.
		 */
		var requestCallback = callBack;

		/**
		 * loops through all request items and checks if they are loaded
		 * @returns true if all of the requests have loaded, false otherwise.
		 */
		function allRequestsLoaded(items)
		{

			var stillLoading = gaerdvark.utils.someArray(items, function(item) {
				return item.processed === false;
			});

			return !stillLoading;
		}

		/**
		 * an item is loaded, check if all the requests are loaded
		 */
		function processRequestItemLoaded(item)
		{
			item.processed = true;

			if (filesLoaded.hasOwnProperty(item.src))
			{
				item.loadedFileReference = filesLoaded[item.src];
				item.loadedFileReference.incrementCount();
			}

			if (allRequestsLoaded(items))
			{
				finalizeRequestLoaded(me);
			}
		}


		/**
		 * does the  cleanup of the request after it has loaded
		 * as well as executing the original callback function the
		 * owner gave to us.
		 */
		function finalizeRequestLoaded()
		{
			var id = me.id;

			if (pendingLoadRequests.hasOwnProperty(id))
			{
				// delete ourselves from the request, prepare to be garbage collected
				delete pendingLoadRequests[id];

				loadedRequests[id] = me;
			}

			// we need the rest of the code to execute before we actually call the callback
			// otherwise there exists the case where the library has fully loaded before we
			// have even returned the library token handle that users can use to cleanup the
			// library.
			// set a short timeout so the function callback occurs after the
			setTimeout(function() {
				requestCallback(getLoadResult());
			}, 1);

		}


		/**
		 * Notifies all of the LoadRequest objects attached to the request item that
		 * the request item has loaded.
		 */
		function notifyLoadRequestsOfItemComplete(requestItem, loadResult) {

			gaerdvark.utils.forEachArray(requestItem.callbacks, function(callBack) {
				callBack(requestItem);
			});
		}

		/**
		 * Returns a function that processes the callbacks for an individual request item
		 * the function will set the fileLoadResult onto the request item when it has been loaded
		 * @return f(loadResult) => sets requestItem.fileLoadResult and calls notifyLoadRequestsOfItemComplete(requestItem)
		 */
		function getCallBackProcessorForRequestItem(requestItem)
		{
			return function(loadResult) {
				// TODO: stephen it has a bad code smell that we delete the pending requests here, but not add in the loaded request.
				// think of a way to refactor this to be better.
				if (pendingRequestItems.hasOwnProperty(requestItem.src))
				{
					delete pendingRequestItems[requestItem.src];
				}
				requestItem.fileLoadResult = loadResult;
				notifyLoadRequestsOfItemComplete(requestItem);
			}
		}

		/**
		 * For the individual request item attempt to load it.  If the file has already been loaded
		 * set the load item status to be success and then immediately execute the callBackProcessor to say
		 * the request item has been loaded.
		 * If the file has not been loaded start up the request for a script or stylesheet.
		 */
		function loadRequestItem(requestItem)
		{
			requestItem.callbacks.push(processRequestItemLoaded);

			// if we are already loaded, let's fire off our events that the item has been loaded.
			if (filesLoaded.hasOwnProperty(requestItem.src))
			{
				var callBackProcessor = getCallBackProcessorForRequestItem(requestItem);
				var url = filesLoaded[requestItem.src].getURL(); // get the fully qualified url that was loaded.
				callBackProcessor(new FileLoadResult(url, STATUS_LOAD_SUCCESS));
				return;
			}

			// check if we are currently being loaded, and make sure we are notified if we are.
			if (pendingRequestItems.hasOwnProperty(requestItem.src))
			{
				var pendingItem = pendingRequestItems[requestItem.src];

				// add to the other item a notification to process our current request item.
				var callBackProcessor = getCallBackProcessorForRequestItem(requestItem);

				pendingItem.callbacks.push(function(pendingRequestItem) {
					// we need to send the right file results object for the requestItem not
					// the pendingRequestItem.
					callBackProcessor(pendingRequestItem.fileLoadResult);
				});
				return;
			}


			// otherwise let others know we are currently being loaded.
			pendingRequestItems[requestItem.src] = requestItem;

			// now load up the file

			// if we are a script, load that one
			if (requestItem.type == REQUEST_TYPE_SCRIPT)
			{
				addScript(requestItem, getCallBackProcessorForRequestItem(requestItem));
			}
			// otherwise load the stylesheet.
			else if (requestItem.type == REQUEST_TYPE_STYLESHEET)
			{
				addStylesheet(requestItem, getCallBackProcessorForRequestItem(requestItem));
			}
		}

		/**
		 * Retrieve an array containing all of the file load result objects
		 * that detail the errors / status of each file.
		 */
		function getLoadResult() {
			// grab all of the fileLoadResults and pass them in
			// to the callback
			var loadStatus = STATUS_LOAD_SUCCESS;

			var loadResults = gaerdvark.utils.mapArray(items, function(item) {
				if (item.fileLoadResult.getStatus() == STATUS_LOAD_FAILED)
				{
					loadStatus = STATUS_LOAD_FAILED;
				}
				return item.fileLoadResult;
			});

			return {
				status: loadStatus
				,files: loadResults
				,OK: (loadStatus === STATUS_LOAD_SUCCESS)
			};
		};

		/**
		 * Request each of the external files we have been created with
		 * return an id that can be used for unloading us in the future.
		 */
		this.load = function()
		{

			// put ourselves at the very first on the pending request
			// as we can fully load before we return out of this function
			pendingLoadRequests[this.id] = me;

			// now issue the load request for each item
			gaerdvark.utils.forEachArray(items, loadRequestItem);

			return this.id;
		};

		/**
		 * Cycle through each item that we have loaded / requested previously
		 * If we have loaded a resource for the request, go through and remove it's node
		 * from the DOM and remove it from our filesLoaded result.
		 */
		this.unload = function()
		{
			gaerdvark.utils.forEachArray(items, function(item) {
				if (item.loadedFileReference)
				{
					var loadedFile = item.loadedFileReference;

					loadedFile.decrementCount();
					if (loadedFile.canBeRemoved())
					{
						var domNode = loadedFile.getDomNode();
						if (domNode != null)
						{
							domNode.parentNode.removeChild(domNode);
							loadedFile.clearDomNode();
						}

						// grab the originally requested src.
						if (filesLoaded.hasOwnProperty(item.src))
						{
							delete filesLoaded[item.src];
						}
					}
				}
			});
		};
	}

	/**
	 * Given a list of request items, script or css
	 * Create the unit of work for loading the items and set up our
	 * unit of work to load.
	 */
	function loadDependenciesRequestItems(dependencyItems, allFilesLoadedCallback)
	{
		var request = new LoadRequest(dependencyItems, allFilesLoadedCallback);

		var libraryLoadToken = request.load();

		return libraryLoadToken;
	}

	/**
	 * Given a filename use the extension portion of the file name to determine if
	 * this is a script request or a stylesheet request.
	 */
	function getRequestItemTypeFromFilename(filename)
	{
		// default to javascript
		var type = REQUEST_TYPE_SCRIPT;

		if (filename != null)
		{
			var parts = filename.split('.');
			if (parts.length > 1)
			{
				var extension = parts[parts.length - 1].trim();
				if (extension == 'css')
				{
					type = REQUEST_TYPE_STYLESHEET;
				}
			}
		}
		return type;
	}

	/**
	 * Load the list of files passed in and call the allFilesLoadedCallback when the script has loaded.
	 * @param dependencyList array of strings that are the dependencies in the library.
	 *
	 */
	function loadLibraryDependencies(dependencyList, allFilesLoadedCallback)
	{
		if (!gaerdvark.utils.isArray(dependencyList))
		{
			dependencyList = [dependencyList]; // make it an array
		}
		// the dependencies are a unit of work
		// when all the dependencies have loaded we need to issue the callback

		// if a dependency is being loaded by another script, we need to
		// have it notify the unit of work when it is completed.

		var requestItems = gaerdvark.utils.mapArray(dependencyList, function(src) {

			return new RequestItem(src, getRequestItemTypeFromFilename(src));
		});

		return loadDependenciesRequestItems(requestItems, allFilesLoadedCallback);

	}

	/**
	 * Goes through each item in the library associated with the passed
	 * in libraryLoadToken and removes the item.  If the item has been
	 * loaded by another library it does not remove it, but decrements
	 * it's reference count.  If a library item is not referenced by
	 * any other item in the system it is removed from the system.
	 */
	function removeDependenciesLoaded(libraryLoadToken)
	{
		if (loadedRequests.hasOwnProperty(libraryLoadToken))
		{
			var loadRequest = loadedRequests[libraryLoadToken];
			loadRequest.unload();
		}
		else
		{
			throwError("Could not find library load request with token " + libraryLoadToken);
		}
	}


	return {
		loadLibraryDependencies:  loadLibraryDependencies
		,removeDependenciesLoaded : removeDependenciesLoaded
	};
};