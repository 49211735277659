function GlobalErrorHandler() {

	/**
	 * The method argument passed in was invalid.
	 */
	this.INVALID_ARGUMENT_ERROR = "Arguments were invalid";
	
	/**
	 * The method called has not been implemented yet.
	 */
	this.METHOD_NOT_IMPLEMENTED = "This method has not been defined";
	
	/**
	 * This error occurs when a tool's method is called and it cannot complete an operation because it's data is in an invalid state.
	 */
	this.TOOL_DATA_INCOMPLETE = "The tool's current data is corrupted, invalid, or incomplete.";
	
	var maxStackSize = 10;
	var currentErrorID = 0;
	var liveErrors = Object();
	var thrownErrors = Array();
	var me = this;
	var seenObjects = {};
	
	/**
	 * {CommManager}
	 */
	var commManager = null;
	
	/**
	 * {ToolEventHandler}
	 */
	var toolEventHandler = null;
	
	/**
	 * Allows the ToolEventHandler object to be set or anything that implements the same interface
	 * {ToolEventHandler}
	 */
	this.setToolEventHandler = function(handler)
	{
		toolEventHandler = handler;
	};
	
	/**
	 * Allows the CommManager object to be set or anything that implements the same interface
	 * {CommManager}
	 */
	this.setCommunicationManager = function(manager)
	{
		commManager = manager;
	};

	/**
	 * @private
	 * Gets the next availible error id, it is a string
	 */
	function getNextErrorID() {
		currentErrorID++;
		return currentErrorID + "";
	}
	
	/**
	 * Create an error object that contains the errorMessage, and error exception if available.
	 * It returns the newly created error. 
	 * @param errorMessage
	 * @param error
	 * @param clientSide
	 * @returns {InternalError}
	 */
	function createErrorObject(errorMessage, error, clientSide)
	{
		var newError = new InternalError("", getNextErrorID(), clientSide);
		// TODO: it feels wierd to create an error and then call add error. we should refactor this.
		newError.addError(errorMessage, error);
		return newError;
	}

	/**
	 * When an error occurs this will tell the proper error object and log it
	 */
	this.logError = function (errorMessage, error, clientSide) {		
		try {
			//Ensuring that the errorMessage is a string.
			//Assume that if the errorMessage isn't a string, it's the error.
			//Man I wish we could ask if errorMessage is a typeof Error
			if(typeof errorMessage !== 'string'){
				if(error === undefined){
					error = errorMessage;
					errorMessage = error.toString();
				}else{
					//If they sent in the error then just toString the errorMessage
					errorMessage = errorMessage.toString();
				}
			}
			
			if (clientSide === undefined) clientSide = true;
			
			//BugReportManager.addLogEntry("error", {"message":errorMessage, "clientSide":clientSide?"true":"false"});
			
			
			var errorObj = createErrorObject(errorMessage, error, clientSide);
			thrownErrors.push(errorObj); // used to track how many errors we've received.
			
			// log it to the console in case developer tools is not enabled.
			if (window.console)
			{
				console.error(errorMessage + ". " + (clientSide ? "client side" : "server side"));
				if(error){
					console.error("Internal Error: %s. \nError Object: %O", errorObj.getErrorMessage(), errorObj.getLogObject());
				}
			}
			
			toolEventHandler.fireToolEvent(errorObj, 'ErrorThrown', true);
			if (clientSide){
				var sendObj = commManager.createSendObject('Log Error', errorObj.getLogObject());
				commManager.send(sendObj);
			}
			
		} catch (e) {
			// don't cause endless loop by throwing an error here!
			if(window.console) {
				console.log('Log Error Failed');
				console.log(e);
			}
		}
	};
	
	/**
	 * For backwards compatibility we want throwError to do the same work as logError for now.
	 */
	this.throwError = this.logError;

	/**
	 * Returns all the error that have been thrown up to this point
	 */
	this.getAllErrors = function () {
		return gaerdvark.utils.cloneObject(thrownErrors);
	};
	
	/**
	 * Returns the number of errors that have been thrown in the system.
	 */
	this.getErrorCount = function() {
		return thrownErrors.length;
	};

	/**
	 * Internal Error object used to manage a single error
	 */
	function InternalError(message, id, clientSide) {

		var me = this;
		var userMessage = "";
		var errorMessage = "";
		var error = null;
		var errorID = id;
		var clientError = clientSide;
		var stackObj = null;

		if(message != "") userMessage = message;

		this.getLogObject = function () {
			var stringStack = stackObj ? stackObj.stringStack : "stack not available";
			return {
				'message':me.getUserErrorMessage()
				,'browserData':_SESSION.browserData
				,'clientError':clientError
				,'error':{
					'message':me.getErrorMessage()
					,'stringStack':stringStack
					,'code':me.getErrorCode()
				}
				,'location':location.href
			};
		};

		this.getLocation = function() {
			if(clientError)
				return 'Client';
			return 'Server';
		};

		this.getStackObject = function() {
			return gaerdvark.utils.cloneObject(stackObj, true, true);
		};

		this.getStackObjectPtr = function () {
			return stackObj;
		};

		this.getErrorMessage = function() {
			if(error){
				return error.message;
			}else{
				return userMessage;
			}
		};

		this.getErrorCode = function (){
			if(error)
				return error.code;
		};

		this.getUserErrorMessage = function() {
			var message = userMessage;
			if(message == "") {
				if(errorMessage != "") {
					message = errorMessage;
				} else {
					message = "Unknown Error Occurred";
				}
			}
			return message;
		};

		this.addError = function(message, e) {
			error = e;
			if(!clientSide) {
				stackObj = {'stringStack':e.stringStack.split('\n')};
			} else {
				stackObj = getStackTrace(error);
			}
			errorMessage = message;
		};

		this.getErrorID = function () {
			return errorID;
		};
	}

	
	this.getStackTrace = getStackTrace;
	/**
	 * @private
	 * Main function giving a function stack trace with a forced or passed in Error
	 *
	 * @param Error e The error to create a stacktrace from (optional)
	 * @return Array of Strings with functions, lines, files, and arguments where possible
	 */
	function getStackTrace(ex) {
		var trimStack = false;
		if(!ex) {
			// on Chrome you can control how many frames of the 
			// stack trace to collect to send to the server.
			// By default it only collects the last 10.  We want all of them.
			// see this article: http://code.google.com/p/v8/wiki/JavaScriptStackTraceApi
			if (Error.stackTraceLimit)
			{
				Error.stackTraceLimit = Infinity;
			}
			
			// we also trigger the error instead of creating a new Error()
			// object because on chrome instantiating the Error object does not create
			// the stack trace.
			trimStack = true;
			try { 
				throw new Error("Forced Error"); 
			}
			catch (e) {
				ex = e;
			}
		}

		var returnVal = {'stringStack':[]};
		
		if (ex.stack) { //chrome
			returnVal.stringStack = ex.stack.split('\n');
		}

		if(trimStack) {
			returnVal.stringStack.shift();
			returnVal.stringStack.shift();
		}

		while(returnVal.stringStack.length > maxStackSize) returnVal.stringStack.pop();

		return returnVal;
	}
};
