/**
 * Provides an interface to the cache on a browser
 */
AardCacheManager = new function() {
	var me = this;
	var cacheTracker = 'LastUsedLocalCache';
	var exparationCutOff = 2628000000; //1 month in milliseconds

	/**
	 * @private
	 */
	function unSupportedOperation() {
		console.error("Local storage not available in this browser");
		return false;
	}

	function checkForExpiredKeys() {
		var checkAge = me.get(cacheTracker);
		var minTime = (new Date()).getTime()  - exparationCutOff;
		for(var i in checkAge) {
			if(minTime > parseInt(checkAge[i][0])) {
				me.removeKey(i);
			}
		}
	}

	function updateKeyAccess(key, size) {
		var checkAge = me.get(cacheTracker);
		checkAge[key] = [(new Date()).getTime(),size];
		localStorage[cacheTracker] = JSON.stringify(checkAge);
	}
	function getKey(name){
		if(name === cacheTracker){
			return cacheTracker;
		}

		return  _SESSION["userID"] + "_" + name;
	}
	/**
	 * Used to add a name and value to the cache
	 * 
	 * @param name of was you want to cache
	 * @param value what to cache, string or object
	 */
	this.add = function(name, value) {
		if(typeof(value) == 'object') {
			value = JSON.stringify(value);
		}
		
		var key = getKey(name);
		try {
			localStorage[key] = value;
		} catch(e) {
			throwError('Not enough space, implement something to clean up cache', e);
		}
		setTimeout(function() { updateKeyAccess(key, value.length); } , 1);
	};

	/**
	 * @param name of was you want to cache
	 * @returns boolean if it is in the cache
	 */
	this.hasKey = function(name) {
		return Object.prototype.hasOwnProperty.call(localStorage, getKey(name));
	};

	/**
	 * @param name of what should be removed
	 */
	this.removeKey = function(name) {
		localStorage.removeItem(getKey(name));
	};

	/**
	 * @param name of property to get
	 * @returns object or string of what was asked for
	 */
	this.get = function(name) {
		try {
			return JSON.parse(localStorage[getKey(name)]);
		} catch(e) {
			return localStorage[getKey(name)];
		}
	};
	/**
	 * Checks an item in the cache, if it older then the given values it will delete it
	 *
	 * @param name of what is to be checked
	 * @param version of what it is now
	 * @param property in the value to check against the version
	 */
	this.checkItem = function(name, version, property) {
		if(!me.hasKey(getKey(name))) {
			return;
		}
		var value = me.get(getKey(name));
		if(value[property] != version) {
			me.removeKey(getKey(name));
		}
	};

	//If the browser does not support local storage then unset the functions

	if ((!window.localStorage)) {
		this.add = unSupportedOperation;
		this.hasKey = unSupportedOperation;
		this.removeKey = unSupportedOperation;
		this.get = unSupportedOperation;
		this.checkItem = unSupportedOperation;
	} else if(!me.hasKey(cacheTracker)) {
		localStorage.clear();
		me.add(cacheTracker, {});
	} else {
		checkForExpiredKeys();
	}
};