/**
 * Js Manager fires two events scriptUnloaded scriptLoaded src is the src of the script
 * that was just changed
 */
function ToolLoadingManager() {

	// arrays to track js to make sure they are loaded properly
	var loadedTools = Object();

	// keep track of how many times a permanent css has been requested
	// when it gets back to 0 remove it
	var loadedPermanentCSS = Object();

	/**
	 * toolsWaiting is an Object:
	 *  with one property "size" that is an integer used to keep track of the number of tools we are waiting on.
	 *  with Zero or more properties that are objects with the toolName is the key to each object.
	 * Each tool object is an instance of TLMToolWaiting.
	 * @type Object
	 */
	var toolsWaiting = {size: 0};

	var callBackData = Object();

	var pending = Object();
	var contentTool = null;
	var firstTool = null;

	var head = document.getElementsByTagName('head')[0];

	// variables used to create script uri strings
	// illegal characters are just stripped by the server before setting the _SESSION.currentVersion value
	var gaerdvarkCurrentVersion = _SESSION.currentVersion;

	var me = this;

	var toolEventHandler = null;

	/**
	 * Pointer to our handler that takes care of any error conditions such as logging etc we are dealing with.
	 */
	var errorHandler = null;
	
	/**
	 * Name of the first full body tool that was loaded in the system 
	 */
	var firstLoadedTool = null;

	this.setToolEventHandler = function(handler) {
		toolEventHandler = handler;
	}

	this.setErrorHandler = function(handler) {
		errorHandler = handler;
	};

	this.init = function() {
		toolEventHandler.addToolListener(processHash, 'HashChanged');
		toolEventHandler.addToolListener(updateLoadedTools, 'UserPermissionMapUpdated');
	};
	
	this.getFirstLoadedToolName = function() {
		return firstLoadedTool;
	};

	function scriptArrived(scriptRequest) {
		var toolName = scriptRequest.tool;
		var toolClassName = getToolNameFromTool(toolName);
		try {
			loadedTools[toolName].inMemory = true;
			if (!gaerdvark.tools[toolClassName]) {
				throwError('Class ' + toolClassName + ' not found');
			}

			var toolData = gaerdvark.toolData[toolClassName];

			// create the style tag
			if (toolData.style) {
				addStyle(toolName, toolData.style);
			}

			// prototype the html and views
			var toolClass = gaerdvark.tools[toolClassName];
			toolClass.prototype.html = toolData.html;
			toolClass.prototype.views = toolData.views;

			setTimeout(function() {
				scriptRequest.scriptArrivedCallback(toolName);
			}, 10); // give the browser just a little time to make sure all functions are instanciated
		} catch (e) {
			throwError("Tool " + toolName + " failed to load", e);
		}
	}

	// --- Private functions

	/**
	 * @private
	 * Goes to each property in an object and calls a function on it
	 */
	function walkObject(obj, func) {
		for (var value in obj) {
			func(obj[value]);
		}
	}

	/**
	 * Class for loadedTools entry
	 */
	function LoadedToolsEntry(tool) {
//		this.hasCode = false; // if it is in memory now
		this.tool = tool; // tool
		this.loadRequests = 0; // number of time load requests have been made
		this.dependencyRequests = 0; // number of times this tool was listed as a dependency
		this.inMemory = false; // if it is in memory now

		this.data = new function() {
			this.toolName = null;
			this.path = null;
			this.version = 0;
			this.dependencies = new Array();
		};
	}

	/**
	 * @private
	 */
	function TLMToolWaiting(tool, forTool) {
		this.tool = tool; // String name of tool
		this.forTools = new Array(forTool); // array of strings, names of tools this tools is a dependency of
		this.waitingFor = {}; // hash of tools being waited for including this tool if it is not loaded yet
		this.waitingFor[tool] = true; // I'm waiting for myself also.

		this.addForTool = function(forTool) {
			this.forTools.push(forTool);
		};
	}
	/**
	 * @private
	 */
	function updateLoadedTools() {
		for (var tool in loadedTools) {
			var cur = loadedTools[tool];
			if (hasToolPermission(tool)) {
				if (!cur.inMemory && (cur.loadRequests || cur.dependencyRequests)) {
					loadDependencyTool(tool, tool);
				}
				continue;
			} else {
				if (cur.inMemory) {
					setTimeout(function() { clearToolFromMemory(tool); }, 1000);
				}
			}
		}
		checkDoneLoading(10000);
	}

	/**
	 * @private
	 */
	function checkDoneLoading(timeout) {
		if (timeout < 0) {
			errorHandler.throwError('Tools never updated on permissions changed');
			return;
		}
		if (toolsWaiting.size) {
			setTimeout(function() {
				checkDoneLoading(timeout - 100);
			}, 100);
			return;
		}
		fireToolEvent(me, 'JSEnviornmentUpdated');
	}

	/**
	 * @private
	 */
	function setTool(toolName, callback) {
		if (gaerdvark.tools[toolName]) {
			setTimeout(function() {
				callback(toolName);
			}, 1);
		} else {
			var src = '/_tool/'
					  + gaerdvarkCurrentVersion + '/'
					  + _SESSION.nameSpace + '/'
					  + toolName + '/'
					  + getToolPackageFromTool(toolName) + '/'
					  + getToolNameFromTool(toolName) + '.js'; // .js added in case some browser uses it to identify type
			var scriptRequest = new AddScriptParam(src, scriptArrived);
			scriptRequest.scriptArrivedCallback = callback;
			scriptRequest.tool = toolName;
			var libraryToken = addPermanentScript(scriptRequest);
			loadedTools[toolName].libraryToken = libraryToken;
		}
	}

	/**
	 * @private
	 * Used to append a script tag to the document
	 */
	function addScript(scriptRequest) {

		// attempt to make it a standard js file
		if (scriptRequest.src.search(/\.js/) == -1) {
			scriptRequest.src = scriptRequest.src + '.js';
		}

		if (scriptRequest.src.search(/\//) == -1) {
			scriptRequest.src = scriptRequest.path + scriptRequest.src;
		}

		if (scriptRequest.src.indexOf("http") != 0
				  && scriptRequest.src.indexOf('/') != 0)
		{
			scriptRequest.src = '/' + scriptRequest.src;
		}

		fireToolEvent(scriptRequest.src, 'ScriptRequested', true);
		var libraryToken = FileLoadingManager.loadLibraryDependencies(scriptRequest.src, function(result) {
			if (result.OK)
			{
				fireToolEvent(scriptRequest.src, 'ScriptLoaded', true);
			}
			else
			{
				var files = result.files;
				var message = "";
				gaerdvark.utils.forEachArray(files, function(file) {
					message += file.getErrorMessage();
				});

				errorHandler.throwError("error on load script " + scriptRequest.src + ". Message: " + message);
			}

			scriptRequest.callback(scriptRequest);
		});

		return libraryToken;

	}

	/**
	 * @private
	 * Checks to see if the hash object has a new tool to be loaded
	 */
	function processHash(hashObject) {
		if (hashObject.removed && hashObject.removed.t) {
			if (contentTool != null) {
				contentTool.destruct();
			}
			//BugReportManager.addLogEntry("destroyTool", {"toolName": hashObject.removed.t});
			contentTool = null;
			unloadToolPrivate(hashObject.removed.t, 'load');
		}
		if (hashObject.t) {
			if (!hasToolPermission(hashObject.t)) {
				//If you try to go to a tool that you don't have permission to go to because you are not logged in.
				if (_SESSION['userID'] == null) {
					//save the hash you were trying to go to and on login go there.
					var temp = getHash();
					var goToWhereWeTriedToGo = function() {
						setHash(temp);
						toolEventHandler.removeToolListener(goToWhereWeTriedToGo, 'Login');
					};

					toolEventHandler.addToolListener(goToWhereWeTriedToGo, 'Login');
					setHash({'t': _SESSION.settings.DefaultHomeAction, 'c': 'Login'});
				} else {
					setHash({});
				}
				return;
			}
			var toolRequest = new LoadToolParam(hashObject.t);
			toolRequest.bodyTool = true;
			//BugReportManager.addLogEntry("createTool", {"toolName": toolRequest.tool});
			loadTool(toolRequest);

			var x = hashObject;
		}

	}

	/**
	 * @private
	 * Adds the CSS for a tool if it does not already exist
	 */
	function addStyle(toolName, styleSheet) {
		if (!styleSheet || loadedTools[toolName].styleElement) {
			return;
		}

		var newStyle = document.createElement("style");
		newStyle.type = 'text/css';
		if (newStyle.styleSheet) { // another IEism
			newStyle.styleSheet.cssText = styleSheet;
		} else {
			newStyle.innerHTML = styleSheet;
		}

		loadedTools[toolName].styleElement = newStyle;
		head.appendChild(newStyle);
	}

	/**
	 * Removes the script tag, style tag, and the tool property from the gaerdvark.tools library object.
	 * @param {string} toolName
	 */
	function clearToolFromMemory(toolName) {
		var loadedTool = loadedTools[toolName];
		if ((!hasToolPermission(toolName)) || (((loadedTool.loadRequests <= 0) && (loadedTool.dependencyRequests <= 0)))) {
			if (toolsWaiting[toolName]) {
				delete toolsWaiting[toolName];
				toolsWaiting.size--;
			}
			delete pending[toolName];
			if (loadedTool) {
				if (loadedTool.libraryToken) {
					FileLoadingManager.removeDependenciesLoaded(loadedTool.libraryToken);
					delete loadedTool.libraryToken;
				}
				delete gaerdvark.tools[toolName];
				// remove the css
				if (loadedTool.styleElement) {
					head.removeChild(loadedTool.styleElement);
					delete loadedTool.styleElement;
				}
				loadedTool.inMemory = false;
				fireToolEvent(toolName, 'ScriptUnloaded', true);
				if (loadedTool.dependencies) {
					var dependencies = loadedTool.dependencies;
					var dependenciesLength = dependencies.length;
					for (var x = 0; x < dependenciesLength; x++) {
						decrementTool(dependencies[x], "dependency");
					}
				}
			}
		}
	}

	/**
	 * Removes a tool from the environment and reduces the requests count by one.
	 * requestType is one of "load" or "dependency"
	 */
	function unloadToolPrivate(tool, requestType) {
		if (requestType != 'load' && requestType != 'dependency') {
			throwError('requestType is not acceptable');
			return;
		}
		if (!loadedTools[tool])
			return;
		//Stop waiting for any pending requests for this tool
		var loadedTool = loadedTools[tool];
		requestType += 'Requests';
		loadedTool[requestType]--;
		if ((loadedTool.loadRequests <= 0) && (loadedTool.dependencyRequests <= 0)) {
			loadedTool.loadRequests = 0;
			loadedTool.dependencyRequests = 0;
			setTimeout(function() { clearToolFromMemory(tool); }, 1000);
		}
	}

	/**
	 * @private
	 * Used to handle when a permanent script has been loaded and the callback need to be called and
	 * the current tool needs to be unset
	 */
	function permanentScriptLoaded(scriptResponse) {
		scriptResponse.callback = scriptResponse.finalCallback;
		delete scriptResponse.finalCallback;
		scriptResponse.callback(scriptResponse);
	}

	/**
	 * @private
	 * checks all the pending that where waiting on this dependency to see if they are done loading
	 * @param {String} tool, the tool that is being told a dependency loaded for it
	 * @param {String} dependencyTool, the tool that just loaded
	 */
	function dependencyLoaded(tool, dependencyTool) {
		if (dependencyTool === undefined) {
			dependencyTool = tool; // when called as callback from FileLoadingManager
		}
		if (!toolsWaiting[tool]) {
			return;
		}

		delete toolsWaiting[tool].waitingFor[dependencyTool]; // one of this tool's dependencies has been loaded

		if (gaerdvark.utils.propertyCount(toolsWaiting[tool].waitingFor) > 0)
			return;

		//DO NOT do ansync from this point on
		// check to see if we are done loading pending
		if (toolsWaiting[tool].forTools) {
			while (toolsWaiting[tool].forTools.length) {
				var forTool = toolsWaiting[tool].forTools.pop();
				if (forTool !== tool && forTool)
					dependencyLoaded(forTool, tool);
			}
		}

		//I am done loading, set myself then children
		if (pending[tool]) {
			toolLoadingComplete(tool);
		}
		// remove the loaded script from the ones to delete and from what is being loaded
		delete toolsWaiting[tool];
		toolsWaiting.size--;
	}

	/**
	 * @private
	 * Checks to see if the loading is complete, if so then it will init the firstTool and then delete
	 * all js that is not being used
	 */
	function toolLoadingComplete(tool) {
		if (firstTool == tool) {
			if (contentTool !== null && typeof (contentTool) == 'object') {
				contentTool.destruct();
			}
			contentTool = new Tool(firstTool);
			contentTool.appendToNode(document.getElementsByTagName('body')[0]);
			contentTool.activate();
			loadComplete(firstTool);
			firstTool = null;
			if (firstLoadedTool === null) {
				firstLoadedTool = contentTool.getCurrentTool();
			}

		} else {
			loadComplete(tool);
		}
		delete pending[tool];
	}

	/**
	 * @private
	 * Used to call the callback function and set it back to the default callback function.
	 */
	function loadComplete(tool) {
		var requests = pending[tool].requests;
		for (var i = 0; i < requests.length; i++) {
			requests[i].callback(requests[i]);
		}
	}

	/**
	 * @private
	 * Loads a tool that is depended on by another tool, it ignores pending that
	 * are currently being, or already, loaded
	 * If dontIcrment is true the incrementTool method has already been run and will be skipped.
	 */
	function loadDependencyTool(tool, forTool, dontIncrement) {
		if (!dontIncrement) {
			incrementTool(tool, 'dependency');
		}

		//Check permission
		if (!hasToolPermission(tool)) {
			return;
		}

		//Currently being loaded
		if (toolsWaiting[tool]) {
			//check for loops
			var forTools = toolsWaiting[tool].forTools;
			for (var i = 0; i < forTools.length; i++) {
				if (forTool == forTools[i]) {
					return;
				}
			}
			if (!loadedTools[tool].inMemory) {
				toolsWaiting[tool].addForTool(forTool);
			}
			return;
		}

		//Make request
		toolsWaiting[tool] = new TLMToolWaiting(tool, forTool);
		toolsWaiting.size++;
		toolsWaiting[forTool].waitingFor[tool] = true;

		//Load dependency tools of this tool
		var dependencies = getToolDependenciesForTool(tool);
		loadedTools[tool].dependencies = dependencies;
		var dependenciesLength = dependencies.length;
		for (var i = 0; i < dependenciesLength; i++) {
			loadDependencyTool(dependencies[i], tool);
		}

		//Load the script and styles if any into html.head
		setTool(tool, dependencyLoaded);
	}

	/**
	 * @private
	 * Tracks the number of times this tool has been requested
	 * requestType is one of "load" or "dependecy"
	 */
	function incrementTool(tool, requestType) {
		if (requestType != 'load' && requestType != 'dependency') {
			throwError('requestType is not acceptable');
			return;
		}
		if (!loadedTools[tool]) {
			loadedTools[tool] = new LoadedToolsEntry(tool);
		}
		requestType += "Requests"
		loadedTools[tool][requestType]++;
	}

	/**
	 * @private
	 * Removes a tool from the tool loading manager
	 * requestType is one of "load" or "dependency"
	 */
	function decrementTool(tool, requestType) {
		unloadToolPrivate(tool, requestType);
	}

	// --- Public functions
	/**
	 * @private
	 * Loads a tool, and tracks that it has been loaded and also cleans up all scripts that are not
	 * needed anymore
	 */
	this.loadToolRequest = function(toolRequest) {
		var toolName = toolRequest.tool;
		incrementTool(toolName, 'load');

		// User does not have permission so an empty tool will be created
		// or the tool is already loaded so we don't need to request it.
		if (!hasToolPermission(toolName) || loadedTools[toolName].inMemory) {
			setTimeout(function() {
				toolRequest.callback(toolRequest);
			}, 1);
			return;
		}

		if (pending[toolName]) {
			pending[toolName].requests.push(toolRequest);
			return;
		}

		//first time the tool has been requested, so setup the arrays to watch the progress
		pending[toolName] = Object();
		pending[toolName].requests = new Array(toolRequest);

		if (toolRequest.bodyTool) {
			firstTool = toolName;
		}
		loadDependencyTool(toolName, toolName, true); // true for don't increment again
	};

	/**
	 * Checks if the tool that performs an tool is loaded on the client
	 */
	this.isToolLoaded = function(tool) {
		if (!loadedTools[tool])
			return false;
		return loadedTools[tool].inMemory;
	};

	/**
	 * Adds a script that will never be removed until a page refresh
	 */
	this.addPermanentScript = function(scriptRequest) {
		if (!scriptRequest.finalCallback) {
			scriptRequest.finalCallback = scriptRequest.callback;
		}
		if (scriptRequest.callback != permanentScriptLoaded) {
			scriptRequest.callback = permanentScriptLoaded;
		}
		return addScript(scriptRequest);
	};

	/**
	 * Adds a link for the css to the head if it does not already exist.
	 */
	this.addThirdPartyCSS = function(cssURL) {
		console.assert(
			( cssURL.indexOf("thirdparty") > -1 && cssURL.indexOf("_library") === -1), 
			"Third party assets should be retrieved from the server using /_library/currentVersion/thirdparty/.../file.css Url:" + cssUrl + " will probably 404");
			
		// if this css has already been requested just add one to the requests count
		if (loadedPermanentCSS[cssURL]) {
			loadedPermanentCSS[cssURL].requests++;
			return;
		}

		// add the css link to the end of head
		var newCSSLink = document.createElement('link');
		newCSSLink.setAttribute('href', cssURL);
		newCSSLink.setAttribute('rel', 'stylesheet');
		newCSSLink.setAttribute('type', 'text/css');
		document.getElementsByTagName('head')[0].insertBefore(newCSSLink, null);

		// create an object to track this css
		loadedPermanentCSS[cssURL] = {requests: 1, node: newCSSLink};
	}

	/**
	 * Removes the css link from the head that was added using addPermanentCSS
	 */
	this.removeThirdPartyCSS = function(cssURL) {
		// is the script loaded
		if (loadedPermanentCSS[cssURL]) {
			// reduce the number of requests by 1
			loadedPermanentCSS[cssURL].requests--;
			// is that the last active request
			if (loadedPermanentCSS[cssURL].requests < 1) {
				// remove the script and the tracking data
				loadedPermanentCSS[cssURL].node.parentNode.removeChild(loadedPermanentCSS[cssURL].node);
				delete loadedPermanentCSS[cssURL];
			}
		}
	}

	/**
	 * Decrements the tool and all its dependencies
	 * requestType is one of "load" or "dependency", "load" is the default if not specified
	 */
	this.unloadTool = function(tool, requestType) {
		if (requestType !== 'dependency') {
			requestType = 'load';
		}
		decrementTool(tool, requestType);
	};

	/**
	 * for testing and debugging only!
	 * @returns {unresolved}
	 */
	this.getLoadedTools = function() {
		return loadedTools;
	}
}

/**
 * Param object for requesting a tool load
 */
function LoadToolParam(tool, callback) {
	this.tool = tool; //tool name, tool to load
	this.bodyTool = false; //if it belongs in the body tag, if so I will activate it
	this.errorID = null;
	this.callback = (callback) ? callback : function() {
		//console.log("Loaded tool with no callback. toolname: " + tool);
	};
}

/**
 * Param object for requesting a script load
 */
function AddScriptParam(src, callback) {
	this.src = src;
	this.path = '';
	this.errorID = null;
	this.callback = (callback) ? callback : function() {
	};
}
