/**
 * Responsible for saving, and retrieving information for the info collection data of the currently logged in user.
 * Maintains a local cache and notifies listeners when the data is modified
 * Currently data for a user can only be created by the same user, it cannot be modified by other users
 * if that changes, this manager will need to be updated to handle that.
 */
gaerdvark.UserInfoCollectionManager = function() {
	
	var manager = this;
	
	var toolEventHandler = null;
	
	var LOAD_ACTION = "Get Information Collection By Student and Title";
	var SAVE_ACTION = "Save Info Collection";
	
	var informationCollectionCache = {};

	manager.setToolEventHandler = function(handler) {
		toolEventHandler = handler;
	};
	
	manager.INFORMATION_COLLECTION_UPDATED_EVENT_NAME = "Information Collection Updated";
	
	
	manager.getAllInformation = function() {
		return informationCollectionCache;
	};
	
	manager.fireUpdateEvent = function(title, data, originalCaller) {
		// no defined event handler just return, the user may want
		// events to be ignored.
		if (!toolEventHandler) {
			return;
		}
		
		toolEventHandler.fireToolEvent({
			"infoCollectionData":data
			,"title": title
			,"toolUuid":originalCaller 
			}, manager.INFORMATION_COLLECTION_UPDATED_EVENT_NAME);
	};

	function getCacheKey(title, courseId){
		return title + courseId;
	}
	
	/**
	 * Title is not the title for the data the action needs. Title can be the same in both cases.
	 * data needs to have everything the action needs to save. IE
	 * { 
	 * 		"title":infoGathererTitle
	 * 		,"data":{
	 * 			"numArray":[1,2,3,4,5,5]
	 * 			,"blah":"blahahalkahblah"
	 * 			,"Data as per your specification":"This is the data that will be saved"
	 * 		}
	 * }
	 * 
	 * @param title
	 * @param data
	 * @param callback
	 * @param uuid|undefined A unique identifier for this request, that can be used to distinguish the caller when an event is fired 
	 * @param isNameSpaceData If you want the data to be saved across all courses in this namespace then send 
	 */
	manager.saveInformationForTitle = function(title, data, callback, uuid, isNamespaceData) {
		// TODO: stephen will this introduce bugs, if someone saves data right at the moment
		// we are attempting to retrieve data?  In our current use of this manager
		// this is currently unlikely to happen, however... we may have to address this
		// later if future requirements change that allow this to happen.

		var informationDataToSave = gaerdvark.utils.cloneObject(data);
		informationDataToSave.data = JSON.stringify(informationDataToSave.data);

		if(gaerdvark.utils.isEmpty(isNamespaceData) || isNamespaceData === false){
			informationDataToSave.courseId = gaerdvark.utils.getCurrentProjectId();
		}else{
			informationDataToSave.courseId = "";
		}
		
		// get the data and save it to the server
		var sendObj = createSendObject(SAVE_ACTION, informationDataToSave, function(sendObj) {
			if (sendObj.OK) {
				
				// the response sends back the information gatherer data that we sent plus 
				// additional populated fields.
				var cacheKey = getCacheKey(title, informationDataToSave.courseId);
				informationCollectionCache[cacheKey] = sendObj.response;
				manager.fireUpdateEvent(title, informationCollectionCache[cacheKey], uuid);
				callback(title, informationCollectionCache[cacheKey]);
			}
			else {
				throwError("Failed to save data for the information collection " + title);
				callback(title, null);
			}
		});
		send(sendObj);
	};
	
	// TODO: stephen this isn't handling the case where a bunch of
	// tools all make the request for the exact same data....
	// perhaps we need to handle that scenario and only make one
	// request instead of many.., for now we won't worry about it, but this is
	// an area of improvement.
	manager.loadInformationForTitle = function(title, callback, isNamespaceData) {
		if (typeof title !== "string") {
			throw new TypeError("title must be a valid string");
		}
		if (typeof callback !== "function") {
			throw new TypeError("callback must be a valid function");
		}

		var data = {
			"title": title
			,"courseId":""
		};

		if(!isNamespaceData){
			data.courseId = gaerdvark.utils.getCurrentProjectId();
		}

		var cacheKey = getCacheKey(title, data.courseId);
		if (informationCollectionCache.hasOwnProperty(cacheKey)) {
			callback(title, informationCollectionCache[cacheKey]);
			return;
		}
		
		send(createSendObject(LOAD_ACTION, data, function (respObj){
			var responseData = null;
			if(respObj.OK){
				// if the response is empty... we want to remember that so we don't
				// issue a new query again.
				responseData = respObj.response;
				informationCollectionCache[cacheKey] = responseData;
			}
			else {
				throwError("Failed to get information collection response from the server");
			}
			// nothing to send here...
			callback(title, responseData);
		}));
	};
	
	manager.init = function() {
		// for now we do nothing in our init.
	};
};