function UserPermissionMap() {

	//object to hold all actions
	var actions = null;
	var tools = null;

	var jsInstanceID = '';
	var loginEvent = 'Login Server';
	var logoutEvent = 'Logout Server';

	var toolEventHandler = null;

	/**
	 * Be able to set the tool event handler dependency that manages any tool listener
	 * events.
	 * @param {type} handler
	 */
	this.setToolEventHandler = function(handler) {
		toolEventHandler = handler;
	};

	//Listen for changes made to the UserPermissionMap
	this.init = function() {
		toolEventHandler.addToolListener(aardvarkLoaded, 'APILoaded');
	};


	function aardvarkLoaded(data, event) {
		jsInstanceID = _SESSION.jsInstanceID;
		loginEvent = 'Login Server';
		logoutEvent = 'Logout Server';
		addToolListener(setUserPermissionMap, loginEvent);
		addToolListener(setUserPermissionMap, logoutEvent);
		setUserPermissionMap({'jsInstanceID':jsInstanceID,'actionMap':_SESSION.actionMap}, event);
	}

	/**
	 * given the data from the server this will build the user tool map so that it can be used to
	 * check permissions on the js side
	 *
	 * @param {Object} data
	 * @param {Object} event
	 */
	function setUserPermissionMap(data, event) {
		if (data === null){
			return;
		}

		if(data.jsInstanceID !== jsInstanceID){
			throwError('Should not get events not for me!');
			return;
		}

		var userActions = data.actionMap.actions;
		_SESSION.actionMap.actions = userActions;

		var userTools = data.actionMap.tools;
		_SESSION.actionMap.tools = userTools;

		actions = new Object();
		tools = new Object();

		for (var i = 0; i < userActions.length; i++) {
			actions[userActions[i]] = new AMAction(userActions[i]);
		}
		
		for (var j in userTools) {
			tools[userTools[j].Tool]
				= new AMTool(
					userTools[j].tool,
					userTools[j].showInNavigation,
					new AMToolImplementation(userTools[j].toolName, userTools[j].path, userTools[j].version, userTools[j].packageName),
					userTools[j].allowCustomCss,
					userTools[j].dependencies
				);
		}

		if (event === logoutEvent) {
			sessionStorage.clear(); // clear the session storage when user logs out
			if (_SESSION['userID'] !== null) {
				if ((_SESSION) && (_SESSION.logoutRedirectUrl)) {
					var logoutRedirectUrl = _SESSION.logoutRedirectUrl.trim();
					if (logoutRedirectUrl.indexOf("http") === 0) {
						window.location = logoutRedirectUrl;
						return;
					}
					// parts[0] will be the pathname
					// parts[1] will be the hash if it exists
					var parts = logoutRedirectUrl.split('#');
					location.hash = parts.length > 1 ? parts[1] : '';
					location.pathname = parts[0];
					location.reload();
				} else if(data.hasOwnProperty("logoutRedirect") && data.logoutRedirect !== "") {
					location = data.logoutRedirect;
					return;
				}else{
					fireToolEvent(data, 'Logout');
					setHash({"t":data.settings.DefaultHomeAction,"c":"Login"});
					location.reload();	
				}
			}
		}

		if(event === loginEvent) {
			fireToolEvent(data, 'Login');
			if(data.hasOwnProperty("loginRedirect") && data.loginRedirect !== ""){
				setTimeout(
						function (){
							location = data.loginRedirect;
						}
						, 500);
			}else{
				setHash({t:data.settings.DefaultHomeAction});
			}
		}

		fireToolEvent(null, 'UserPermissionMapUpdated');
	}

	/**
	 * gets a tool name from an tool
	 */
	function getToolName(tool) {
		return tools[tool].toolImpl.name;
	}

	function getToolPackage(tool) {
		return tools[tool].toolImpl.packageName;
	}

	/**
	 * Gets the plugin path for an tool
	 */
	function getPluginName(tool) {
		return tools[tool].toolImpl.plugin;
	}

	function getVersion(tool) {
		return tools[tool].toolImpl.version;
	}

	/**
	 * Private tool object to hold the abstract name
	 */
	function AMTool(name, showInNav, toolImpl, allowCustomCss, dependencies) {
		this.name = name;
		this.showInNav = showInNav;
		this.toolImpl = toolImpl;
		this.allowCustomCss = allowCustomCss;
		this.dependencies = dependencies;
	}

	/**
	 * Private Tool Impl data object, holds a name and plugin path and real name
	 */
	function AMToolImplementation(name, plugin, version, packageName) {
		this.name = name;
		this.plugin = plugin;
		this.version = version;
		this.packageName = packageName;
	}

	/**
	 * Private Action data object, holds name, tool, and if it should be shown in navigation
	 */
	function AMAction(name) {
		this.name = name;
	}

	/**
	 * Checks to see if the user has permission for the given tool
	 */
	this.hasActionPermission = function(action, suppressError) {
		try {
			if (actions[action]) {
				return true;
			}
			if(!suppressError){
				throwError('Action "' + action + '" not found. check spelling/case of action.');
			}
			return false;
		} catch (e) {
//			throwError will cause endless looping because actions didn't load;
			if(window.console) {
				console.log('Failed to populate actions.');
				console.log(e);
			}
		}
	};


	/**
	 * Checks to see if the user has permission for the given tool
	 */
	this.hasToolPermission = function(tool) {
		if (tools[tool]) {
			return true;
		}
		return false;
	};

     /**
		* Returns true if the tool allows custom css
		*/
	  this.allowsCustomCss = function(toolCommonName) {
		  if (tools[toolCommonName] === undefined) {
				// default to false, maybe this should be true? right now all it does is causes customCss class name to be added to root node
				return false;
		  }
		  return (tools[toolCommonName].allowCustomCss);
	  };

	/**
	 * Finds the toolName of a given tool, will fail if the user does not have permission or the
	 * tool does not exist
	 */
	this.getToolNameFromTool = function(tool) {
		if (!tools[tool]) {
			throwError("Could not convert tool into toolimpl name for tool: '" + tool + "'", null);
			return false;
		}
		return getToolName(tool);
	};

	/**
	 * Finds the implementation package name of a given tool, will fail if the user does not have permission or the
	 * tool does not exist
	 */
	this.getToolPackageFromTool = function(tool) {
		if (!tools[tool]) {
			throwError("Could not convert tool into package implementation name for tool: '" + tool + "'", null);
			return false;
		}
		return getToolPackage(tool);
	};

	this.getToolDependenciesForTool = function(tool) {
		if (!tools[tool]) {
			throwError("Could not convert tool into dependencies for tool: '" + tool + "'", null);
			return false;
		}
		if (tools[tool].dependencies) {
			return tools[tool].dependencies;
		}
		return [];
	}

	/**
	 * Given an object with an tool property this will add the toolName, Path (plugin), javascript
	 * and style for the given action and return true. If the action is not found or it is unable to
	 * put the data into the object it will return false
	 */
	this.addToolData = function(request, tool) {
		if (!tools[tool]) {
			return false;
		}
		try {
			request.toolName = getToolName(tool);
			request.path = getPluginName(tool);
			request.version = getVersion(tool);
			return true;
		} catch (error) {
			throwError("Could not create toolData for '" + tool + "'", error);
		}
		return false;
	};
};
