/**
 * Allows tools to fire events, as well as lets other tools register listeners to be called
 * when the event is fired
 */
function ToolEventHandler() {

	//Map, key is eventName, value is {function[]}
	var me = this;
	var eventListeners = new Object();
	var eventQueue = new Array();
	var uniqueEventName = 0;
	var updateTimeout = null;
	var firstRun = true;
	var throwEventRegisterError = true;
	var serverEventListenerList = []; //The list of events that the client is listening to on the server.
	var serverEvents = []; //The list of possible server events that can be listened to.
	var sendServerEvents = false; //Flag to control when Action is sent to server...set to true whenever a Server Event is added or removed from listener

	/**
	 * How long in milliseconds to wait between each update of server events to send up to the server.
	 */
	var SERVER_EVENT_UPDATE_TIME = 300;

	/**
	 * Pointer to the handler that will deal with all communication to the server
	 * @type {CommManager}
	 */
	var commManager = null;

	/**
	 * Pointer to our handler that takes care of any error conditions such as logging etc we are dealing with.
	 */
	var errorHandler = null;

	this.setErrorHandler = function(handler) {
		errorHandler = handler;
	};

	/**
	 * @param {commManager} manager
	 * @returns {undefined}
	 */
	this.setCommunicationManager = function(manager) {
		commManager = manager;
	};
	/**
	 * setter for the list of server events
	 * @param {array} _serverEvents
	 * @returns {undefined}
	 */
	this.setServerEvents = function(_serverEvents){
		if( !gaerdvark.utils.isEmpty(_serverEvents) ){
			serverEvents = _serverEvents;
		}
	};

	this.init = function() {
		// our init function does nothing for now
	};

	this.isServerEvent = function (eventName){
		return (serverEvents.indexOf(eventName) !== -1);
	}

	function updateWhenDone() {
		if(updateTimeout) {
			clearTimeout(updateTimeout);
		}
		updateTimeout = setTimeout(updateServerEventList, SERVER_EVENT_UPDATE_TIME);
	}


	/**
	 * Tells if the server events are the same or not.
	 *
	 * @param {Array} currentEventList
	 * @returns {Boolean}
	 */
	function allEventsAreOnServer(currentEventList){
		if(currentEventList.length !== serverEventListenerList.length){
			return false;
		}

		for(var i = 0; currentEventList.length; i++){
			if(serverEventListenerList.indexOf(currentEventList[i]) === -1){
				return false;
			}
		}

		return true;
	}

	/**
	 * Updates the server event list.
	 * @returns {undefined}
	 */
	function updateServerEventList(){
		//get a list of the server events.
		var eventList = [];
		for(var eventName in eventListeners) {
			if(eventListeners[eventName].serverEvent && eventListeners[eventName].listeners.length > 0){
				eventList.push(eventName);
			}
		}

		// If the server events are exactly the same as the eventList then we have
		// already sent up the server event and we don't need to send it again.
		if(allEventsAreOnServer(eventList)){
			return;
		}

		serverEventListenerList = eventList;

		try{
			if(commManager && commManager.send) {
				//Only send the Action to the Server if we believe that Server Events MIGHT have changed
				if (sendServerEvents) {
					sendServerEvents = false;
					commManager.send(commManager.createSendObject('Set Server Event Listeners', {'events':serverEventListenerList}, updateEventResult), !firstRun);
				}
			} else {
				updateWhenDone();
			}
		} catch(e) {
			errorHandler.throwError("Failed to send Set Server Event Listeners", e);
		}
	}

	/**
	 * Check to make sure we updated the server events.  If we didn't... then don't put an error out to the screen again
	 * @param sendObj
	 */
	function updateEventResult(sendObj) {
		var shouldThrowError = throwEventRegisterError;

		if (sendObj.status < 400 && sendObj.response !== null && sendObj.response === true)
		{
			// reset our flag so if we have errors in the future we can notify of it
			throwEventRegisterError = true;
			shouldThrowError = false;
		}

		if (shouldThrowError)
		{
			errorHandler.throwError('Unable to init server events');
			throwEventRegisterError = false;
		}
	}

	/**
	 * Loops through all the event listeners for a given event, calling the functions with the object
	 * that was passed
	 */
	function runToolEvent() {
		var nextEvent = eventQueue.shift();
		var eventName = nextEvent.name;
		var obj = nextEvent.obj;

		if(eventListeners.hasOwnProperty(eventName)) {
			for(var i = 0; (Object.prototype.hasOwnProperty.call(eventListeners, eventName)) && (i < eventListeners[eventName].listeners.length); i++) {
				if (eventListeners[eventName].listeners[i]) {
					try {
						eventListeners[eventName].listeners[i](obj, eventName);
					} catch(error) {
						
						if(eventListeners.hasOwnProperty(eventName)){
							errorHandler.throwError('Event listener callback (' + eventListeners[eventName].listeners[i]  +
														 ') for "' + eventName + '" threw exception', error);
						}else{
							errorHandler.throwError("Event listener callback falled for '" + eventName + "' threw exception: " + error.message, error);
						}
					}
				} else {
					// callback is gone, remove it from the array
					eventListeners[eventName].listeners.splice(i, 1);
					i--;
				}
			}
		}
		if(eventListeners[eventName] && eventListeners[eventName].listeners.length === 0) {
			delete eventListeners[eventName];
		}
	}
	/**
	 * Logs the event in that was fired so that we can have it... you know
	 * for debugging.
	 *
	 * @param {type} obj
	 * @param {type} eventName
	 * @param {type} synchronous
	 * @returns {undefined}
	 */
	function logEvent(obj, eventName, synchronous){
		return;
		// var eventObj = {};

		// switch (eventName) {
		// 	case 'ScriptUnloaded' :
		// 	case 'ScriptLoaded' :
		// 		eventObj.tool = obj; // only send the tool name
		// 		break;
		// 	default :
		// 		eventObj = obj; // send all the data for the others
		// 		break;
		// }

		// BugReportManager.addLogEntry("event", {"eventName":eventName, "synchronous":synchronous?"true":"false", "obj":eventObj});
	}

	/**
	 * Calls all the listeners of an event passing the object as a parameter to the function,
	 * if synchronous it will run all the callbacks before returning, if not it will return, then
	 * run the events later in the order that they where recieved
	 */
	this.fireToolEvent = function (obj, eventName, synchronous) {
		logEvent(obj, eventName, synchronous);
		if (eventListeners.hasOwnProperty('ToolEventFired')) {
			// tell listeners that a tool event has been fired
			// do it synchronous in case error occurs when the actual event is fired
			// used by DevConsole
			eventQueue.unshift({"obj":{"eventObj":obj, "eventName":eventName, "listeners":eventListeners[eventName] ? eventListeners[eventName].listeners : []}, "name":"ToolEventFired"});
			runToolEvent();
		}
		if (synchronous) {
			eventQueue.unshift({'obj':obj,'name':eventName});
			runToolEvent();
		} else {
			eventQueue.push({'obj':obj,'name':eventName});
			setTimeout(runToolEvent, 1);
		}
	};

	/**
	 * registers a listener to an event by giving a callback
	 *
	 * @param {function} callback
	 * @param {String} eventName
	 * @returns {undefined}
	 */
	this.addToolListener = function (callback, eventName) {
		if(!eventListeners.hasOwnProperty(eventName)) {
			eventListeners[eventName] = new Object();
			eventListeners[eventName].listeners = new Array();
		}

		/*
		We should probably do this but right now we don't know if people 
		count on adding the same listener multiple times for the same instance
		of a tool.

		if(eventListeners[eventName].listeners.indexOf(callback) > -1){
			console.log("ignoring duplicate event listener");
			updateWhenDone();
			return;
		}*/

		eventListeners[eventName].serverEvent = me.isServerEvent(eventName);
		eventListeners[eventName].listeners.push(callback);
		/* To Do:  Add check to see if THIS server event is already present, then do NOT bother to set flag */
		/* This would reduce re-sending the same list, assuming a second (or 3rd, 4th, etc) listener is added for the same event */
		if (eventListeners[eventName].serverEvent) {
			sendServerEvents = true;
		}
		updateWhenDone();
	};

	/**
	 * Removes a listener to an event
	 * if callback is not defined remove all the listeners
	 *
	 * @param callback method
	 * @param eventName string
	 */
	this.removeToolListener = function (callback, eventName) {
		if(eventListeners.hasOwnProperty(eventName)) {
			if (!callback) {
				delete eventListeners[eventName]; // don't delete, see comment in runToolEvent
			} else {
				for(var i = 0; i < eventListeners[eventName].listeners.length; i++) {
					if (eventListeners[eventName].listeners[i] === callback) {
						eventListeners[eventName].listeners.splice(i, 1);
						/* To Do: Check if this is the last listener for this event, and if so, remove event */
						/* To Do: If not last listener, do NOT set this flag */
						if (eventListeners[eventName].serverEvent) {
							sendServerEvents = true;
						}
						break;
					}
				}
			}
		}
		updateWhenDone();
	};

	/*
	 * return a unique event name for a tool to make available if a request is made
	 * to listen to it
	 * @return string unique event name
	 */
	this.createUniqueEventName = function () {
		return ++uniqueEventName + '' + (new Date).getTime();
	};
}
