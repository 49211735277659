/**
 * Notification Manager is responsible for creating and displaying notifications from the server / other tools on the
 * current user's screen.
 */
gaerdvark.NotificationManager = function() {
	
	/**
	 * @var ToolEventHandler
	 */
	var toolEventHandler = null;
	
	/**
	 * @var gaerdvark.ToolLoadingManager
	 */
	var toolLoadingManager = null;
	
	/**
	 * Sets our event listening handler
	 */
	this.setToolEventHandler = function(handler) {
		toolEventHandler = handler;
	};
	
	/**
	 * Sets the tool loading manager
	 */
	this.setToolLoadingManager = function(loadingManager) {
		toolLoadingManager = loadingManager;
	};
	
	/**
	 * Takes an array of notifications and displays them on the screen.
	 */
	function createNotifications(notifications) {
		
		if (!gaerdvark.utils.isArray(notifications) || gaerdvark.utils.isEmpty(notifications)) {
			throwError("notificationData must be an array with at least one item");
			return;
		}
		
		gaerdvark.utils.forEachArray(notifications, function(notification) {
			var notificationTool = new Tool("Notification");
			notificationTool.appendToNode(document.body);
			notificationTool.activate(notification);
			noty({
				// noty let' the text property be an actual dom node (which is a strange property name).
				text: notificationTool.getRootNode()
				,callback: {
					afterClose: function() {
						notificationTool.destruct();
					}
				}
			});
		});
	}
	
	/**
	 * Given an array of notifications from the server, go through and create notification events for the client on the screen.
	 * @param {Array}
	 */
	function handleNotifications(notifications) {
		
		if (!gaerdvark.utils.isArray(notifications) || gaerdvark.utils.isEmpty(notifications)) {
			throwError("notificationData must be an array with at least one item");
			return;
		}
		
		if (!toolLoadingManager.isToolLoaded("Notification")) {
			var toolLoadRequest = new LoadToolParam("Notification", createNotifications.bind(null, notifications));
			toolLoadingManager.loadToolRequest(toolLoadRequest);
		}
		else {
			createNotifications(notifications);
		}
	}

	/**
	 * Setup all of the event listeners.
	 */
	function initNotifications() {
		$.noty.defaults.maxVisible = _SESSION.settings.NotificationMaxDisplay; // set the defaults to only show 3.
		$.noty.defaults.layout = _SESSION.settings.NotificationLayoutDisplay;
		
		if (_SESSION && _SESSION.settings && _SESSION.settings.NotificationTimeout) {
			$.noty.defaults.timeout = _SESSION.settings.NotificationTimeout;
		}
		toolEventHandler.addToolListener(handleNotifications, "Notification");
	}
	
	
	/**
	 * This external method is used to create a notification in the system for the user to see.
	 * It expects a notification in the format {title: <string>, message: <string>, type: "notification"|"trophy"|<string>}
	 */
	this.createNotifications = function(notification) {
		var notifications = notification;
		if (!gaerdvark.utils.isArray(notifications)) {
			notifications = [notifications];
		}
		handleNotifications(notifications);
	};
	
	/**
	 * Setup any functionality for when the system has loaded
	 */
	this.init = function() {
		initNotifications();
	};
	
	/**
	 * Cleanup this manager.
	 */
	this.destruct = function() {
		toolEventHandler.removeToolListener(handleNotifications, "Notification");
	};
}