/* global gaerdvark */
/* global AardCacheManager */
/// <reference path="commManager.js" />
/// <reference path="toolEventHandler.js" />
/// <reference path="stateHandler.js" />
/// <reference path="errorHandler.js" />
/// <reference path="hashManager.js" />
/// <reference path="UserPermissionMap.js" />
/// <reference path="ToolLoadingManager.js" />
/// <reference path="BugReportManager.js" />
/// <reference path="Tool.js" />
/// <reference path="CacheManager.js" />
/// <reference path="utilities.js" />

/**
 * setup the system and do our dependency boilerplate.
 * all of this makes it easier to identify and isolate the dependencies so the core framework
 * does not have globals floating around everywhere and we can unit test the core system.
 */

// window.gaerdvark object is created by index

/**
 * Object that holds all of the loaded tools
 */
gaerdvark.tools = {};

/**
 * Setup the location where our shared models will reside.
 */
gaerdvark.models = {};

/**
 * Object that holds all the singleton objects
 */
gaerdvark.SingletonTools = {};

/**
 * For cacheing stuff
 * 
 * @type {Object}
 */
gaerdvark.cache = {};

/**
 * Super publics are system methods that are added by js classes and taken care
 * of by them. Superpublic methods should only be used with tools that are loaded
 * once. If the tool is loaded more that once the the superpublic will only use
 * the first instance of the tool and once any instance of the tool is destroyed then
 * the it will be gone for all other instances.
 * @type type
 */
gaerdvark.superPublics = {};

/**
 * checks if function exists in super publics
 * @param {type} functionName
 * @returns {@exp;gaerdvark@pro;superPublics@call;hasOwnProperty}
 */
gaerdvark.superPublics.exists = function (functionName){
	return gaerdvark.superPublics.hasOwnProperty(functionName.toString());
};

gaerdvark.superPublics.add = function (functionName, func){
	if(!gaerdvark.superPublics.exists(functionName)){
		gaerdvark.superPublics[functionName] = func;
		return true;
	}
	
	return false;
};

gaerdvark.superPublics.remove = function (functionName){
	delete gaerdvark.superPublics[functionName];
};

/**
 * An object that holds system wide constants
 * @type Object
 */
gaerdvark.constants = {};

gaerdvark.constants.ALTERNATE_PAGE_DIV_ID = "alternatePageDivId" + gaerdvark.utils.uuid();

/**
 * Object that holds runtime environment settings
 */
gaerdvark.environment = {};

/**
 * Environment value that when set will tell the multimedia plugin to rewrite the scheme://hostname:port/ information to whatever
 * is specified in the rewriteMultimediaURL value.  If the value is null it ignores the rewrite and does nothing.
 * This makes it possible for devices such as the iPad to make audio work correctly by serving up the media content on it's own
 * internal webserver so it can route the multimedia to the correct local file.  Currently iOS prevents the multimedia requests
 * from being intercepted from within the app network proxy so the webserver rewriting allows this functionality to occur.
 */
gaerdvark.environment.rewriteMultimediaURL = null;

/**
 * Audio volume level for the system.  0 <= volume <= 1.0.  Changing this environmental value
 * will affect any tools that use audio when they are created.  Tools already existing at the time should use the
 * "MultiMedia Set Volume Level" event.  @see GaeMultiMedia for example
 * @type Number
 */
gaerdvark.environment.audioVolumeLevel = 1.0;

var commManager = new CommManager();

var toolEventHandler = new ToolEventHandler();
var stateHandler = new StateHandler();
var globalErrorHandler = new GlobalErrorHandler();
var BugReportManager = new GlobalBugReportManager();
BugReportManager.setErrorHandler(globalErrorHandler);
var hashManager = new HashManager();
var mobileManager = new gaerdvark.MobileManager();
var googleAnalyticsManager = new gaerdvark.GoogleAnalyticsManager();
var notificationManager = new gaerdvark.NotificationManager();
var userInfoCollectionManager = new gaerdvark.UserInfoCollectionManager();
var userPermissionMap = new UserPermissionMap();

var toolLoadingManager = new ToolLoadingManager();

toolEventHandler.setCommunicationManager(commManager);
toolEventHandler.setErrorHandler(globalErrorHandler);

globalErrorHandler.setToolEventHandler(toolEventHandler);
globalErrorHandler.setCommunicationManager(commManager);

commManager.setToolEventHandler(toolEventHandler);
commManager.setErrorHandler(globalErrorHandler);
commManager.setUserPermissionMap(userPermissionMap);
commManager.setXMLHTTPRequestFactory(new XMLHTTPRequestFactory());


stateHandler.setToolEventHandler(toolEventHandler);
stateHandler.setErrorHandler(globalErrorHandler);

hashManager.setToolEventHandler(toolEventHandler);
hashManager.setToolLoadingManager(toolLoadingManager);

userPermissionMap.setToolEventHandler(toolEventHandler);

toolLoadingManager.setToolEventHandler(toolEventHandler);
toolLoadingManager.setErrorHandler(globalErrorHandler);

mobileManager.setToolEventHandler(toolEventHandler);

googleAnalyticsManager.setHashManager(hashManager);
googleAnalyticsManager.setToolEventHandler(toolEventHandler);

notificationManager.setToolEventHandler(toolEventHandler);
notificationManager.setToolLoadingManager(toolLoadingManager);

userInfoCollectionManager.setToolEventHandler(toolEventHandler);

BugReportManager.init();
commManager.init();
stateHandler.init();
userPermissionMap.init();
hashManager.init();
toolLoadingManager.init();
mobileManager.init();
googleAnalyticsManager.init();
notificationManager.init();
userInfoCollectionManager.init();

/**
 * Add the bug submit tool to the page so that people can submit bugs.
 */
// toolEventHandler.addToolListener(function (){
// 	function callback(loadToolParam){
// 		var submitBug = new Tool(loadToolParam.tool);
// 		submitBug.appendToNode(document.getElementsByTagName('body')[0]);
// 		submitBug.activate();
// 	}

// 	var loadToolParam = new LoadToolParam("Submit Bug",	callback);
// 	toolLoadingManager.loadToolRequest(loadToolParam);
// },"APILoaded");


toolEventHandler.addToolListener(function (){
	toolEventHandler.setServerEvents(_SESSION['serverEventList']);
}, 'APILoaded');


/**
 * Add some admin tools to the hash changed event.
 * Right now we only have clearCache. To clear the cache and
 * local storage add this to the hash
 * &admin=clearCache
 */
toolEventHandler.addToolListener(function (hashObj){
	if(!hashObj.hasOwnProperty("admin")){
		return;
	}

	var adminAction = hashObj["admin"];

	if(adminAction == 'clearCache'){
		hashManager.removeHashParam("admin");
		localStorage.clear();
		location.reload(true);
	}
}, "HashChanged");





/** The Methods below are short hand globals to be able to call these functions
 * throughout the codebase.
 * TODO: At some point we may want to revisit these and refactor them
 * away so that we can make the system easier to test.
 */

/**
 *
 * Fires an event in this JS enviornment
 *
 * @param object
 *          what contains the data to pass along with the event
 * @param string
 *          name of the event to fire
 * @param bool
 *          if the event should be sent synchronously
 */
var fireToolEvent = toolEventHandler.fireToolEvent;

/**
 * Registers a function as a listener to an event
 *
 * @param function
 *          what to be called when the event is fired
 * @param string
 *          Name of the event to listen to
 */
var addToolListener = toolEventHandler.addToolListener;

/**
 * Unregisters a function as a listener to an event, if callback is null then all listeners to that
 * event are be removed
 *
 * @param function
 *          that is registered to be called when the event is fired
 * @param string
 *          name of the event to remove a listener from
 */
var removeToolListener = toolEventHandler.removeToolListener;

/**
 * return a unique event name for a tool to make available if a request is made to listen to it
 *
 * @return string a unique event name
 */
var nextUniqueEventName = toolEventHandler.createUniqueEventName;

/**
 * Gets the current hash in an object of name value pairs
 *
 * @return object name value pairs of the current hash
 */
var getHash = hashManager.getHash;

/**
 * Sets the hash to the given object
 *
 * @param object name value pairs of the desired hash
 */
var setHash = hashManager.setHash;

/**
 * adds the given param to the hash string
 *
 * @param name the name of the param
 * @param value the value of the param
 */
var addHashParam = hashManager.addHashParam;

/**
 * removes the given param to the hash string
 *
 * @param name the name of the param
 */
var removeHashParam = hashManager.removeHashParam;

/**
 * Asyncronous send a request to the server, the object is not cloned, meaning that if you reuse it
 * for another request before the callback is called the callback will get the latest change you put
 * into the object.
 */
var send = commManager.send;
/**
 * cancel a request that was sent to the server
 */
var cancelSend = commManager.cancelSend;
/**
 * used to request or cancel a short poll delay
 * single argument is boolean, true for request, false for cancel
 */
var shortPollDelay = commManager.shortPollDelay;

/**
 * Returns true if the tool allows custom css
 */
var allowsCustomCss = userPermissionMap.allowsCustomCss;

/**
 * Get the name of the tool that implements the given tool
 */
var getToolNameFromTool = userPermissionMap.getToolNameFromTool;

/**
 * Get the name of the tool that implements the given tool
 */
var getToolPackageFromTool = userPermissionMap.getToolPackageFromTool;

/**
 * Get the dependencies for a tool that implements the given tool name
 */
var getToolDependenciesForTool = userPermissionMap.getToolDependenciesForTool;

/**
 * Check if the user has permission to the given action
 */
var hasActionPermission = userPermissionMap.hasActionPermission;

/**
 * Check if the user has permission to the given tool
 */
var hasToolPermission = userPermissionMap.hasToolPermission;


/**
 * Throw an error
 */
var throwError = globalErrorHandler.logError;

/**
 * Check if an tool is loaded
 *
 * @returns bool true if the tool is in memory and can be used, false otherwise
 */
var isToolLoaded = toolLoadingManager.isToolLoaded;

/**
 * Asyncronous Request an action to be loaded, the object is not cloned, meaning that if you reuse
 * it for another request before the callback is called the callback will get the latest change you
 * put into the object.
 *
 * @param object
 *          LoadToolParam(action, callback)
 * @property action String to request
 * @property bodyTool bool if the tool belongs in the body tag
 * @property errorID string error id to throw an error to
 * @property callback function what to call when loading is done
 */
var loadTool = toolLoadingManager.loadToolRequest;

/**
 * unload an action
 *
 * @param string
 *          action name to unload
 */
var unloadTool = toolLoadingManager.unloadTool;

/**
 * Asyncronous add a permanent script to the document, the object is not cloned, meaning that if you
 * reuse it for another request before the callback is called the callback will get the latest
 * change you put into the object.
 *
 */
var addPermanentScript = toolLoadingManager.addPermanentScript;

/**
 * Asyncronous add a css link to the document.
 * It tracks the number of times a css is requested.
 */
var addThirdPartyCSS = toolLoadingManager.addThirdPartyCSS;

/**
 * Asyncronous remove a css link from the document.
 * It tracks the number of times a css removal is requested.
 * When the removes equal the requests the css link will be removed.
 *
 */
var removeThirdPartyCSS = toolLoadingManager.removeThirdPartyCSS;

/**
 * @param name
 *          of was you want to cache
 * @param value
 *          what to cache, string or object
 */
var cacheValue = AardCacheManager.add;

/**
 * @param name
 *          of was you want to cache
 * @returns boolean if it is in the cache
 */
var hasCachedKey = AardCacheManager.hasKey;

/**
 * @param name
 *          of what should be removed
 */
var removeKey = AardCacheManager.removeKey;

/**
 * @param name
 *          of property to get
 * @returns object or string of what was asked for
 */
var getCacheValue = AardCacheManager.get;

/**
 * Checks an item in the cache, if it older then the given values it will delete it
 */
var checkCacheKey = AardCacheManager.checkItem;

/**
 * Setup any events on the window object that are needed.
 */

(function(){

	var terminatedJSInstance = false;

	function listener (){
		if (terminatedJSInstance) {
			return;
		}
		// tell the server the client is unloading (release all locks)
		var sendObj = createSendObject('Terminate JSInstance');
		sendObj.synchronous = true;
		send(sendObj);
		terminatedJSInstance = true;
	}

	// This works in Chrome, Safari, IE, and Firefox.
	// No need to use window.addEventListener which didn't seem work anyway for these events.
	window.onbeforeunload = listener;
	window.onunload = listener;
}());


// window.addEventListener('error', function(e) {
// 	var msg = [];
// 	msg.push("We detected an Error (Possibly 404) ");
// 	msg.push("Current Hash: " + location.href );

// 	if(e.originalTarget){
// 		msg.push("Current Src: " + e.originalTarget.currentSrc);
// 	}

//     throwError(msg.join("\n"));
// }, true);
