/**
 * This file keeps track of memory leaks in JS. Since our whole system runs with no page refreshes
 * we want to make sure that there are no global variables and nothing kept in memory that isn't
 * being used.
 */
function StateHandler() {

	var objects = new Object();
	var functions = new Object();
	var variables = new Object();
	var index = new Object();

	var states = new Object();

	var toolEventHandler = null;

	/**
	 * Pointer to our handler that takes care of any error conditions such as logging etc we are dealing with.
	 */
	var errorHandler = null;

	var memLeakWhitelistHashmap = {
			"scayt":true,
			"scaytConfig":true,
			"djConfig":true,
			"sc_dojo":true,
			"sc_dijit":true,
			"sc_dojox":true,
			"node":true,
			"rangeText":true,
			"itemText":true,
			"getInterface":true,
			"console":true,
			"BugSubmit":true,
			"Digest":true,
			"0":true,
			"CkEditorLoader": true,
			"CKEDITOR": true,
			"data-cke-expando": true,
			"findPos": true, // thumbnailScroller
			"Codemirror": true, //Codemirror code. 

			"videojs": true, // VideoJS player
			"_V_": true, // VideoJS minified constructor
			"vjs": true, // VideoJs dev version constructor
			"errNum": true//video player
			,"VTTRegion": true//video player
			,"VTTCue": true,//video player
			"hasOwnProp":true,
			"_handleMultipleEvents":true,
			"_logType":true,
			"WebVTT":true,
			"cx":true,
			"vttjs":true,
			"showSlides":true,
			"vttjs":true,
			
			// edge
			"$0":true,
			"$1":true,
			"$2":true,
			"$3":true,
			"$4":true,
			"$5":true,
			"$6":true,
			"$7":true,
			"$8":true,
			"__BROWSERTOOLS_CONSOLE_BREAKDOWN_FUNC":true,
			"__BROWSERTOOLS_CONSOLE":true,
			"__BROWSERTOOLS_CONSOLE_ADDED":true,


			// the following are caused by js unit tests
			"asyncTest": true,
			"expect": true,
			"ok": true,
			"equal": true,
			"notEqual": true,
			"propEqual": true,
			"notPropEqual": true,
			"deepEqual": true,
			"notDeepEqual":true,
			"strictEqual":true,
			"notStrictEqual":true,
			"throws":true,
			"raises":true,
			"equals":true,
			"same":true,
			"QUnit":true,
			"module":true,
			"test":true,
			"start":true,
			"fireunit":true,
			
			//Google analytics
			"gaplugins":true,
			"gaGlobal":true,
			"gaData":true,
			"google_tag_data":true,
			"sa":true,


			// flash
			"__flash__arrayToXML":true,
			"__flash__argumentsToXML":true,
			"__flash__objectToXML":true,
			"__flash__escapeXML":true,
			"__flash__toXML":true,
			"__flash__request":true,
			"__flash_temp":true,
			"swfobject":true,

			// globals we should move to our tool space
			"fireToolEvent":true,
			"addToolListener":true,
			"removeToolListener":true,
			"nextUniqueEventName":true,
			"getHash":true,
			"setHash":true,
			"addHashParam":true,
			"removeHashParam":true,
			"send":true,
			"cancelSend":true,
			"shortPollDelay":true,
			"allowsCustomCss":true,
			"getToolNameFromTool":true,
			"getToolPackageFromTool":true,
			"getToolDependenciesForTool":true,
			"hasActionPermission":true,
			"hasToolPermission":true,
			"throwError":true,
			"isToolLoaded":true,
			"loadTool":true,
			"unloadTool":true,
			"addPermanentScript":true,
			"addThirdPartyCSS":true,
			"removeThirdPartyCSS":true,
			"cacheValue":true,
			"hasCachedKey":true,
			"removeKey":true,
			"getCacheValue":true,
			"checkCacheKey":true,
			"CodeMirror":true
	};

	/**
	 * For whitelist memLeak variables that can't be put in the list.
	 * Separate each with a pipe.
	 */
	var memLeakWhitelistRegex = new RegExp('^jQuery\\d+$|^\\d+$', '');

	this.setErrorHandler = function(handler) {
		errorHandler = handler;
	};

	/**
	 * Be able to set the tool event handler dependency that manages any tool listener
	 * events.
	 */
	this.setToolEventHandler = function(handler) {
		toolEventHandler = handler;
	};

	this.init = function() {
		addState('init');

		toolEventHandler.addToolListener(scriptAdded, 'ScriptLoaded');
		toolEventHandler.addToolListener(scriptRemoved, 'ScriptUnloaded');
		toolEventHandler.addToolListener(scriptRequested, 'ScriptRequested');
		toolEventHandler.addToolListener(apiLoaded, 'APILoaded');
	};

	function initState(stateName) {
		states[stateName] = true;
		objects[stateName] = new Array();
		functions[stateName] = new Array();
		variables[stateName] = new Array();
	}

	function unInitState(stateName) {
		delete states[stateName];
		delete objects[stateName];
		delete functions[stateName];
		delete variables[stateName];
	}

	function loopList(list, func) {
		for ( var i = 0; i < list.length; i++) {
			func(list[i]);
		}
	}

	function errorItem(item) {
		if (memLeakWhitelistHashmap[item]) {
			return;
		}
		if (memLeakWhitelistRegex.test(item)) {
			return;
		}
		
		if(item.indexOf("id-") === 0 || item.indexOf("jp_poster") > -1 || item.indexOf("avatar") > -1 || item.indexOf("WamiRecorder") > -1){
			return;
		}
		
		try {
			throw new Error("Mem leak: " + item);
		} catch (e) {
			errorHandler.throwError("Global not declared at script load  item=" + item, e);
		}
	}

	function deleteItem(item) {
		if (delete window[item]) {
			delete index[item];
			return;
		}
		index[item] = false;
		if (memLeakWhitelistHashmap[item]) {
			return;
		}
		if (memLeakWhitelistRegex.test(item)) {
			return;
		}
		try {
			throw new Error("Mem leak: " + item);
		} catch (e) {
			errorHandler.throwError("Unable to delete", e);
		}
	}

	function addState(stateName) {
		initState(stateName);
		for (var item in window) {
			if (index[item] == true) {
				continue;
			}
			index[item] = true;
			try {
				switch (typeof window[item]) {
					case 'function':
						functions[stateName].push(item);
						break;
					case 'object':
						objects[stateName].push(item);
						break;
					default:
						variables[stateName].push(item);
						break;
				}
			} catch (e) {
				variables[stateName].push(item);
			}
		}
	}

	//TODO merge the following functions
	function removeState(stateName) {
		if (states[stateName] == true) {
			loopList(functions[stateName], deleteItem);
			loopList(objects[stateName], deleteItem);
			loopList(variables[stateName], deleteItem);
			unInitState(stateName);
		}
	}

	function errorState(stateName) {
		if (states[stateName] == true) {
			loopList(functions[stateName], errorItem);
			loopList(objects[stateName], errorItem);
			loopList(variables[stateName], errorItem);
		}
	}

	function scriptRemoved(toolName) {
		removeState(toolName);
		scriptRequested(toolName, 'Mem Leak ');
	}

	function scriptRequested(toolName, message) {
		if(!message) message = 'Mem Leak before ';

		var memLeakName = message + toolName;
		addState(memLeakName);
		if(functions[memLeakName].length === 0
				&& objects[memLeakName].length === 0
				&& variables[memLeakName].length === 0) {
			removeState(memLeakName);
		}
		else {
			errorState(memLeakName);
		}
	}

	function scriptAdded(src) {

		addState(src);
	}

	function apiLoaded(api) {
		setTimeout(function(){addState(api.name);},1);
	}
};