gaerdvark.MobileManager = function() {
	
	/**
	 * Boolean that can be used in other parts of the handler to say we are on the iPad app.
	 */
	var isIPadApp = false;
	
	/**
	 * Event handler that allows for test mocks to verify this javascript function is working.
	 */
	var toolEventHandler = null;
	
	/**
	 * The name of the target window to open on the iPad app.
	 */
	var IPAD_APP_LINKS_EXTERNAL_TARGET = 'external';
	
	/**
	 * Enables page navigation by swiping on a mobile device.  Users can swipe left to go the next page
	 * and can swipe right to go to the previous page.  The swiping is enabled on the entire body.
	 */
	function enableSwipeNavigation() {
		$("body").on("swipeleft", function(event){ // dragging to the left go to the next page
			// don't let swiping occur on a draggable element.  Otherwise the page will switch as soon as the user stops dragging.
			var answerNodes = $(event.target).closest(".ui-draggable").filter(":first");
			if (answerNodes.length > 0) {
				return;
			}
			toolEventHandler.fireToolEvent({}, "Project Next Page");
		})
		.on("swiperight", function(event){ // dragging to the right go to the previous page
			var answerNodes = $(event.target).closest(".ui-draggable").filter(":first");
			if (answerNodes.length > 0) {
				return;
			}
			toolEventHandler.fireToolEvent({}, "Project Previous Page");
		});
	}
	
	/**
	 * Unbinds the page navigation events through swiping on a mobile device.
	 */
	function removeSwipeNavigation() {
		$("body").unbind("swipeleft swiperight");
	}
	
	/**
	 * "Page Loaded" event handler that cleans up all of the page links on the ipad by removing their onclick for any 
	 * window.open events.
	 */
	function cleanupIpadAppPageLinks(pageData, eventName) {
		if (pageData.pageId) {
			$("." + pageData.pageId).find("a[onclick^='window.open'][href^='http://']").attr('onclick', '');
		}
		else {
			throwError("Page Loaded event called with no pageId specified");
		}
		// no page id do nothing
	}
	
	// we define this function here so that we share memory and we keep the closure scope clean from the data processor.
	/**
	 * This empty function does nothing and is used in the removeWindowOpenReferences.  We define it here rather than an anonymous function
	 * so that we can avoid binding up the processorObject into the closureScope.  The object is a huge data object that we don't want sticking around.
	 */
	function emptyWindowOpenReplacement() {
		return true;
	}
	
	/**
	 * ProcessHtml passes in the processorObject and allows you to do some post processing logic of your own before it returns the DOMNode
	 * to the tool that asked for it.  We hook into that processing system and replace all of the anchor tags that have a window.open onclick event
	 * with our own onclick function.  The onclick function has to be set instead of removed because the gaerdvark system right now removes any href's if
	 * there is not an attached onclick function (this is an old design decision that should be revisited...).  We also set the target of the anchor to
	 * IPAD_APP_LINKS_EXTERNAL_TARGET.
	 * @param processorObject The processorObject with all kinds of properties for modifying the processed html.  See ProcessHtml.processHtml for more details on what this object is.
	 */
	function removeWindowOpenReferences(processorObject) {
		var workingObj = processorObject.completeObj;
		// grab all of the anchors
		if (!workingObj) { // if there is no object to work on we are not going to do anything.
			return;
		}
		// TODO: stephen we will have to benchmark the performance of this... it only runs on mobile so we won't be showing huge lists of data like we do
		// in other parts of the system... but the performance could be pretty bad...
		var anchors = workingObj.querySelectorAll("a[onclick^='window.open'][href^='http://']");
		if (anchors.length) {
			var length = anchors.length;
			for (var i = 0; i <length; i++) {
				anchors[i].removeAttribute('onclick');
				anchors[i].onclick = emptyWindowOpenReplacement; // process html kills anything that does not have an onclick event.
				anchors[i].target = IPAD_APP_LINKS_EXTERNAL_TARGET;
			}
		}
	}
	
	/**
	 * On the iPad it has to display in it's own modal any external links so that the user can hit the done button
	 * when they are done viewing the external page.  Because the original system has window.open's all over the place for anchor tag
	 * links we have to remove those onclick event handlers.  So we hook into the ProcessHtml processor and add a global parser rule
	 * that will remove the window references and make sure the links still show up correctly.
	 */
	function addLinkCleanupProcessor() {
		ProcessHtml.prototype.addPostProcessor(removeWindowOpenReferences);
	}
	
	/**
	 * Adds all of the mobile classnames if we are on mobile as well as adding any event hooks / processors that change the dynactive system
	 * if we are on a mobile device.
	 */
	function initMobile() {
		if (gaerdvark.utils.mobileDetect.isMobile)
		{
			enableSwipeNavigation(); // enable swipe navigation on the mobile device.
			
			var $htmlNode = $("html");
			if (gaerdvark.utils.mobileDetect.isIpad()) 
			{
				$htmlNode.addClass("ipad");
				if (gaerdvark.utils.mobileDetect.isIPadDynactiveApp())
				{
					// let's add the css class to the html tag so that
					// our css style-sheets can key specific styles off the ipad app.
					$htmlNode.addClass("ipad-app");
					isIPadApp = true;
				}
				
				addLinkCleanupProcessor();
			}
		}
	}
	
	this.setToolEventHandler = function(handler) {
		toolEventHandler = handler;
	}
	
	this.init = function() {
		// change the sensitivity of the swipe event.
		// TODO: stephen this assumes jquery mobile has already loaded... not sure I like this but the mobileinit fires
		// immediately and this script would have to be placed before jquery mobile which we don't want.
		$.event.special.swipe.horizontalDistanceThreshold = (screen.availWidth)/6;
		
		toolEventHandler.addToolListener(initMobile, "APILoaded")
	};
	this.destruct = function() {
		if (gaerdvark.utils.mobileDetect.isMobile) {
			disableSwipeNavigation();
		}
	}
}