/**
 * Base class for all tool classes, does html reference handling
 */
function Tool(requestedTools) {
	var me = this;
	var uniqueToolID = 0;
	var toolUuid = gaerdvark.utils.uuid();
	var rootNode = null;
	
	/**
	 * The tool event listeners that the tool has subscribed to
	 * that should be removed on destruct
	 */
	var toolEventListeners = {};
	
	/**
	 * Tools that have been created as children of the current tool
	 */
	var loadedTools = new Object();
	
	//tools are always an array
	var tools = null;

	// Is requestedActions an array?
	if (Object.prototype.toString.apply(requestedTools) === '[object Array]') {
		tools = requestedTools;
	} else {
		tools = new Array(requestedTools);
	}

	var subTool = null; //Instantiation of the current Tool
	var currentTool = null; // String name of the current Tool
	var subToolMissedCalls = {};
	//boolean to say if the tool has been placed on the dom and told to display
	var activated = false;
	// pointer to parent tool USE CAUTIOUSLY!!!!!!!
	var parentTool = null;

	var uniqueEventName = nextUniqueEventName();

	/**
	 * Private method that loops through all tools that this tool can have as subtools, then
	 * instantiates the first valid one it finds, and unloads what it can
	 */
	function checkToolPermissions() {
		for (var i = 0; i < tools.length; i++) {
			if(hasToolPermission(tools[i])) {
				if(currentTool == tools[i])
					return;
				unsetSubTool();
				//Need to change sub tool
				if(isToolLoaded(tools[i])) {
					setSubTool(tools[i]);
					return;
				}
			}else if(currentTool == tools[i]) {
				unsetSubTool();
			}
		}
		if(currentTool != null) {
			unsetSubTool();
		}
		currentTool = null;
	}

	/*
	* Private method that assigns the subTool based on the tool requested.
	* @param tool string
	*/
	function setSubTool(tool) {
		currentTool = tool;
		try {
			var toolFileName = getToolNameFromTool(tool);
			// make superclass methods available to subtool

			//check it is a singleton
			if(gaerdvark.tools[toolFileName].getInstance) {
				subTool = gaerdvark.tools[toolFileName].getInstance();
				gaerdvark.SingletonTools[currentTool] = me;
				me.toolInstantiated = true;
			}
			else {
				subTool = new gaerdvark.tools[toolFileName]();
			}
		} catch (e) {
			unsetSubTool();
			throwError('failed to create tool for tool ' + tool, e);
			return;
		}

		//expose sub tool public methods that are part of the world interface and if you did not
		// overload protected functions add them to your object
		setInheritance(me, subTool);

		if (activated) {
			if(!subToolMissedCalls['activate'])
				subTool.activate();

			for(var func in subToolMissedCalls) {
				switch(func) {
					case 'appendToNode':
					case 'removeNode':
						for(var i = 0; i < subToolMissedCalls[func].length; i++) {
							callChildMethod(func, subToolMissedCalls[func][i]);
						}
						break;
					default:
						callChildMethod(func, subToolMissedCalls[func]);
						break;
				}
				delete subToolMissedCalls[func];
			}
		}
	}

	/**
	 * Sets up the superClass as the super class to the sub class, supports multiple superclasses
	 *
	 * @params instance superClass the super class to inherit from
	 * @params instance subClass the sub class to get inheritance
	 */
	function setInheritance(superClass, subClass) {
		if(superClass instanceof Tool) { // Special case when the parent is Tool, always called first for a new instance
			for(var pub in world) {
				if (typeof subClass[pub] == 'function' && (superClass[pub] == world[pub])) {
					superClass[pub] = subClass[pub];
				}
				if(subClass[pub] == undefined) {
					subClass[pub] = world[pub];
				}
			}

			//if you did not overload protected functions add them to your object
			for(var prot in group) {
				if (subClass[prot] == undefined) {
					subClass[prot] = group[prot];
				}
			}

			//Gives all protected properties pointers in the normal class
			for(prot in subClass.protectedMethods) {
				if(subClass[prot] == undefined) {
					subClass[prot] = subClass.protectedMethods[prot];
				}
			}

			//Check to see if the subTool wants to expose any methods
			if(subClass.publicMethods) {
				for(pub in subClass.publicMethods) {
					if (superClass[pub] === undefined) {
						superClass[pub] = subClass.publicMethods[pub];
					}

					//Give shorter pointers to your own public methods
					if(subClass[pub] === undefined) {
						subClass[pub] = subClass.publicMethods[pub];
					}
				}
			}

			var newViews = {};
			if(subClass.views) {
				for(var i in subClass.views) {
					newViews[i] = subClass.views[i];
				}
				subClass.views = newViews;
			}

			subClass.group = group;
			subClass.world = world;

			subClass.construct();

		} else { //make a class inherit from another class, both should already be inheriting from the same Tool class
			//if you did not overload protected functions add them to your object

			//TODO this is a work around and needs to be fixed
			if(subClass.html) {
				superClass.html = subClass.html + "";
			}

			newViews = {};
			if(subClass.views) {
				for(i in superClass.views) {
					newViews[i] = superClass.views[i];
				}
				for(i in subClass.views) {
					newViews[i] = subClass.views[i];
				}
				superClass.views = newViews;
			}

			if(!superClass.protectedMethods) {
				superClass.protectedMethods = new function() {};
			}

			if(subClass.protectedMethods) {
				for(prot in subClass.protectedMethods) {
					superClass[prot] = subClass.protectedMethods[prot];
				}
			}

			if(subClass.publicMethods) {
				for(pub in subClass.publicMethods) {
					superClass[pub] = subClass.publicMethods[pub];
				}
			}

			if(superClass.protectedMethods) {
				for(prot in superClass.protectedMethods) {
					if(subClass[prot] == undefined) {
						subClass[prot] = superClass.protectedMethods[prot];
					}
				}
			}

			//Put the superclass public methods on the subClass
			if(superClass.publicMethods) {
				for(pub in superClass.publicMethods) {
					if(subClass[pub] == undefined) {
						subClass[pub] = superClass.publicMethods[pub];
					}
				}
			}
		}


		//Add the superPublics to Gaerdvark
		if(subClass.superPublics){

			if(!gaerdvark.superPublics){
				gaerdvark.superPublics = {};
				throwError("Super Publics not being declared in boot strap or getting deleted somewhere.");
			}

			for(pub in subClass.superPublics){
				if(gaerdvark.superPublics.hasOwnProperty(pub) && gaerdvark.superPublics[pub].toString() !== subClass.superPublics[pub].toString()){
					throwError("The super public function " + pub + " was already registered. " + subClass.tool.getCurrentTool() + " tried to overload it.");
				}else{
					gaerdvark.superPublics[pub] = subClass.superPublics[pub];
				}
			}
		}

	}

	/**
	 * Private method to set Tool back to a default "null" state by resetting all public and protected
	 * methods either the tools defaults or null.
	 */
	function unsetSubTool() {
		if(currentTool == null || subTool == null)
			return;

		//call destruct on the subtool so that it can do what it needs to.
		callChildMethod('destruct', []);

		//zero out all of the sub tools protected methods.
		for(var proc in group) {
			if(subTool[proc] == group[proc]) subTool[proc] = function(){};
		}

		delete group.subTool;

		//puts the worlds public methods back to the world's default methods and clears out
		// the other public methods in the sub tool.
		for(var pub in me) {
			if(me[pub] == subTool[pub] && pub != 'destruct') {
				if(world[pub]) {
					me[pub] = world[pub];
				} else {
					delete me[pub];
				}
			}
		}

		//Remove the tool from the DOM
		try {
			delete subTool;
			if(activated){
				group.setCurrentHtmlTree(document.createComment('placeholder for root node ' + currentTool));
				group.replaceRootNode();
			}
			subTool = null;
		} catch(e) {
			throwError("could not unset subtool ", e);
		}

		currentTool = null;
	}

	/**
	 * Private method that adds the child node to the parent node.
	 * Keeps a pointer to child so that it can be replaced and so
	 * when the tool is destructed it can be remove from the document
	 *
	 * @param parentNode node
	 * @param childNode node
	 * @param beforeNode node
	 */
	function insertChildIntoParentBefore(parentNode, childNode, beforeNode) {
		//this can happen when a page tries to display a tool that they do not have access to.
		if(gaerdvark.utils.isEmpty(parentNode)){
			return;
		}

		try{
			parentNode.insertBefore(childNode, beforeNode);
			rootNode = childNode;
		} catch (error) {
			throwError('child node cannot be inserted into parent node ' + parentNode, error);
		}
	}

	/**
	 * Encapsulated object to house privilaged data and functions for the tool/subtool
	 */
	var group = new function() {
		// this is so when you pass a pointer of yourself you can pass the tool, not the subtool
		// used by processHtml and maybe other methods
		this.tool = me;

		/**
		 * checks to see if the specified node has the specified className, returns true if true, false if false.
		 *
		 * @param evt event object
		 * @param className string
		 * @param node reference
		 */
		this.nodeContainsClass = function (evt, className, node) {
			if (node === undefined) node = rootNode;
			if (node.className) {
				var classList = node.className.trim().split(' ');
				if (classList.indexOf(className) === -1) {
					return false;
				} else {
					return true;
				}
			} else {
				return false;
			}
		};
		
		this.unloadToolListeners = function() {
			for (var eventName in toolEventListeners) {
				var functions = toolEventListeners[eventName];
				if (functions) {
					functions.forEach(function(callback) {
						removeToolListener(callback, eventName);
					});
				}
			}
			toolEventListeners = {};
		};

		/**
		 * Make an alias that is easier to remember
		 * @see nodeContainsClass
		 */
		this.hasClass = this.nodeContainsClass;

		/**
		 * adds the class to a node or array of nodes if specified
		 * otherwise to the root node
		 *
		 * @param {event} event object
		 * @param {string} className
		 * @param {mixed} nodeArray (optional) null, or string id of node, or node, or array of nodes
		 *		null,
		 *		string id of a single node,
		 *		single node,
		 *		array of nodes,
		 *		if undefined, null, or empty array the class will be added to the root node
		 *	@param {boolean} stopPropagation optional
		 */
		this.addClassToNode = function (evt, className, nodeArray, stopPropagation) {
			if (typeof nodeArray === "string") {
				nodeArray = document.getElementById(nodeArray);
			}
			if ((nodeArray === undefined) || (nodeArray === null)
					  || ((nodeArray instanceof Array) && (nodeArray.length === 0))) {
				nodeArray = [rootNode];
			}
			if (nodeArray instanceof Node) nodeArray = [nodeArray];
			for (var i = 0; i < nodeArray.length; i++) {
				if (gaerdvark.utils.isEmpty(nodeArray[i])) {
					continue;
				}
				if (typeof nodeArray[i].className == 'string') {
					var classList = nodeArray[i].className.trim().split(' ');
					if (classList.indexOf(className) === -1) {
						nodeArray[i].className += ' ' + className;
					}
				}
			}
			if ((stopPropagation) && (evt)) {
				group.stopPropagation(evt);
			}
		};

		/**
		 * removes the class from a node or array of nodes if specified
		 * otherwise from the root node
		 *
		 * @param evt event object
		 * @param className string
		 * @param {mixed} nodeArray (optional) null, or string id of node, or node, or array of nodes
		 *		null,
		 *		string id of a single node,
		 *		single node,
		 *		array of nodes,
		 *		if undefined, null, or empty array the class will be added to the root node
		 *	@param {boolean} stopPropagation optional
		 */
		this.removeClassFromNode = function (evt, className, nodeArray, stopPropagation) {
			if (typeof nodeArray === "string") {
				nodeArray = document.getElementById(nodeArray);
			}

			if (gaerdvark.utils.isEmpty(nodeArray)) nodeArray = [rootNode];
			if (nodeArray instanceof Node) nodeArray = [nodeArray];

			for (var i = 0; i < nodeArray.length; i++) {
				if (gaerdvark.utils.isEmpty(nodeArray[i])) {
					continue;
				}
				if (typeof nodeArray[i].className === 'string') {
					var classList = nodeArray[i].className.trim().split(' ');
					var chatHideMsgClassIndex = classList.indexOf(className);
					if (chatHideMsgClassIndex !== -1) {
						classList.splice(chatHideMsgClassIndex, 1);
						nodeArray[i].className = classList.join(' ');
					}
				}
			}
			if ((stopPropagation) && (evt)) {
				group.stopPropagation(evt);
			}
		};

		/**
		 * if the node has the class, it is removed, otherwise it is added
		 * takes an string node id or single node pointer or array of node pointers
		 * otherwise it works on the root node
		 *
		 * @param evt event object
		 * @param className string
		 * @param nodeArray mixed (optional)
		 *   single node or array of nodes or string id of a single node
		 *   if undefined class will be added to the root node
		 */
		this.toggleClassOfNode = function (evt, className, nodeArray) {
			/**
			 * sending old reveal answers to the new reveal answer code. 
			 */
			if(me.getCurrentTool() === "Page" && className === "revealAnswerHidden"){
				subTool.publicMethods.revealAnswer(evt);
				return;
			}

			if (typeof nodeArray === "string") {
				nodeArray = document.getElementById(nodeArray);
			}
			if (nodeArray === undefined) nodeArray = [rootNode];
			if (nodeArray instanceof Node) nodeArray = [nodeArray];
			for (var i = 0; i < nodeArray.length; i++) {
				// no-op if they send null, nodearray === null as a value
				if (gaerdvark.utils.isEmpty(nodeArray[i])) {
					continue;
				}
				if (typeof nodeArray[i].className == 'string') {
					var classList = nodeArray[i].className.trim().split(' ');
					var chatHideMsgClassIndex = classList.indexOf(className);
					if (chatHideMsgClassIndex !== -1) {
						classList.splice(chatHideMsgClassIndex, 1);
						nodeArray[i].className = classList.join(' ');
					} else {
						nodeArray[i].className += ' ' + className;
					}
				}
			}
		};

		/**
		 * stops further propagation of the event
		 * @param {event} evt
		 */
		this.stopPropagation = function(evt) {
			if (evt.stopPropagation) {
				// W3C standard
				evt.stopPropagation();
			} else {
				// older ie and maybe some mobile
				evt.cancelBubble = true;
			}
		}

		//default function for when a tool is constructed
		this.construct = function () {};

		/**
		 * Replaces all rootNode with currentHTMLTree.
		 * If currentHTMLTree is a string it will be treated as innerHTML
		 * and the first node of that innerHTML will be used.
		 */
		this.replaceRootNode = function() {
			activated = true;
			try {
				var currentHTMLTree = this.getCurrentHtmlTree();
				if(rootNode && rootNode.parentNode && currentHTMLTree) {
					var parent = rootNode.parentNode;
					parent.replaceChild(currentHTMLTree, rootNode);
					rootNode = currentHTMLTree;
					// If this tool allows client custom css add "clientCustomCss" as a class to the root node.
					if (allowsCustomCss(subTool.getCurrentTool())) {
						this.addClassToNode(null, "customCss", rootNode);
					}
					this.defaultProcessHtmlObj.setRootNodesUpdated(true);
				}
				this.checkForHtmlAddedTools();
			} catch(error) {
				throwError('Could not replace root node with currentHTMLTree ', error);
			}
		};

		/**
		 * Puts the html in the root node then binds the html to it.
		 * This is for building a tool using Knockout (ko)
		 * 
		 * @param html 
		 * @param dataModel 
		 */
		this.bindHtml = function (html, dataModel) {
			this.setCurrentHtmlTree(html);
			this.replaceRootNode();
			ko.applyBindings(dataModel, this.getCurrentHtmlTree());
		}

		// instantiate a process html object for legacy methods
		// and pass this group so processHtml has access to the public methods and the tool using the this.tool parameter
		this.defaultProcessHtmlObj = new ProcessHtml(this);

		this.processHtml = this.defaultProcessHtmlObj.processHtml.bind(this.defaultProcessHtmlObj);

		this.processHtmlAndReplace = this.defaultProcessHtmlObj.processHtmlAndReplace.bind(this.defaultProcessHtmlObj);

		this.processHtmlAndReturnTree = this.defaultProcessHtmlObj.processHtmlAndReturnTree.bind(this.defaultProcessHtmlObj);

		this.dynamicOn = this.defaultProcessHtmlObj.dynamicOn; // object not a function so we don't need to keep the this pointer.

		this.setCurrentHtmlTree = this.defaultProcessHtmlObj.setCurrentHtmlTree.bind(this.defaultProcessHtmlObj);

		this.getCurrentHtmlTree = this.defaultProcessHtmlObj.getCurrentHtmlTree.bind(this.defaultProcessHtmlObj);
		
		/**
		 * Searches the node's current html tree for the node that matches the given css selector.  It follows the same format as document.querySelector
		 * @param {string} The CSS selector that you want to find in the child descendants of the current html tree
		 * @return {DOMNode} The node that matches the selector or null if none is found
		 */
		this.findNode = function(selector) {
			if (this.getCurrentHtmlTree() === null || typeof this.getCurrentHtmlTree().querySelectorAll !== 'function') {
				throwError("findNode called where getCurrentHtmlTree is not a valid DOMNode");
				return null;
			}
			return this.getCurrentHtmlTree().querySelector(selector);
		};
		
		/**
		 * Searches the node's current html tree for the node list that matches the given css selector.  It follows the same format as document.querySelectorAll
		 * @param {string} The CSS selector that you want to find in the child descendants of the current html tree
		 * @return {StaticNodeList} The node list that matches the selector
		 */
		this.findAllNodes = function(selector) {
			if (this.getCurrentHtmlTree() === null || typeof this.getCurrentHtmlTree().querySelectorAll !== 'function') {
				throwError("findNode called where getCurrentHtmlTree is not a valid DOMNode");
				return null;
			}
			return this.getCurrentHtmlTree().querySelectorAll(selector);
		};
		
		this.findNodes = this.findAllNodes;

		this.unloadHtmlLoadedTools = this.defaultProcessHtmlObj.unloadHtmlLoadedTools.bind(this.defaultProcessHtmlObj);

		this.checkForHtmlAddedTools = this.defaultProcessHtmlObj.checkForHtmlAddedTools.bind(this.defaultProcessHtmlObj);

		/**
		 * Fires an event to the system and contains the data that was passed in
		 * as well as the toolUuid of the tool that fired the event, this makes it possible
		 * for the tool firing the event to ignore the event for themselves, if they so choose.
		 */
		this.fireToolEvent = function(data, eventName) {
			var eventData = {
				"toolUuid": toolUuid
				,"data": data
			};
			fireToolEvent(eventData, eventName);
		};
		
		
		this.addToolListener = function(callback, eventName) {
			if (!toolEventListeners.hasOwnProperty(eventName)) {
				toolEventListeners[eventName] = [];
			}
			toolEventListeners[eventName].push(callback);
			
			// add it to the global one
			addToolListener(callback, eventName);
		};
		
		this.removeToolListener = function(callback, eventName) {
			if (!toolEventListeners.hasOwnProperty(eventName)) {
				throwError("Cannot remove listener " + currentTool + "[" + toolUuid + "] is not listening to " + eventName);
				return;
			}
			var indexOfCallback = toolEventListeners[eventName].indexOf(callback);
			if (indexOfCallback === -1) {
				throwError("Cannot remove listener " + currentTool + "[" + toolUuid + "] is not listening to the function you attempted to remove for event " + eventName);
				return;
			}
			
			toolEventListeners[eventName].splice(indexOfCallback, 1);
			
			// remove it from the global one
			removeToolListener(callback, eventName);
		}

		/**
		 * Creates a new tool instance and returns the heap pointer
		 */
		this.createTool = function (toolName) {
			//BugReportManager.addLogEntry("createTool", {"toolName":toolName});
			try {
				if(gaerdvark.SingletonTools[toolName] && gaerdvark.SingletonTools[toolName].toolInstantiated) {
					return gaerdvark.SingletonTools[toolName];
				}
				var id = (new Date).getTime() + "" + (uniqueToolID++);
				var newTool = new Tool(toolName);
				loadedTools[id] = newTool;
				return newTool;
			} catch (e) {
				throwError('failed to init tool ' + toolName, e);
			}
			return new Tool();
		};

		/**
		 * destroy a tool and remove references to it
		 *
		 * @param tool Object
		 */
		this.destroyTool = function(tool) {
			//BugReportManager.addLogEntry("destroyTool", {"toolName":tool.getCurrentTool()});
			try {
				var id = null;
				for(var i in loadedTools) {
					if(loadedTools[i] == tool) {
						id = loadedTools[i];
						break;
					}
				}
				if (id) {
					tool.destruct();
					delete loadedTools[id];
				}
			} catch (e) {
				throwError('failed to destroy tool ' + e);
			}
		};

		this.makePropertyKey = function (propertyName, dataObj) {
			var newDataObj = new Object();
			for (var key in dataObj) {
				newDataObj[dataObj[key][propertyName]] = dataObj[key];
			}
			return newDataObj;
		};

		/**
		 * Calls the observe method with the passed in object, can be forced to be
		 * synchronus.
		 *
		 * @params object obj the object to pass
		 * @params boolean sync defauls to false
		 */
		this.notify = function (obj, sync) {
			fireToolEvent(obj, uniqueEventName, sync);
		};

		/**
		 * Makes the subtool inherit from the given tool, expected to be a dependency,
		 *	does call construct on the superClass
		 *	does not call activate on the superClass
		 *
		 * @params string toolName the name of the tool to inherit form
		 */
		this.inherit = function(toolName) {
			var toolFileName = getToolNameFromTool(toolName);
			if(gaerdvark.tools[toolFileName]) {
				if(gaerdvark.tools[toolFileName].getInstance) {
					var superClass = gaerdvark.tools[toolFileName].getInstance();
				}else{
					superClass = new gaerdvark.tools[toolFileName]();
				}

				setInheritance(superClass, subTool);
				setInheritance(me, superClass);

				if(!subTool.superClass) {
					subTool.superClass = {};
				}
				subTool.superClass[toolName] = superClass;
			}
			else {
				throwError('Asked to inherit from a tool that is not loaded. Trying to load tool: ' + toolName);
			}

		};

		this.getParentTool = function() {
			return parentTool;
		};

	};

	var world = new function() {

		function dataInitilized(sendObj) {
			callChildMethod('processData', [sendObj]);
			group.replaceRootNode();
		}

		/**
		 * This does not set the activate flag and is a default implementation
		 * of activate that takes care of getData and process data genericly
		 */
		this.activate = function () {
			//arguments is a var in every function, just passing them along
			var returnVal = callChildMethod('getData', arguments);
			if(returnVal && typeof(returnVal) == 'object') {
				returnVal.callback = dataInitilized;
				send(returnVal);
				return;
			}
			dataInitilized(returnVal);
		};



		this.getData = function() {
			return true;
		};

		this.processData = function() {
			return;
		};

		this.destruct = function() {
			return;
		};

		this.replaceNode = function() {
			return;
		};

		this.appendToNode = function() {
			return;
		};

		this.removeNode = function () {
			return;
		};

		/**
		 * return the subtool tool this tool currently instantiates
		 *
		 * @return string tool name
		 */
		this.getCurrentTool = function() {
			return currentTool;
		};

		this.getToolUuid = function() {
			return toolUuid;
		};
		
		this.debugToolMsg = function(msg, includeParent) {
			if (includeParent && parentTool) {
				console.log(currentTool + "[" + toolUuid + "]&&parent=" + parentTool.getCurrentTool() + "[" + parentTool.getToolUuid() + "]: " + msg);
			}
			else {
				console.log(currentTool + "[" + toolUuid + "]: " + msg);
			}
		};
		
		this.errorToolMsg = function(msg, includeParent) {
			if (includeParent && parentTool) {
				console.error(currentTool + "[" + toolUuid + "]&&parent=" + parentTool.getCurrentTool() + "[" + parentTool.getToolUuid() + "]: " + msg);
			}
			else {
				console.error(currentTool + "[" + toolUuid + "]: " + msg);
			}
		};
		this.whichPageIsToolOn = function (){
			var node = this.getCurrentHtmlTree();
			
			while (true){
				var parent = node.parentNode;
				if(parent === null){
					return null;
				}

				if(this.hasClass(null, "contentMain", parent)){
					return null;
				}

				if(parent.hasAttribute("data-pageid")){
					return parent.getAttribute("data-pageid");
				}

				node = parent;
			}
		}

		this.isToolOnCurrentLMSPage = function (currentPageId){
			if(currentPageId === null || currentPageId === undefined){
				throw new Error("current page id must be defined.");
			}

			var pageId = this.whichPageIsToolOn(currentPageId);
			if(pageId === currentPageId){
				return true;
			}else{
				return false;
			}
		}
		/**
		 * Returns true if the tool is visible (IE not styled to be hidden) in 
		 * the current document.  This works across all modern browsers. Does not work in IE<9
		 */
		this.isToolVisibleInDocument = function() {
			var rootNode = this.getCurrentHtmlTree();
			if (rootNode) {
				return gaerdvark.utils.isElementVisibleInDocument(rootNode);
			}
			else {
				return false;
			}
		};
		
		/**
		 * Checks if the tool is currently displayed in the viewport.
		 * @return {boolean} true if the tool is displayed in the viewport.
		 */
		this.isToolInViewport = function() {
			var rootNode = this.getCurrentHtmlTree();
			if (rootNode) {
				return gaerdvark.utils.isElementInViewport(rootNode);
			}
			else {
				return false;
			}
		};
		
		/**
		 * Fires an event to listeners on the current tool and all sub tools that
		 * have been loaded by this tool, or by one of it's sub tools.  This allows
		 * an event to essentially be fired down the tool heirarchy chain so that 
		 * a descendant further down the chain can handle an action that occurred.
		 */
		this.fireSubToolEvent = function(data, eventName) {
			var eventData = {
				"toolUuid": toolUuid
				,"data": data
			};
			if (toolEventListeners.hasOwnProperty(eventName)) {
				toolEventListeners[eventName].forEach(function(callback) {
					try {
						callback(eventData, eventName);
					}
					catch (error) {
						// if an error occurs on the callback, keep going
						throwError(error);
					}
				});
			}
			// now for our loaded tools, let's fire the event down as well.
			for (var index in loadedTools) {
				if (loadedTools.hasOwnProperty(index)) {
					try {
						loadedTools[index].fireSubToolEvent(data, eventName);
					}
					catch (error) {
						throwError(error);
					}
				}
			}
		};
		
		
		// TODO: stephen there is some redundant code here with what is in group
		// not sure how we want to rectify that...
		// that we can probably remove...
		this.addSubToolListener = function(callback, eventName) {
			if (!toolEventListeners.hasOwnProperty(eventName)) {
				toolEventListeners[eventName] = [];
			}
			toolEventListeners[eventName].push(callback);
		};
		
		this.removeSubToolListener = function(callback, eventName) {
			if (!toolEventListeners.hasOwnProperty(eventName)) {
				throwError("Cannot remove listener " + currentTool + "[" + toolUuid + "] is not listening to " + eventName);
				return;
			}
			var indexOfCallback = toolEventListeners[eventName].indexOf(callback);
			if (indexOfCallback === -1) {
				throwError("Cannot remove listener " + currentTool + "[" + toolUuid + "] is not listening to the function you attempted to remove for event " + eventName);
				return;
			}
			
			toolEventListeners[eventName].splice(indexOfCallback, 1);
		};
		
		 /**
		  * Attempts to print the root html tree node of this tool.
		  * If for whatever reason it doesn't have the root node it will try to get the tool's
		  * current HTML tree to print. The styles of the tools will be added to the option's styles
		  * property and injected into the print window.
		  * @param tool the tool object to print
		  * @param options @see initOptions
		*/
		this.print = function(options) {
			var rootNode = this.getRootNode();
			if (rootNode && rootNode.nodeType !== 1) { // make sure it's a DOM Node
				throw new Error("Printing requires a node from the tool to print. Node from tool was" + node);
				return;
			}
			
			options = options || {};
		
			options.styles = options.styles || "";
			
			var toolName = getToolNameFromTool(me.getCurrentTool());
			if (gaerdvark.toolData.hasOwnProperty(toolName)
				&& gaerdvark.toolData[toolName].style) {
				options.styles += gaerdvark.toolData[toolName].style;
			}
			return gaerdvark.utils.Print.printNode(rootNode, options);
		};
	};

	/**
	 * Deletes the given tool reference, should pass the same thing that
	 * createTool returned
	 *
	 * @param id string
	 */
	function deleteTool (id) {
		var deleted = true;
		if(loadedTools[id].toolInstantiated) {
			return false;
		}
		if(loadedTools[id]) {
			try{
				loadedTools[id].destruct();
			} catch (error) {
				deleted = false;
				throwError('tool\'s destruct errored ' + id, error);
			}
			if(!(delete loadedTools[id])) {
				deleted = false;
				throwError('tool cannot be deleted ' + id, error);
			}
		}
		return deleted;
	}

	function setMissedCall(func, args, keepList) {
		if(keepList) {
			if(!subToolMissedCalls[func]) {
				subToolMissedCalls[func] = new Array();
			}
			subToolMissedCalls[func].push(args);
			return;
		}
		subToolMissedCalls[func] = args;
	}

	/**
	 * Used to safely call a subTool method and if the subTool does not exist then it is saved for
	 * later use
	 *
	 * @param string name of the function
	 * @param array args the array of args
	 * @param boolean keepList true to keep the args, false to drop them
	 *
	 * @returns mixed the value that the child method returned
	 */
	function callChildMethod(name, args, keepList) {
		if(!subTool) {
			setMissedCall(name, args, keepList);
			return undefined;
		}
		if(!subTool[name]) {
			setMissedCall(name, args, keepList);
			return undefined;
		}
		try {
			return subTool[name].apply(me, args);
		} catch(e) {
			throwError('subTool ' + name, e);
		}
		return undefined;
	}

	/**
	 *
	 */
	this.processHtmlAndReturnTree = function (innerHTMLstr, dataObj, actionObject, permissionObject, postProcess, noDynamicOn, noDataToolLoad) {
		group.processHtml(innerHTMLstr, dataObj, actionObject, permissionObject, postProcess, noDynamicOn, noDataToolLoad);
		return group.getCurrentHtmlTree();
	}

	/**
	 * A final activate method to ensure that only a parent can set acitvated to
	 * true
	 */
	this.activate = function() {
		activated = true;
		callChildMethod('activate', arguments);
	};

	/**
	 * Deletes all HTML references that the tool placed while it existed as well
	 * as all the classes it initilized.
	 */
	this.destruct = function() {
		callChildMethod('destruct', []);

		//clean up superclasses
		if(subTool && subTool.superClass) {
			for(var i in subTool.superClass) {
				subTool.superClass[i].destruct();
			}
		}

		//Get rid of the super publics.
		if(subTool && subTool.superPublics){
			if(!gaerdvark.superPublics){
				throwError("Super Publics got delete out there somewhere.");
				return;
			}

			for(var pub in subTool.superPublics){
				delete gaerdvark.superPublics[pub];
			}
		}

		//clean up tools, should happen before cleaning up html
		for (var id in loadedTools) {
			deleteTool(id);
		}

		//clean up html
		me.removeNode();

		// remove all listeners that may have registered for this instance of this tool
		group.unloadHtmlLoadedTools();
		removeToolListener(null, uniqueEventName);
		removeToolListener(checkToolPermissions, 'JSEnviornmentUpdated');
		
		group.unloadToolListeners();
	};

	/**
	 * used to appened an html string or a node (newRootNode) to the document tree
	 * and add that node to this tool's rootNode
	 * if sublingNode is specified then new node will be added just before or after the sibling
	 *
	 * @param parentNode node
	 * @param newRootNode node (optional, default comment node)
	 * @param siblingNode node (optional, default append to end of parentNode)
	 * @param afterSibling boolean (optional, default before siblingNode if specified)
	 *
	 * @return node new root node
	 */
	this.appendToNode = function(parentNode, newRootNode, siblingNode, afterSibling) {
		me.removeNode();
		callChildMethod('appendToNode', [parentNode, newRootNode, siblingNode, afterSibling], true);
		if (!afterSibling) afterSibling = false;
		if (!siblingNode) {
			siblingNode = null;
		} else {
			if (afterSibling) siblingNode = siblingNode.nextSibling;
		}
		if (!newRootNode) newRootNode = document.createComment('Root Node place holder');
		if (typeof (newRootNode) == 'string') {
			var div = document.createElement('div');
			div.innerHTML = newRootNode.trim();
			insertChildIntoParentBefore(parentNode, div.firstChild, siblingNode);
			if (div.childNodes.length) {
				throwError('tool ' + currentTool + ' has no root node');
			}
		} else {
			insertChildIntoParentBefore(parentNode, newRootNode, siblingNode);
		}
		if(activated) {
			me.replaceNode();
		}
		return (newRootNode);
	};

	/**
	 * Removes the node from the dom so tool is not displayed
	 */
	this.removeNode = function() {
		if (rootNode && rootNode.parentNode) {
			callChildMethod('removeNode', []);
			rootNode.parentNode.removeChild(rootNode);
		}
		rootNode = null;
	};

	this.replaceNode = function() {
		activated = true;
		callChildMethod('replaceNode', []);
		group.replaceRootNode();
	};

	/**
	 * public method to find out if tool has been activated
	 *
	 * @return boolean
	 */
	this.isActivated = function() {
		return activated;
	};

	this.getRootNode = function() {
		return rootNode;
	};

	/**
	 * Public method that will hide the root node of the tool if it has been activated
	 * and displayed on the screen
	 */
	this.hideTool = function() {
		if (this.isActivated()) {
			var rootNode = this.getRootNode();
			if (rootNode && rootNode.nodeType === 1) // make sure it's a DOM Node
			{
				group.addClassToNode(null, 'noDisplay', rootNode);
			}
		}
	};

	/**
	 * Public method that will show the root node of the tool if it has been activated
	 * and displayed on the screen and it is hidden
	 */
	this.showTool = function() {
		if ( this.isActivated() ) {
			var rootNode = this.getRootNode();
			if (rootNode && rootNode.nodeType === 1) // make sure it's a DOM Node
			{
				group.removeClassFromNode(null, 'noDisplay', rootNode);
			}
		}
	};
	
	this.observe = function(callbackFunc) {
		addToolListener(callbackFunc, uniqueEventName);
	};

	this.unObserve = function(callbackFunc) {
		removeToolListener(callbackFunc, uniqueEventName);
	};
	//alias of unObserve
	this.unobserve = function (callbackFunc){
		me.unObserve(callbackFunc);
	};

	this.setParentTool = function(tool) {
		parentTool = tool;
	};

	//set the tools public interface
	for(var i in world) {
		if(!me[i]) {
			me[i] = world[i];
		}
	}

	addToolListener(checkToolPermissions, 'JSEnviornmentUpdated');
	checkToolPermissions();
};