/**
 * @copyright Dynactive Design LLC
 * describe the sendObj class used by js
 */
//TODO check action permission
function SendObject() {
	// the following properties will be sent to the server
	this.action = null; // required value
	this.data = {}; // to stop clientRequest from throwing error
	// default function is run
	this.callback = null; // function
	//		string if callback sent from server but it must be a global function
	this.timeout = 60000; // set timeout, default 30 seconds
	//		if timeout occurs execute callback with timeout set to true
	//		otherwise always set timeout to false
	this.ajax = false; // set to true to force use of ajax

	// set to true to force the request to hit the server and come back before giving control back to javascript
	this.synchronous = false;
	//
	//	parameters returned by call to send
	//	this.sendId = null; // int
	//	this.response = null; // object (data) returned by the server
	// this.ajaxReq = null; // XMLHttpRequest object if ajax was used
}

/**
 * helper function to create a SendObject
 * @param action string
 * @param data object (optional)
 * @param callback function (optional)
 * @param func string
 *
 * @return object SendObject
 */
function createSendObject(action, data, callback) {
	var temp = new SendObject();
	temp.action = action;
	if (data != undefined) temp.data = data;
	if (callback != undefined) temp.callback = callback;
	return temp;
}

/**
 * Factory object used to create XMLHTTPRequest objects.
 */
function XMLHTTPRequestFactory() {

	var REQUEST_FINISHED = 4;

	/**
	 * Go through the various microsoft objects for older browsers and attempt to instantiate
	 * their XMLHTTPRequest object.  Return it if we can, otherwise return null.
	 */
	function createMicrosoftXMLHTTPRequest()
	{
		var requester = null;

		if (window.ActiveXObject) { //Test for support for ActiveXObject in IE first (as XMLHttpRequest in IE7 is broken)
			// stephen I believe it is IE < 7 that has XMLHTTPRequest broken.
			var aVersions = [ "MSXML2.XMLHttp.5.0", "MSXML2.XMLHttp.4.0", "MSXML2.XMLHttp.3.0", "MSXML2.XMLHttp", "Microsoft.XMLHTTP"];
			for(var i = 0; i< aVersions.length; i++) {
				try {
					requester = new ActiveXObject(aVersions[i]);
					break;
				}
				catch (e) {
					requester = null;
				}
			}
		}

		return requester;
	}
	/**
	 * produce and return a XMLHttpRequest object.  It will call the loadedCallback
	 * provided when the request has finished loading ie got some kind of response from the server.
	 *
	 * @return XMLHttpRequest
	 */
	function createXMLHTTPRequest(loadedCallback) {
		var requester = null;
		var error = '';
		try {
			requester = new XMLHttpRequest();
		} catch (e) {
			// this line should only be executed on IE < 7.1
			requester = createMicrosoftXMLHTTPRequest();
		}

		if (requester === null) {
			throw new Error("Unable to create ajax request object");
		}

		requester.onreadystatechange = function () {
			if (requester.readyState != REQUEST_FINISHED) return;
			loadedCallback(requester);
		};
		return requester;
	}

	this.createXMLHTTPRequest = createXMLHTTPRequest;
}

/**
 * class that provides communication between client and server
 *
 * @param maxRequests int (optional, default 10)
 *   the maximum concurrent requests that will be handeled,
 *   additional requests will be placed in a holding queue
 */
function CommManager() {
	var availableRequests = 10;
	var socket = false;
	var ajaxPollTimeout = null;
	var ajaxPollMinDelay = 10000;
	var ajaxPollMaxDelay = 10001;
	var ajaxPollCurrentDelay = ajaxPollMinDelay;
	var shortPollDelayRequests = 0;
	var waitingSend = new Array();
	var activeSend = {};
	var lastSendId = 0;
	var userActive = false;
	var socketHeartbeat;
	var me = this;

	var AJAX_POLL_DELAY_INCREMENT = 250;

	/**
	 * Pointer object to the tool event handler that we need to listen to and fire events.
	 * {ToolEventHandler}
	 */
	var toolEventHandler = null;

	/**
	 * Pointer object to the user Permission Map used to determine if actions user requested
	 * should be allowed to be called.
	 * {UserPermissionMap}
	 */
	var userPermissionMap = null;

	/**
	 * Flag that determines whether CommManager will poll or not.
	 */
	var pollingEnabled = true;

	/**
	 * Flag that tracks when we have no network communication with the server.
	 */
	var networkCommunicationEnabled = true;

	/**
	 * XMLHTTPRequest generator for the factory.  Allows stubs or other methods of handling
	 * the XMLHTTPRequest to be generated.
	 * {XMLHTTPRequestFactory}
	 */
	var xmlHTTPRequestFactory = null;

	/**
	 * Pointer to our handler that takes care of any error conditions such as logging etc we are dealing with.
	 * {GlobalErrorHandler}
	 */
	var errorHandler = null;

	/**
	 * Status of a send request when the user either does not have permission to an action
	 * or the action does not exist in the system.
	 */
	this.STATUS_INVALID_ACTION = 401;

	/**
	 * Status when the send request times out.
	 */
	this.STATUS_TIMEOUT = 408;

	/**
	 * Server responded but gave back no data in it's responseText.  Likely a programming error on the server.
	 */
	this.STATUS_SERVER_RESPONSE_INVALID = 550;

	/**
	 * Server sent a response back to the client, but for the specific request item inside the server request the server did not send a result back.
	 */
	this.STATUS_REQUEST_RESULT_MISSING = 560;

	/**
	 * Some kind of networking error occurred in attempting to communicate with the server.
	 * No communication was established.
	 */
	this.STATUS_COMMUNICATION_ERROR = 570;


//	// attempt to connect using a web socket
//	var host = 'ws://' + location.hostname + ':815';
//
//	try{
//		socket = new WebSocket(host);
////	socket.onopen = function(msg){log("Welcome - status "+this.readyState);};
//		socket.onmessage = socketMessage;
//		socket.onclose = closeSocket;
//		//sockets need a heartbeat, because they are cheap set them at min time.
//		socketHeartbeat = setInterval(pollForEvents, ajaxPollCurrentDelay);
//	}
//	catch(ex) {
//		// unable to create the socket, have to use ajax
//		socket = false;
//	}

// private functions
	/**
	 *	We know that session will be defined when the api loaded event is called
	 */
	function apiLoaded() {
		ajaxPollMinDelay = milliSecondsFromTime(_SESSION['settings']['MinEventPollTime']);
		ajaxPollMaxDelay = milliSecondsFromTime(_SESSION['settings']['MaxEventPollTime']);

		ajaxPollCurrentDelay = ajaxPollMinDelay;
	}

	/**
	 *	Gives the number of milliseconds from the sql time given
	 */
	function milliSecondsFromTime(time) {
		time = time.split(":");
		var totalTime = parseInt(time[0]) * 3600000; //hours
		totalTime += parseInt(time[1]) * 60000; //minutes
		totalTime += parseInt(time[2]) * 1000; //seconds
		return totalTime;
	}
//	/**
//	 * method that will be called whenever a message is received from the
//	 * server via the open socket
//	 *
//	 * @param msg socket message object
//	 */
//	function socketMessage(msg) {
//		var msgObj = getJSONObj(msg.data);
//		commonCallback(msgObj);
//	}

	/**
	 * called by the ajax handler when the response is received
	 * As long as the client is receiving events.  IE the client is making requests from the server and getting toolEvents back
	 * the polling delay remains constant.  If we are just polling but getting nothing back from the server we add a polling delay
	 * that increases up to our max ajaxPollMaxDelay value so that we are not using up bandwidth unnecessarily.
	 * @param string responseText the text from the XMLHTTPRequest or an error json string if there were none.
	 * @param sendId int
	 *
	 * @return mixed, returned by sendObj.callback or false if no sendObj found
	 */
	function updatePollingTimesFromAjaxResponseObject(msgObj) {
		if (ajaxPollTimeout) clearTimeout(ajaxPollTimeout);

		if ((msgObj)
				&& (msgObj.hasOwnProperty('toolEvents'))
				&& (msgObj.toolEvents.length > 0)) {
			ajaxPollCurrentDelay = ajaxPollMinDelay;
		} else {
			ajaxPollCurrentDelay += AJAX_POLL_DELAY_INCREMENT; // add a quarter of a second
			if (ajaxPollCurrentDelay > ajaxPollMaxDelay) ajaxPollCurrentDelay = ajaxPollMaxDelay;
		}

		ajaxPollTimeout = setTimeout(pollForEvents, ajaxPollCurrentDelay);
	}

	/**
	 * common code for callback from server
	 * Any response from the server can contain a toolEvents object
	 * Each event will be handeled here
	 *
	 * @param {Object} msgObj data object from server
	 * @param {array[number]} sendIds  These sendIds are the sendIds that have requested a callback operation.  
	 * 				If any action was sent to the server that the client didn't care to be notified, it will not be in this list. 
	 */
	function commonCallback(msgObj, sendIds) {
		// check for redirect to another url
		if ((msgObj) && (msgObj.hasOwnProperty('httpRedirect'))) {
			sessionStorage.clear(); // clear the session storage when we leave
			var httpRedirect = msgObj.httpRedirect.trim();
			// if the redirect is to inactive and the session has a logout url location use it instead
			if ((msgObj.redirectToDsInactivePage) && (_SESSION) && (_SESSION.logoutRedirectUrl)) {
				var logoutRedirectUrl = _SESSION.logoutRedirectUrl.trim();
				if (logoutRedirectUrl) {
					httpRedirect = '/inactive?logoutRedirectUrl=' + encodeURIComponent(logoutRedirectUrl);
				}
			}
			window.location = httpRedirect;
			return;
		}

		if (msgObj) {
			if (msgObj.hasOwnProperty('toolEvents')) {
				var toolEvents = msgObj.toolEvents;
				var toolEventsLength = toolEvents.length;
				for (var x = 0; x < toolEventsLength; x++) {
					fireToolEvent(toolEvents[x].data, toolEvents[x].event);
				}
				delete msgObj.toolEvents;
			}
		}

		// notify the user of the error
		if (msgObj.hasOwnProperty("error"))
		{
			errorHandler.throwError(msgObj.error);
		}

		// it's easier to work with the sendIds then the indexes... not sure why we don't store the information based on sendId
		var resultSendIdsMap = {};
		for (var i = 1; Object.prototype.hasOwnProperty.call(msgObj, i); i++)
		{
			resultSendIdsMap[msgObj[i].sendId] = i;
		}
		
		// these two variables are used for debugging.
		var sendIdsSentString = sendIds.join(",");
		var sendIdsReceivedString = Object.keys(resultSendIdsMap).join(",");

		// go through the requests and do the callbacks
		// if the server sends us back something with a send id that we did not originally request... we may have some problems here...
		for (var i in sendIds)
		{
			// the error conditions we can have are
			// 1) For some reason the request was popped from our active send before we actually handled it
			// 2) The server response did not contain a response to our send request
			// 3) The sendObj was in the active list when in reality it had no callback assigned to it.
			if (sendIds.hasOwnProperty(i))
			{
				var sendObj = popActiveSend(sendIds[i]);
				if (!sendObj)
				{
					var errorMessage = "Send object could not be found in active sends for sendId of " + sendIds[i]
					+ ".  The request could have timed out or was canceled.  Original send Ids sent: " + sendIdsSentString
					+ ". Send ids received: " + sendIdsReceivedString;
					errorHandler.throwError(errorMessage);
					continue;
				}

				if (!sendObj.callback) // this condition should never be reached..
				{
					errorHandler.throwError("Send Object of " + sendIds[i] + " was in active send list but had no callback when it should have had it.");
					continue;
				}

				if (!resultSendIdsMap.hasOwnProperty(sendIds[i]))
				{
					var errorMessage = "Send request with id " + sendIds[i] + " was not found in request response.";
					errorMessage += "Send ids that were sent: " + sendIdsSentString + ". SendIds that were received " + sendIdsReceivedString + ".";
					errorHandler.throwError(errorMessage);

					// update our error status
					sendObj.status = me.STATUS_REQUEST_RESULT_MISSING;
				}
				else
				{
					var msgIndex = resultSendIdsMap[sendIds[i]];

					updateSendObj(sendObj, msgObj[msgIndex]);
				}

				try
				{
					sendObj.OK = (sendObj.status == 200);
					sendObj.callback(sendObj);
				}
				catch(e)
				{
					errorHandler.throwError('callback for send request ' + sendIds[i] + ' call failed.', e);
				}
			}
		}
	}

	/**
	 * strip any extra characters from the beginning or end of the string
	 * then parse the json string
	 * @param string (json encoded data)
	 * @return object
	 */
	function getJSONObj(msgData) {
		var startOfJSON = msgData.indexOf('{');
		var endOfJSON = msgData.lastIndexOf('}');
		if ((startOfJSON > -1) && (endOfJSON > 0)) {
			try {
				var response = msgData.substring(startOfJSON, endOfJSON + 1);
				return JSON.parse(response);
			} catch (e) {
				errorHandler.throwError("Unable to Parse JSON: |" + msgData + "| ", e);
			}
		} else {
			errorHandler.throwError("Unable to Parse JSON: |" + msgData + "|");
		}
		// error, return empty object
		return {};
	}

	/**
	 * delete a send object from the activeSend object
	 * clear the timeout set for the send request
	 * 	 *
	 * @param sendId int
	 *
	 * @return mixed, sendObj or false if the sendObj did not exist
	 */
	function popActiveSend(sendId) {
		if (activeSend[sendId]) {
			var sendObj = activeSend[sendId];
			clearTimeout(sendObj.timeoutId);

			// TODO: Bug #786 we may want to look at fixing this as if we force the request to have timed out
			// we need to abort the ajax request.
			// there seems to be a conflating of meaning here though... we only want this to happen in cancelRequest and timeoutSend
			if ((sendObj.hasOwnProperty('ajaxReq'))
					&& (sendObj.ajaxReq.readyState != 4)) {
				sendObj.ajaxReq.abort();
			}
			delete activeSend[sendId];
			availableRequests++; // cleared up a request
			checkWaiting(); // start the next request if any
			return sendObj;
		}
		return false;
	}

	/**
	 * Returns a unique identifier for the send id.
	 * @returns {Number}
	 */
	function generateSendId()
	{
		return ++lastSendId;
	}

	/**
	 * poll the server for events that are waiting to be delivered to the client
	 */
	function pollForEvents() {
		// don't poll if someone has told us not to.
		if (!pollingEnabled)
		{
			return;
		}

		ajaxPollTimeout = setTimeout(pollForEvents, ajaxPollCurrentDelay);

		// we add the request directly onto the queue and start the request if we can.
		// this bypasses any action checks or network connectivity checks as we want this
		// function to assist us in determining if we have network connectivity.
		var sendObj = createSendObject(null);

		// we need a unique identifier for the send object to be part of the send request
		// we add it here.
		sendObj.sendId = generateSendId();

		waitingSend.push(sendObj);
		checkWaiting(); // start the next request if any
	}

	/**
	 * closes the open socket connection to the server
	 */
	function closeSocket() {
//		if (socket) {
//			socket.close();
//			socket = false;
//		}
		for (var sendId in activeSend) {
			if (!activeSend[sendId].hasOwnProperty('ajaxReq')) popActiveSend(sendId);
		}
//		clearInterval(socketHeartbeat);
	}

	/**
	 * copies the public parameters from the msgObj, returned via an ajax call
	 * or socket request, to the sendObj
	 *
	 * @param sendObj object
	 * @param msgObj object
	 */
	function updateSendObj(sendObj, msgObj) {
		for (var prop in msgObj) {
			sendObj[prop] = msgObj[prop];
		}
	}

	/**
	 * send an ajax request to the server
	 * @param sendObj object
	 * @param jsonString string
	 *
	 * @return sendId int or false
	 */
	function sendAjax(sendIdsInRequest, jsonString, asynchronous) {
		try {
			var ajaxReq = createXMLHTTPRequest(sendIdsInRequest);
		} catch (error) {
			errorHandler.throwError("Failed to create xml http request", error);
			return false;
		}
		//var targetURL = location.protocol + '//' +	location.hostname + '/requestManager';
		var targetURL = '/requestManager';
		// FNC force no cache, (required to fix ie7+ problem) & just a good idea
		//  http://www.php-web-host.com/blog/tag/ie7-ajax-broken/
//		var currentDate = new Date();
//		targetURL += (targetURL.lastIndexOf('?') == -1 ? '?' : '&') + 'FNCms'
//			+ currentDate.getTime();

		//open the requester, true for asyncronous
		ajaxReq.open('POST', targetURL, asynchronous);
		//set the requester header.
		ajaxReq.setRequestHeader('Content-Type','application/x-www-form-urlencoded');
		//send the requester.
		ajaxReq.send('ps=' + encodeURIComponent(jsonString));
		return true;
	}

	/**
	 * produce and return a XMLHttpRequest object
	 *
	 * @return XMLHttpRequest
	 */
	function createXMLHTTPRequest(sendIdsInRequest) {

		var requestLoadedCallback = createAJAXRequestLoadedCallback(sendIdsInRequest);

		return xmlHTTPRequestFactory.createXMLHTTPRequest(requestLoadedCallback);
	}

	/**
	 * Creates a function closure that handles the AJAX request when it finishes loading.
	 * It closes over the sendIds for the individual request items that were bundled together to be sent in
	 * a single AJAX request.  This makes it possible to fire the callbacks on those requests with error statii
	 * even if the server did not tell us what requests it attempted to handle.
	 * @param sendIds array of strings representing the unique send ids for each send request.
	 * @returns {Function}
	 */
	function createAJAXRequestLoadedCallback(sendIds)
	{
		return function(xmlHTTPRequest) {
			var msgObj = null;
			// keep ourselves safe in case any modifications of the sendIds occur
			var requestSendIds = sendIds;
			var responseText = xmlHTTPRequest.responseText;

			var logError = true;
			var hasNetworkCommunication = true;

			// there was a communication error with the server.
			// after more investigation a status code of 0 when we have reached this code
			// is some kind of error happened in the xmlhttprequest
			// see http://www.w3.org/TR/XMLHttpRequest/#the-status-attribute
			if (xmlHTTPRequest.status === 0)
			{
				hasNetworkCommunication = false;

				msgObj = {
					error: me.STATUS_COMMUNICATION_ERROR + " Failed to communicate with server"
				};
			}
			else if (xmlHTTPRequest.status > 399)
			{
				msgObj = {
						error: this.status + ' ' + this.statusText
				};
			}
			// server for some reason failed to give us a response text.
			else if (gaerdvark.utils.isEmpty(responseText))
			{
				msgObj = {
						error: me.STATUS_SERVER_RESPONSE_INVALID + ' '
							+ "Server came back with empty response"
				};
			}
			else
			{
				// this is a valid request now.
				logError = false
				msgObj = getJSONObj(responseText);
			}

			// if (logError)
			// {
			// 	BugReportManager.addLogEntry("serverError", {"error":  msgObj.error});
			// }

			// make it so that other requests can go forward.
			networkCommunicationEnabled = hasNetworkCommunication;

			updatePollingTimesFromAjaxResponseObject(msgObj);

			commonCallback(msgObj, requestSendIds);
		};
	}

	/**
	 * check to see if any send requests are queued
	 * if so process them first in first out
	 *
	 * @return mixed, sendId or false on error
	 */
	function checkWaiting() {
		if ((waitingSend.length == 0) || (availableRequests == 0)) {
			// noting waiting or already maxed out the requests
			return true;
		}

		var jsonObj = new Object();
		//This try catch exists for the unit tests
		try {
			jsonObj.jsInstanceID = _SESSION['jsInstanceID'];
		} catch (e) {
			delete jsonObj.jsInstanceID;
		}

		var location = window.location.protocol + '//' + window.location.host + window.location.pathname;
		if ((window.location.pathname.length > 1) && (location.slice(-1) !== '/')) location += '/';
		jsonObj.location = location;


		var i = 0;
		var callbackRequested = false;
		var asynchronous = true;

		var sendIdsInRequest = [];

		while (waitingSend.length > 0) {
			i++;
			var sendObj = waitingSend.shift();
			var sendId = sendObj.sendId;

			if(sendObj.synchronous) {
				asynchronous = false;
			}
			jsonObj[i] = new Object();
			jsonObj[i].action = sendObj.action;
			jsonObj[i].sendId = sendId;
			jsonObj[i].data = (sendObj.data) ? sendObj.data : '';
			jsonObj[i].func = sendObj.func;
			if (sendObj.callback) {
				callbackRequested = true;
				activeSend[sendId] = sendObj;

				sendIdsInRequest.push(sendId);

				// we need the timeoutSend to pass along the sendId.  Originally this method passed in a third argument array that is not
				// supported in IE.
				sendObj.timeoutId = setTimeout(function() { timeoutSend(sendId); }, sendObj.timeout);
			}
		}
		if (callbackRequested) {
			availableRequests--;
		}

		// notify server if user is active or not on this jsInstance
		// any key press or mouse click in this browser instance sets userActive flag to true
		jsonObj.userActive = userActive;
		userActive = false; // reset userActive flag

		var jsonString = JSON.stringify(jsonObj);

//		if ((socket)
//				&& (socket.readyState === 1)
//				&& (!sendObj.ajax)) {
//			try {
//				setTimeout(function(){socket.send(jsonString);}, 1);
//			} catch(err) {
//				errorHandler.throwError('socket failed: ', err);
//				closeSocket();
//				return sendAjax(jsonObj, jsonString);
//			}
//			return sendId;
//		}
		return sendAjax(sendIdsInRequest, jsonString, asynchronous);
	}

	/**
	 * callback method when time expires after a request is sent to the server
	 * pop the sendObj from the activeSend list. This cancels the request and will notify
	 * the sendObj.callback of the error if a callback has been provided.
	 *
	 * @param sendId int
	 */
	function timeoutSend(sendId) {
		if (sendId === undefined || sendId === null)
		{
			errorHandler.throwError('some request timed out but no sendId was passed as part of the request');
		}

		var sendObj = popActiveSend(sendId);

		if (sendObj) {
			// errorId may or may not be populated...
			errorHandler.throwError('communication with server timed out', null, sendObj.errorId);
			sendObj.status = me.STATUS_TIMEOUT;
			sendObj.callback(sendObj);
		}
	}


// public functions

	/**
	 * Makes it possible to create a stub for the createSendObject used throughout the
	 * system.  We leave createSendObject outside of this container function because
	 * the global is still used in a lot of different places.
	 */
	this.createSendObject = createSendObject;

	/**
	 * CommManager depends on UserPermissionMap to validate action requests and this
	 * allows callers to provide a custom UserPermissionMap
	 * {UserPermissionMap}
	 */
	this.setUserPermissionMap = function(map) {
		userPermissionMap = map;
	}

	/**
	 * There are certain actions we want handled when the api of the system has fully
	 * loaded.  Because of that we depend on toolEventHandler.  This function
	 * allows you to set a Custom ToolEventHandler
	 * {ToolEventHandler}
	 */
	this.setToolEventHandler = function(handler) {
		toolEventHandler = handler;
	}

	/**
	 * We have to track our errors.  This function allows us to override the error class
	 */
	this.setErrorHandler = function(handler) {
		errorHandler = handler;
	};

	/**
	 * Set the factory object that will generate XMLHTTPRequest objects.
	 */
	this.setXMLHTTPRequestFactory = function(factory) {
		xmlHTTPRequestFactory = factory;
	};

	/**
	 * Initialize the comm manager.
	 */
	this.init = function() {
		toolEventHandler.addToolListener(apiLoaded, 'APILoaded');
	};

	/**
	 * This function attached to events to watch for mouseup
	 *  and keydown to know if user is active
	 * mouseup used because they are rarely used elsewhere
	 * keyup was not working
	 */
	this.userActivity = function () {
		userActive = true;
	};
	/**
	 * turned off logging
	 * @param {} sendObj 
	 */
	function logSend(sendObj){
		
		// var data = {};
		// data.action = sendObj.action;
		// data.callback = sendObj.callback;

		// switch (data.action) {
		// 	case 'Save Page' :
		// 		data.data={"pageID":sendObj.data.pageID}; // only send the pageID
		// 		break;
		// 	case 'Set Server Event Listeners' :
		// 		break; // don't send the list of listeners
		// 	default :
		// 		data.data = sendObj.data; // send all the data for the others
		// 		break;
		// }

		// BugReportManager.addLogEntry("send", {"sendData":data});
	}

	 /**
	 * used to send a request/data to the server.  If we lose connectivity to the server
	 * subsequent calls will queue up the request until we have established communication with
	 * the server again.
	 * @param sendObj object
	 * @param delayed bool true means to send this on the next non-delayed request
	 * @return sendId int
	 */
	this.send = function (sendObj, delayed) {
		logSend(sendObj);
		if(delayed == null) {
			delayed = false;
		}

		// we need a unique identifier for the send object to be part of the send request
		// we add it here.
		var sendId = generateSendId();
		sendObj.sendId = sendId;

		// don't allow invalid actions
		if (!userPermissionMap.hasActionPermission(sendObj.action))
		{
			sendObj.status = me.STATUS_INVALID_ACTION;
			//BugReportManager.addLogEntry("sendStatus", {"action":sendObj.action, "status":sendObj.status});

			if(sendObj.callback) {
				setTimeout(function(){sendObj.callback(sendObj)}, 1);
			}
			return sendId;
		}

		// If we don't notify the send requests that we do not have network connection
		// the system can get into an infinite loop status where it attempts to send
		// additional send requests which will fail, which then starts the cycle again.
		// for now if we are not connected to the network we just tell the caller immediately
		// we don't have network connection

		// the polling will reset the networkCommunicationEnabled flag if it
		// can establish a connection with the server
		if (!networkCommunicationEnabled)
		{
			sendObj.status = me.STATUS_COMMUNICATION_ERROR;
			//BugReportManager.addLogEntry("sendStatus", {"action":sendObj.action, "status":sendObj.status});
			if (sendObj.callback)
			{
				setTimeout(function(){sendObj.callback(sendObj)}, 1);
			}
			return sendId;
		}

		// push our send objects onto our waiting list to be sent
		waitingSend.push(sendObj);

		if(!delayed) {
			setTimeout(checkWaiting, 1);
		}
		if(sendObj.synchronous) {
			checkWaiting();
		}

		return sendId;
	};

	/**
	 * class destruct method
	 * does cleanup
	 */
	this.destruct = closeSocket;

	/**
	 * will try to pop the send request from the activeSend list
	 * if the send request has not yet been processed
	 * will find it in the waiting queue and remove it
	 * if applicable
	 *
	 * @param sendId int
	 * @return boolean true
	 */
	this.cancelSend = function (sendId) {
		if (!popActiveSend(sendId)) {
			for (var i = 0; i < waitingSend.length; i++) {
				if (waitingSend[i].sendId == sendId) {
					waitingSend.splice(i, 1);
					break;
				}
			}
		}
		return true;
	};

	/**
	 * used to request short poll delay
	 * keeps track of the number of requests so it will not be lengthened
	 * until all requesters have canceled
	 * true is a request for short poll delay, false is cancel request
	 *
	 * @param onOff boolean
	 */
	this.shortPollDelay = function (onOff) {
		if (onOff) {
			shortPollDelayRequests++;
			ajaxPollMinDelay = milliSecondsFromTime(_SESSION['settings']['ChatMinEventPollTime']); // one second between poll
			ajaxPollMaxDelay = milliSecondsFromTime(_SESSION['settings']['ChatMaxEventPollTime']); // ten seconds between poll
			ajaxPollCurrentDelay = ajaxPollMinDelay;
			if (ajaxPollTimeout) clearTimeout(ajaxPollTimeout);
			ajaxPollTimeout = setTimeout(pollForEvents, ajaxPollCurrentDelay);
		} else if (shortPollDelayRequests) {
			shortPollDelayRequests--;
			if (!shortPollDelayRequests) {
				ajaxPollMinDelay = milliSecondsFromTime(_SESSION['settings']['MinEventPollTime']); // one second between poll
				ajaxPollMaxDelay = milliSecondsFromTime(_SESSION['settings']['MaxEventPollTime']); // ten seconds between poll
				ajaxPollCurrentDelay = ajaxPollMinDelay;
			}
		}
	};

	this.setMinPollTime = function (evt) {
		ajaxPollMinDelay = Math.max(1, evt.ourTarget.value) * 1000;
		evt.ourTarget.form.max.value = Math.max(evt.ourTarget.form.max.value, parseInt(evt.ourTarget.value) + 1);
		ajaxPollCurrentDelay = ajaxPollMinDelay;
		me.pollNow();
	};

	this.setMaxPollTime = function (evt) {
		ajaxPollMaxDelay = Math.max(2, evt.ourTarget.value) * 1000;
		evt.ourTarget.form.min.value = Math.min(evt.ourTarget.form.min.value, parseInt(evt.ourTarget.value) - 1);
		me.pollNow();
	};

	this.pollNow = function (evt) {
		if (ajaxPollTimeout) clearTimeout(ajaxPollTimeout);
		pollForEvents();
	};

	this.enablePolling = function (evt) {

		pollingEnabled = true;
		// issues a poll and starts the polling request if it's not started.
		pollForEvents();
	};

	this.disablePolling = function (evt) {
		if (ajaxPollTimeout) clearTimeout(ajaxPollTimeout);

		pollingEnabled = false;
	};
};

/**
 * Takes the json response from the generic file upload request, parses it and calls the callback
 * with the given JSON response.  It sends null to the callback if it could not parse the JSON
 * @param iframe the iframe element that contains the JSON response
 * @param callback the function to call with the parsed response
 * @return JSON or null if it could not parse the response
 */
function parseFileUploadJSON(iframe, dataSent, callback)
{
	var response = null;
	var jsonData = null;
	var error = null;
	if (iframe != null && iframe.contentDocument != null) {
		response = iframe.contentDocument.getElementById("response");
	}

	if (response != null) {
		//get the response data from the iframe. It is embedded in a div with the id of response and contains a jsonstring
		try {
			jsonData = JSON.parse(response.innerHTML)[1];
		}catch(e){
			error = e;
		}
	} else {
		error = { message: "Failed to receive file upload response from iframe"};
	}
	
	if (error && error.message) {
		// for when this runs stand alone in BrowseFiles
		if (!window.throwError) {
			alert(error.message);
		} else {
			throwError(error);
		}
	}
	return jsonData;
}

/**
 * Creates and sends a file upload submission for a given action with the specific data.
 * It must be given the iframe node that contains the file upload form.  It also takes a callback that will
 * be given the JSON response from the server once the file has uploaded.  Because we allow files of arbitrary length
 * we send a request for a URL endpoint that upload the file to.  Once we've received that endpoint the actual iframe
 * will be submitted to that endpoint.
 * @param iframe
 * @param action
 * @param data
 * @param callback
 */
function sendFileUploadRequest(iframe, action, data, callback)
{	
	var originalIframeSrc = iframe.src;
	var processUploadRequest = function() {
		var jsonData = parseFileUploadJSON(iframe);
		iframe.src = ""; // null it, reset it, and then do the callback
		iframe.src = originalIframeSrc;
		callback(jsonData, data);
		iframe.onload = null; // remove the call back.
	};

	// get the URL we need to upload to.  This enables us to upload files to appengine in excess
	// of 32MB (I belive up to 2GB files -- check the official documentation if this has changed).

	var uploadUrlCallback = function(sendObj) {
		if (sendObj.OK) {
			var uploadSendObj = createSendObject(action, data);
			var jsonObj = new Object();
			jsonObj.jsInstanceID = _SESSION['jsInstanceID'];
			jsonObj[1] = new Object();
			jsonObj[1].action = uploadSendObj.action;
			jsonObj[1].sendId = 1;
			jsonObj[1].data = (uploadSendObj.data) ? uploadSendObj.data : '';
			jsonObj.userActive = true;
			
			try {
				var iframeform = iframe.contentDocument.forms[0];
			}
			catch(e) {
				throwError(e.message);
			}

			// need to set the form action string here
			iframeform.action = sendObj.response.url;
			
			// normally we just embed the ps string into the URL, however, appengine has a limit to the
			// URL length that their redirect action will go to so by embedding it as a hidden field in the
			// form we are able to tell appengine to submit this as part of the POST request to our requestManager page
			var psHidden = document.createElement("input");
			psHidden.type = "hidden";
			psHidden.name = "ps";
			psHidden.value = JSON.stringify(jsonObj);
			iframeform.appendChild(psHidden);
			iframe.onload = processUploadRequest;
			iframeform.submit();
		}
		else {
			callback(sendObj);
		}
	};

	getBlobstoreUploadUrl(uploadUrlCallback);
}

function getBlobstoreUploadUrl(callback){
	send(createSendObject("Get Blobstore Upload URL", {}, callback));
}