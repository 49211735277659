gaerdvark.GoogleAnalyticsManager = function() {
	
	/**
	 * Event handler that allows for test mocks to verify this javascript function is working.
	 */
	var toolEventHandler = null;
	
	/**
	 * Hash manager tracks the location hash bar and allows us to get information about the current location from it.
	 * @var HashManager
	 */
	var hashManager = null;
	
	function trackPage() {
		if(!gaExists()){
			return;
		}
		
		var hash = hashManager.getHash();
		
		var url = "";
		
		if (window.location && window.location.pathname) {
			// prefix the url with the namespace name
			url += window.location.pathname;
		}
		else {
			url = "/";
		}
		
		if (hash.t) {
			url += hash.t;
			
			if (hash.t === "LMS Home" && hash.id) {
				url += "/" + hash.id;
				delete hash["id"];
			}
			
			delete hash["t"];
		}
		
		if (hash.c) {
			url += "/" + hash.c;
			delete hash["c"];
		}
		
		if (hash.tab) {
			url += "/" + hash.tab;
			delete hash["tab"];
		}
		
		var params = [];
		for (var index in hash) {
			if (hash.hasOwnProperty(index)) {
				params.push(index + "=" + hash[index]);
			}
		}
		if (params.length > 0) {
			url = url + "?" + params.join("&");
		}
		
		ga('send', 'pageview', url);
		ga('set', 'page', url);
	}
	
	function createTracker() {
		if(!gaExists()){
			return;
		}
		
		// start off the tracking code so we can start tracking the analytics.
		if (gaerdvark.gaSettings) {
			var domainPolicy = 'auto';
			if (gaerdvark.gaSettings.environment === 'localhost') {
				domainPolicy = 'none';
			}
		}
		ga('create', gaerdvark.gaSettings.gaCode, domainPolicy);
	}
	/**
	 * returns true if google analytics is loaded.
	 * @returns {unresolved}
	 */
	function gaExists(){
		return (window.hasOwnProperty("ga") && (window.ga !== null || window.ga !== undefined));
	}
	/**
	 * 
	 * @param {ToolEventHandler} handler
	 * @returns {undefined}
	 */
	this.setToolEventHandler = function(handler) {
		toolEventHandler = handler;
	};
	
	/**
	 * 
	 * @param {HashManager} manager
	 * @returns {undefined}
	 */
	this.setHashManager = function(manager) {
		hashManager = manager;
	};
	
	this.init = function() {
		toolEventHandler.addToolListener(createTracker, "APILoaded");
		toolEventHandler.addToolListener(trackPage, "HashChanged");
	};
};