// createModal
/**
 * Modal is intended as a replacement for the browsers alert or confirm.
 * Modal has been used in the past for things that should have been done in a wizard. This should not be continued.
 *
 *  pass an object containing the following parameters
 *   content mixed - if string it is the innerHTML of the modal
 *                   if node the node will be placed in the modal
 *   focusNode node - normally used if the content is a node, the focusNode is a node contained withing the content node.
 *   toolbarTitle mixed - false for no toolbar
 *     string for title of toolbar, can be empty
 *     default false
 *    toolbarExit boolean - if true and X will be added to toolbar
 *      default false
 *   bgExit boolean - true for exit on click of background
 *     default false
 *   exitFunction - function to call on exit (x in toolbar or background click)
 *     function must return true if modal is to be removed
 *     default just remove modal
 *   fgClassName will be used if supplied
 *     default modalFg
 *
 * @param {Object} parameterObj
 * @returns {Boolean}
 */
function createModal(parameterObj) {
	/**
	 * called by bg exit and toolbar X exit.
	 */
	function exitModal() {
		if ((!Object.prototype.hasOwnProperty.call(parameterObj, 'exitFunction'))
				|| parameterObj.exitFunction()) {
			removeModal();
		}
	}

	if (document.getElementById('modalBg')) return false;
	var modalBg = document.createElement('div');
// TODO set a variable to set modelBg.id and Fg so it can be changes one place
	modalBg.id = 'modalBg';  // TODO: document the modalBg is a reserved ID or other solution
	modalBg.className = 'modalBg';

	if (!parameterObj.bgExit) {
		modalBg.onclick = function() {
			var modalFg = document.getElementById('modalFg');
			if (modalFg) modalFg.focus();
		};
	} else {
		modalBg.onclick = exitModal;
	}
	var body = document.getElementsByTagName('body')[0];
	body.appendChild(modalBg);

	var modalFg = document.createElement('div');
	modalFg.id = 'modalFg';
	modalFg.className = parameterObj.fgClassName || 'standardModalFg';

	if ((parameterObj.toolbarExit) || (typeof parameterObj.toolbarTitle == 'string')) {
		var toolbar = document.createElement('div');
		toolbar.id = 'modalToolbar';
		toolbar.className = 'modalToolbar';

		toolbar.innerHTML = (parameterObj.toolbarTitle !== false) ? parameterObj.toolbarTitle : '';

		if (parameterObj.toolbarExit) {
			var toolbarControl = document.createElement('div');
			toolbarControl.className = 'modalToolbarControl';
			toolbarControl.onclick = exitModal;
			toolbarControl.innerHTML = 'x';
			toolbar.appendChild(toolbarControl);
		}
		modalFg.appendChild(toolbar);
	}

	var modalContent = document.createElement('div');
	modalContent.id = 'modalContent';
	modalContent.className = 'modalContent';
	if (typeof parameterObj.content == 'string') {
		modalContent.innerHTML = parameterObj.content;
	} else {
		modalContent.appendChild(parameterObj.content);
	}
	modalFg.appendChild(modalContent);
	modalFg.tabIndex = 1;
	body.appendChild(modalFg);
	document.getElementById('modalFg').focus();
	if (parameterObj.focusNode) parameterObj.focusNode.focus();
	return true;
}

function removeModal() {
	var node = document.getElementById('modalFg');
	if(node && node.parentNode) {
		node.parentNode.removeChild(node);
	}
	node = document.getElementById('modalBg');
	if(node && node.parentNode) {
		node.parentNode.removeChild(node);
	}

	//In utilities.js.
	//We force a redraw in safari because it will
	//leave some of the modal background in if they have scrolled.
	//See Bug #714
	gaerdvark.utils.forceRedraw();
}

/**
 * Checks to see if there is a modal currently on screen.  Returns true if there
 * is, false otherwise.
 * @returns {Boolean}
 */
function isModalOpen() {
	var node = document.getElementById('modalFg');
	return node !== null;
}

function CreateModalParam(content) {
	this.content = content ? content : '';
	this.toolbarTitle = false;
	this.toolbarExit = false;
	this.bgExit = false;
	this.exitFunction = function() {return true;};
	this.fgClassName = 'standardModalFg';
	this.focusNode = false;
}
