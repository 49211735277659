/**
 * Used to log things like events, errors, tool create and destroy, and hash changes
 */
var GlobalBugReportManager = function() {
	var me = this;
	/**
	 * During a cms usage we hit the 5 meg Firefox limit at about 350 entries.
	 * That means about 175 entries on Chrome with a 2.45 meg limit
	 * and about 330 entries on IE with a 4.75 meg limit.
	 * This all assumes we are not using the sessionStorage for anything else.
	 * @type Number
	 */
	var DEFAULTMAXLOGENTRIES = 300;
	var BUGREPORTPREFIX = 'bugreport';
	var maxLogEntries = DEFAULTMAXLOGENTRIES;
	var sessionStorage = window.sessionStorage;
	var oldestEntry = 1; // preset for first entry that will be made
	var newestEntry = 0; // add one for each entry made
	/**
	 * Strings returned in the error.message (in the order shown) for firefox 7.0.1, ie9, and safari5.1.1/chrome 15.0.874.
	 * @type Array
	 */
	var STORAGE_FULL_STRINGS = ["Persistent storage maximum size reached", "Out of memory", "QUOTA_EXCEEDED_ERR: DOM Exception"];

	/**
	 * do nothing method that replaces all the public methods if the browser doesn't support sessionStorage
	 */
	function unSupportedOperation() {
		return false;
	}

	function initializeCounters() {
		var oldestEntrySet = false;
		var sessionStorageLength = sessionStorage.length;
		var BUGREPORTPREFIXLENGTH = BUGREPORTPREFIX.length;
		for (var x = 0; x < sessionStorageLength; x++) {
			var entryName = sessionStorage.key(x);
			if (entryName.indexOf(BUGREPORTPREFIX) === 0) {
				var entryNumber = parseInt(entryName.substr(BUGREPORTPREFIXLENGTH));
				if (entryNumber > newestEntry) {
					newestEntry = entryNumber;
				}
				if ((!oldestEntrySet) || (entryNumber < oldestEntry)) {
					oldestEntry = entryNumber;
					oldestEntrySet = true;
				}
			}
		}
	}

	/**
	 * Removes excess entries and updates the counter pointers in case of a browser refresh on the tab
	 */
	function removeOldEntries() {
		while (newestEntry - oldestEntry >= maxLogEntries) {
			sessionStorage.removeItem(BUGREPORTPREFIX + oldestEntry); // delete the oldest entry
			oldestEntry++;
		}
	}

	var getStackTrace;
	this.setErrorHandler = function (globalErrorHandler){
		getStackTrace = globalErrorHandler.getStackTrace;
	}


	/**
	 * Add an object to the log.
	 * Stored log entry will be {"time":"jsontimestring", "type":"typestring", "stackTrace":"tracestring", "obj":obj}
	 * @param String type of log entry
	 * @param Object log entry
	 */

	function addLogEntry(type, obj) {
		var time = new Date();
		var stackTrace = getStackTrace().stringStack;
//		stackTrace.shift(); // remove this function from the stack trace
//		stackTrace.splice(7); // only keep the top 7 trace calls not including the one to this function
		//stackTrace = stackTrace.join('\n'); // turn it into a string

// This has been commented out for now. If page saves make the bug log to large we can uncomment the following
// and change obj inside the try to objectClone
//
//		var objectClone = gaerdvark.utils.cloneObject(obj, true, true); // do a deep safe clone
//		if ((type == "send") && (objectClone.sendObj.hasOwnProperty('action')) && (objectClone.sendObj.action == "Save Page")) {
//			delete (objectClone.sendObj.data.description);
//			delete (objectClone.sendObj.data.cmsText);
//			delete (objectClone.sendObj.data.lmsText);
//			delete (objectClone.sendObj.data.searchText);
//			delete (objectClone.sendObj.data.metadata);
//		}

		var bugReportObj = {"time":time, "type":type, "stackTrace": stackTrace, "obj":obj};
		var bugReportStr;
		try {
			bugReportStr = JSON.stringify(bugReportObj);
		} catch (e) {
			bugReportObj.obj = {"error":"object causes error during JSON.stringify"};
			bugReportStr = JSON.stringify(bugReportObj);
		}
		var itemSet = false; // set to true if the item gets set without errors
		newestEntry++;
		while (!itemSet) {
			try {
				sessionStorage.setItem(BUGREPORTPREFIX + newestEntry, bugReportStr);
				itemSet = true;
			} catch (e) {
				if (this.isStorageFullError(e)) {
					if (oldestEntry < newestEntry) {
						// we ran out of disk space allocated by the browser
						sessionStorage.removeItem(BUGREPORTPREFIX + oldestEntry); // delete the oldest entry
						oldestEntry++;
					} else {
						// some problem, all our entries have been deleted and it still says we don't have space
						newestEntry--; // it didn't set
						console.error("unable to store Bug Report Entry");
						break;
					}
				} else {
					// an error we don't check for
					newestEntry--; // it didn't set
					console.error("unable to store Bug Report Entry");
					break;
				}
			}
		}

		removeOldEntries();
	};
	
	this.isStorageFullError = function (e){
		return STORAGE_FULL_STRINGS.indexOf(e.message) >= 0;
	};

	/**
	 * Returns the entire log as an array of objects oldest to newest.
	 * If the unlikely happens that the json is corrupt a new empty array is returned.
	 * @return Array of objects
	 */
	function getLog() {
		var bugReport = [];
		for (var x = oldestEntry; x <= newestEntry; x++) {
			var entry = sessionStorage.getItem(BUGREPORTPREFIX + x);
			if (entry != null) {
				bugReport.push(JSON.parse(entry));
			}
		}
		return bugReport;
	};

	/**
	 * Change the default maximum log entries to keep.
	 * If max is 0 or NaN max is set to the default max.
	 * If the new max is less than the old, some entries may be deleted.
	 */
	function setMaxLogEntries(max) {
		var newMax = parseInt(max);
		if ((isNaN(newMax)) || (newMax < 1)) {
			newMax = DEFAULTMAXLOGENTRIES;
		}
		maxLogEntries = newMax;
		removeOldEntries();
	}

	/**
	 * clear the session storage. This needs to be done because otherwise
	 * its way to big after they have submitted a few bugs.
	 */
	this.clear = function (){
		for (var x = oldestEntry; x <= newestEntry; x++) {
			sessionStorage.removeItem(BUGREPORTPREFIX + x);
		}
		// reset the entry pointers
		oldestEntry = 1;
		newestEntry = 0;
	}

	this.addLogEntry = addLogEntry;
	this.getLog = getLog;
	this.setMaxLogEntries = setMaxLogEntries;

	//If the browser does not support session storage then unset the functions
	if (sessionStorage == undefined) {
		this.addLogEntry = unSupportedOperation;
		this.getLog = unSupportedOperation;
		this.getLogAsJSON = unSupportedOperation;
		this.setMaxLogEntries = unSupportedOperation;
	} else {
		initializeCounters()
		if (isNaN(oldestEntry) || isNaN(newestEntry)) {
			sessionStorage.clear();
			oldestEntry = 1;
			newestEntry = 0;
		}
	}

	//Add a log entry that the log was started
	this.init = function (){
		me.addLogEntry("bugReportStarted",{});
	}
};

// FUTURE: maybe move this to bootstrap.js
//  but be careful of order, BugReportManager.js must be loaded before this is run
// window.onerror = function (msg, url, line) {
// 	var errMsg = msg ? msg : 'no message';
// 	var errUrl = url ? url : 'no url';
// 	var errLine = line ? line : 'no line';
// 	try {
// 		//BugReportManager.addLogEntry("windowOnError", {"msg":errMsg, "url":errUrl, "line":errLine});
// 	} catch(e) {
// 		// avoid an infinite loop
// 		if(window.console){
// 			console.error(e);
// 		}
// 	}
//     return false;
// }

