/**
 * HashManager fires the hashChanged event with an object that contains all
 * the changed variables in the hash
 * // TODO: there are a number of undocumented functions that we should go through and document...
 */
function HashManager()  {

	var manager = this;
	var hashRecord = null;
	var lastHash = null;
	var myHashChecker = null;
	
	var toolEventHandler = null;
	
	var toolLoadingManager = null;
	
	/**
	 * Boolean that tracks if our current hash is on the first
	 * loaded tool
	 */
	var userOnFirstLoadedTool = true;
	
	/**
	 * Be able to set the tool event handler dependency that manages any tool listener
	 * events.
	 */
	this.setToolEventHandler = function(handler) {
		toolEventHandler = handler;
	};
	
	/**
	 * Be able to set the tool loading manager dependency that manages the loading of tools
	 * and information about loaded tools.
	 */
	this.setToolLoadingManager = function(manager) {
		toolLoadingManager = manager;
	};
	
	this.isUserOnFirstLoadedTool = function() {
		return userOnFirstLoadedTool;
	};

	this.init = function() {
		toolEventHandler.addToolListener(apiInit, 'APILoaded');
	};

	function apiInit() {
		if ('onhashchange' in window) {
			if (window.addEventListener) {
				window.addEventListener("hashchange", hashChanged, false);
			} else { // ie
				window.attachEvent("onhashchange", hashChanged);
			}
		} else {
			// for browsers that don't yet support onhashchange:
			myHashChecker = self.setInterval(checkHash, 250);
		}
		checkHash();
	}

	function checkHash() {
		if (hashRecord != document.location.hash) {
			hashRecord = document.location.hash;
			hashChanged();
		}
	}

	function hashChanged(evt) {
		//BugReportManager.addLogEntry("hashChanged", {"hash":document.location.hash.trim()});
		if (document.location.hash == '') {
			var newHash = {'t':_SESSION.settings.DefaultHomeAction};
			if(_SESSION.settings.DefaultContentAction) {
				newHash.c = _SESSION.settings.DefaultContentAction;
			}
			setHash(newHash);
		} else {
			fireToolEvent(resolveHashChanges(), 'HashChanged');
		}
	}

	/**
	 *Takes the browser url, parses variables that appear after the hash (#) character, and returns them as an
	 * associative array.
	 */
	function parseHashVars() {

		// safari adds %20 to hash where spaces exist, decode to get spaces back
		var hash = location.hash;
		
		return parseHashString(hash);
		
	}
	
	/**
	 * Takes a string representing the hash url and returns an associative array of the variables
	 */
	function parseHashString(hashString) {
		var nvPairs = new Object();
		
		var decodedHash = decodeURIComponent(hashString);
		var nvPairsRaw = decodedHash.substr(1).split('&');
		for ( var i = 0; i < nvPairsRaw.length; i++) {
			var nvPair = nvPairsRaw[i].split('=');
			nvPairs[nvPair[0]] = nvPair[1];
		}
		return nvPairs;
	}

	function resolveHashChanges() {
		var newHash = parseHashVars();
		var tempHash = gaerdvark.utils.cloneObject(newHash);
		for(var item in lastHash) {
			if(newHash[item] == lastHash[item]) {
				delete newHash[item];
				delete lastHash[item];
			}
		}
		for(var item in lastHash) {
			if(!newHash.removed) newHash.removed = new Object();
			newHash.removed[item] = lastHash[item];
		}
		lastHash = tempHash;
		
		// if they've left the initial hash... then we are no longer on
		// the tool that was first loaded in the system.
		if (toolLoadingManager.getFirstLoadedToolName()
			&& lastHash.t !== toolLoadingManager.getFirstLoadedToolName()) {
			userOnFirstLoadedTool = false;
		}

		// set the id of the body for css use
		document.getElementsByTagName('body')[0].id = lastHash.t.replace(" ", "_") + '_body';

		return newHash;
	}
	
	this.parseHashString = parseHashString;

	this.getHash = parseHashVars;

	/**
	 * Takes the saved off hash state that is in the hash string and restores
	 * the hash state to be that saved off value.
	 */
	this.restoreReturnHash = function() {
		var hash = manager.getHash();
		
		try {
			var serializedReturn = hash["_return"];
			var hash = JSON.parse(serializedReturn);
			setHash(hash);
		}
		catch (error) {
			throwError(error);
		}
	};
	
	/**
	 * Returns true if there is a saved off return hash in the hash string
	 * @returns {boolean} True if there is a return hash, false otherwise
	 */
	this.hasReturnHash = function() {
		var hash = manager.getHash();
		return hash.hasOwnProperty("_return") === true;
	};
	
	/**
	 * Given a hash object to set the hash location to, the current hash state is saved
	 * and stored as part of the new hash.  This allows the hash 
	 * @param {type} newHash
	 * @returns {undefined}
	 */
	this.saveCurrentHashAndSetHash = function(newHash) {
		var hash = manager.getHash();
		
		var serializedHash = JSON.stringify(hash);
		newHash["_return"] = serializedHash;
		manager.setHash(newHash);
	};

	this.setHash = function(newHash) {
		var hashString = '#';
		for(var i in newHash) {
			hashString += (i + '=' + newHash[i] + '&');
		}
		location.hash = hashString.substr(0, hashString.length - 1);
	};

	this.addHashParam = function (name, value) {
		var temp = getHash();
		temp[name] = value;
		setHash(temp);
	};

	this.removeHashParam = function (name) {
		var temp = getHash();
		delete temp[name];
		setHash(temp);
	};
};
