
/**
	 * Class used to process html of tool.
	 * @param group is the group of the tool instantiating this class
	 */
	function ProcessHtml(toolGroup) {

		var me = this;
		var group = toolGroup;

		var processDynamicOn = true;

		var focusNode = null;
		var actionObj = null;
		var permissionObj = null;

		var currentHTMLTree = null;
		var rootNodesUpdated = null;

		// nodes that will have a period added at the beginning of the comment
		// to stop them from being processed again if the finished html
		// is added to another tool and reprocessed
		var commentNodesToDisable = [];

		// constants

		// DYNAMIC NODES CAN'T BE NESTED!!!!
		var DYNAMICONNODEFLAG = 'data-dynamicon'; // a single node that will be reproduced when the js asked for it by label
		var LOOPONFLAG = 'data-loopon'; // a node (and it's descendants) that will be reproduced once for each property/value in an object/array
		// label ascending/descending initial,  ascending or descending is optional, ascending is default, initial is optional
		var TOOLLOADFLAG = "data-toolload";
		var COMMENTFLAGSTHATMUSTBEPROCESSED = /DYNAMIC\s+ON|data-dynamicon|data-loopon|LOOP\s+ON|RECURSE\s+ON|TOOL\s+LOAD|EXECUTE\s+FUNCTION|IF\s+START|IGNORE\s+START/i;
		var htmlToolsRequested = {};
		var COMMENTSWITCHREGEX = /^\s*(IF\s+START|IF\s+ELSE|LOOP\s+ON|RECURSE\s+ON|RECURSE\s+PLACE|DYNAMIC\s+ON|DYNAMICON\s+PLACE|TOOL\s+LOAD|EXECUTE|IGNORE\s+START)\s*/i;
		var COMMENTENDREGEX = /^\s*(RECURSE END|IF END|LOOP END)\s*/i;
		var IFSTARTREGEX = /^\s*IF\s+START\s+(.*)\s*$/i; // <!-- IF START valid javascript condition statement that can use dataflags -->
		var IFELSEREGEX = /^\s*IF\s+else\s*$/i; // <!-- IF ELSE -->
		var IFENDREGEX = /^\s*IF\s+end\s*$/i; // <!-- IF END -->
		var IGNORESTARTREGEX = /^\s*IGNORE\s+START\s*$/i; // <!-- IGNORE START -->
		var IGNOREENDREGEX = /^\s*IGNORE\s+END\s*$/i; // <!-- IGNORE END -->
		var LOOPONREGEX = /^\s*LOOP\s+on\s+(\S+)\s*$/i; // <!-- LOOP on dataName -->
		var LOOPENDREGEX = /^\s*LOOP\s+end\s*$/i; // <!-- Loop end -->
		var RECURSEONREGEX = /^\s*RECURSE\s+on\s+(\S+)(?:\s+(\S+))?\s*$/i; // <!-- RECURSE ON dataName -->
		var RECURSEPLACEREGEX = /^\s*RECURSE\s+place\s+(\S+)(?:\s+(\S+))?\s*$/i; // <!-- RECURSE PLACE dataName --> or <!-- RECURSE PLACE dateName propertyNameToCallDataName -->
		var RECURSEENDREGEX = /^\s*RECURSE\s+end\s*$/i; // <!-- RECURSE end -->
		// <!-- DYNAMIC on label toolCommonName replace/ascending/descending --> replace, ascending, or descending is optional, replace is default
		var DYNAMICONREGEX = /^\s*DYNAMIC\s+on\s+(.+)\s*$/i;
		var DYNAMICONPLACEREGEX = /^\s*DYNAMICON\s+place\s+(\S+)\s+(\S+)\s*$/i; // <!-- DYNAMICON place label dataName -->
		var TOOLLOADREGEX = /^\s*TOOL\s+load\s+(.+)\s*$/mi; // <!-- TOOL load tool name(arguments) --> try to load the action tool
		var EXECUTEFUNCTIONREGEX = /^\s*EXECUTE\s+function\s+([^\)]*\))\s*;?\s*$/i; // <!-- EXECUTE function alert('hello world'); doSomethingElse(); -->
		var DATAFLAGREGEX = /\^([a-zA-Z0-9\._]+)(\([^\)]*\);?)?\^/g; // e.g. ^user.name^  ^something.funcname("string", int)^  ^funcname(^grade.date^)^  ^funcname("string");^
//		var FUNCTIONSINEVENTSREGEX = /([a-zA-Z0-9_]+\s*\([^\)]*\)\s*;?)/g;
//		var FUNCTIONNAMEARGREGEX = /^\s*([a-zA-Z0-9_]+)\s*(\([^\)]*\))?\s*;?\s*$/;
		var SCALARTYPESREGEX = /^(string|number|function)$/i;
		var STANDARDEVENTATTRIBUTES = {
			"data-onabort":true,
			"data-onblur":true,
			"data-onchange":true,
			"data-onclick":true,
			"data-ondblclick":true,
			"data-onfocus":true,
			"data-onkeydown":true,
			"data-onkeypress":true,
			"data-onkeyup":true,
			"data-onload":true,
			"data-onmousedown":true,
			"data-onmousemove":true,
			"data-onmouseout":true,
			"data-onmouseover":true,
			"data-onmouseup":true,
			"data-onreset":true,
			"data-onselect":true,
			"data-onsubmit":true,
			"data-onunload":true,
			"data-ondragstart":true,
			"data-ondrag":true,
			"data-ondragend":true,
			"data-ondragenter":true,
			"data-ondragover":true,
			"data-ondrop":true,
			"data-ondragleave":true
		};


		/*
		 * functions to be executed when any of these attributes are changed
		 * by a data flag during the processing of the html
		 *
		 * checked, selected, and disabled have specific allowed values and
		 * some browsers will not produce the attribute node if the values
		 * are not one of the allowed therefore data- is prepended to those names
		 * and these functions ensure the appropriate properties get set
		 *
		 * value and defaultValue properties must be changed because changing
		 * the attribute's nodeValue only will not change the input node's properties
		 */
		var specialCaseNodeAttribute = {};
		// set the checked and defaultChecked property of the input node
		specialCaseNodeAttribute['data-checked'] = function(nodeObj, attributeIndex) {
			nodeObj.checked = evaluateValue(nodeObj.attributes[attributeIndex].value);
			nodeObj.defaultChecked = nodeObj.checked;
		};
		// set the selected and defaultSelected property of the input node
		specialCaseNodeAttribute['data-selected'] = function(nodeObj, attributeIndex) {
			nodeObj.selected = evaluateValue(nodeObj.attributes[attributeIndex].value);
			nodeObj.defaultSelected = nodeObj.selected;
		};
		// set the disabled property of the input node
		specialCaseNodeAttribute['data-disabled'] = function(nodeObj, attributeIndex) {
			nodeObj.disabled = evaluateValue(nodeObj.attributes[attributeIndex].value);
		};
		// set the value and defaultValue property of the input node
		specialCaseNodeAttribute['value'] = function(nodeObj, attributeIndex) {
			nodeObj.value = nodeObj.attributes[attributeIndex].value;
			nodeObj.defaultValue = nodeObj.attributes[attributeIndex].value;
		};
		specialCaseNodeAttribute['data-style'] = function(nodeObj, attributeIndex) {
			var style = document.createAttribute('style');
			style.nodeValue = nodeObj.attributes[attributeIndex].nodeValue;
			nodeObj.attributes.setNamedItem(style);
		};

		/*
		 * attempt to evaluate a nodeValue, if it returns exactly a boolean accept it
		 * if not type cast the node value to a boolean
		 */
		function evaluateValue(nodeValue) {
			var result = null;
			var evalStr = 'result = ' + nodeValue;
			try {
				eval(evalStr);
				if (typeof result == 'boolean') return result;
			} catch (e) {
				// ignore error
			}
			return nodeValue ? true : false;
		}

		/**
		 * run replaceActionFlags if actions have been provided
		 *  or any default actions are requested in the node
		 *
		 */
		function checkForActionFlags(nodeObj) {
			if ((nodeObj.nodeType == 1)
					&& ((gaerdvark.utils.propertyCount(actionObj) > 3)
						|| (nodeObj.innerHTML.indexOf('toggleClassOfNode'))
						|| (nodeObj.innerHTML.indexOf('addClassToNode'))
						|| (nodeObj.innerHTML.indexOf('removeClassFromNode')))) {
				replaceActionFlags(nodeObj);
			}
		}
		
		/**
		 * Given the anchor node obj this goes through and sets the href to javascript:void for all links
		 * that would change the user's current window location outside of our system.  Links that have an onclick we allow
		 * as we assume they are doing a window.open command.  Links that change _top, _self, _parent, or attempt to mail to
		 * someone we kill.
		 * 
		 * @Removed References to this 11 Sep 2017 it was killing links we needed for printing. We think that this isn't nessasary because 
		 * if you leave the page and go back, it will take you directly to the page you were on. It may be a problem elsewhere but we don't
		 * do many outside links elsewhere either. 
		 */
		function removeExternalLinksThatChangeCurrentWindow(nodeObj) {
			console.debug("This function should not be used. Depricated and javascript:void throws warnings in the browsers.");
			var target = nodeObj.target.trim();
			// have to getAttribute because the browser has already changed node.href adding the rest of the url
			//  before any hash we may have put there
			var href = nodeObj.getAttribute('href');

			if(href !== null){
				href = href.trim();
			}
			
			if ((nodeObj.onclick == null)
					&& ((href == null) || (href.substring(0, 1) != '#') )
					&& (['', '_top', '_self', '_parent'].indexOf(target) > -1)
					&& ((href == null) || (href.indexOf('mailto:') !== 0)) ) {

				nodeObj.href = "javascript: void(0);";
			}
		}

		/**
		 * Creates a node event for each data-on??????? attribute that corresponds to an event.
		 * Will set all the tools
		 * within the nodeobject with the proper functions from the tool object
		 * If the tool specifies parameters they must be scaler values only,
		 * create an array as a property in the node using the name of the action
		 * and place the parameters in the order given in the array
		 *
		 * @param nodeObj node
		 */
		// TODO: check if a cloned node still has the event that was added using addEventListener/attachEvent
		function replaceActionFlags(nodeObj) {
			var lowerCaseNodeName = nodeObj.nodeName.trim().toLowerCase();

			if (lowerCaseNodeName == 'a') {	
				// if the href is going to take us away from our site in our window change it
				
				// Removing this because we don't need it. 
				//removeExternalLinksThatChangeCurrentWindow(nodeObj);
			} 
			
			if (lowerCaseNodeName == 'form') {
				nodeObj.onsubmit = function(){return false;};
			}

			var attributes = nodeObj.attributes;
			for (var a = 0; a < attributes.length; a++) {
				var nodeName = attributes[a].nodeName.toLowerCase();
				if (STANDARDEVENTATTRIBUTES[nodeName]) { // is this one of the node events we look for
					var funcObjArray = getFunctionsFromString(attributes[a].value); // returns array of functions:args objects
					nodeObj['gae' + attributes[a].nodeName] = createRedirectSafeFunction(funcObjArray);
					if (nodeObj.addEventListener) { // firefox, safari, chrome, ie9
						nodeObj.addEventListener(attributes[a].nodeName.substr(7), nodeObj['gae' + attributes[a].nodeName], false); // e.g. remove the on from onclick
					} else { // ie 8 and less
						nodeObj.attachEvent(attributes[a].nodeName.substr(5), nodeObj['gae' + attributes[a].nodeName]); // ie8 and before want the on in front of each event e.g. onclick
					}
				} else if (nodeName == 'data-setfocus' && attributes[a].nodeValue != 'false') {
					focusNode = nodeObj;
				}
			}
			for (var n = 0; n < nodeObj.childNodes.length; n++) {
				if (nodeObj.childNodes[n].nodeType == 1) {
					replaceActionFlags(nodeObj.childNodes[n], actionObj);
				}
			}
		}

		/**
		 * returns array of functions:args objects
		 * @param functionStr string
		 * @return array of objects [{"ptr":pointerToFunction, "args":[arguments]}]
		 */
	function getFunctionsFromString(functionStr) {
			var funcObjArray = [];
			var locatedFunctions = parseFunctionsLine(functionStr);
				for (var f = 0; f < locatedFunctions.length; f++) { // set up the functions to be executed
					funcObjArray.push(createFuncObj(actionObj[locatedFunctions[f].funcName], locatedFunctions[f].argsArray));
				}
			return funcObjArray;
		}

		/**
		 * parse function line, return array of function objects
		 */
		function parseFunctionsLine(str) {
			var objArray = [];
			var i = 0;
			var nextChrEscaped = false;
			while (i < str.length) {
				skipWhiteSpace();
				if (i == str.length) break;
				objArray.push({"funcName":getFunctionName(), "argsArray":getArgsArray()});
			}
			return objArray;

			function skipWhiteSpace() {
				while ((/\s/.test(str[i])) && (i < str.length)) {
					i++;
				}
			}

			function getFunctionName() {
				var startOfArgs = str.indexOf('(', i);
				if (startOfArgs === -1) {
					throwError('invalid function name at ' + i + ' in ' + str);
					return '';
				}
				var functionName = str.substring(i, startOfArgs).trim();
				i = startOfArgs;
				return functionName;
			}

			function getArgsArray() {
				var argsArray = [];
				var commaCount = 0;
				var error = false;
				i++; // skip over the starting open paren
				while ((i < str.length) && (!error)) {
					skipWhiteSpace();
					if (i == str.length) break;
					switch (str[i]) {
						case ')' :
							if ((argsArray.length + commaCount) && (commaCount != argsArray.length - 1)) {
								error = true;
								break;
							}
							i++;
							skipWhiteSpace();
							if (i == str.length) return argsArray;
							if (str[i] == ';') i++;
							return argsArray;
						case '\'' :
						case '"' :
							if (commaCount != argsArray.length) error = true;
							argsArray.push(getStrArg(str[i]));
							break;
						case ',' :
							commaCount++;
							if (commaCount != argsArray.length) error = true;
							i++;
							break;
						case '{' :
							if (commaCount != argsArray.length) error = true;
							var jsonObj = {};
							eval('var jsonObj = ' + getObjArg(str[i]));
							argsArray.push(jsonObj);
							break;
						default :
							if (commaCount != argsArray.length) error = true;
							argsArray.push(getLitteralArg());
							break;
					}
				}
				throwError('invalid function arguments at ' + i + ' in ' + str);
				return [];
			}

			function getStrArg(quoteChar) {
				var returnStr = '';
				i++;
				while (!((!nextChrEscaped) && (str[i] == quoteChar))) {
					if (i == str.length) {
						throwError('invalid string argument at ' + i + ' in ' + str);
						return '';
					}
					switch (str[i]) {
						case '\\' :
							if (!nextChrEscaped) {
								i++;
								nextChrEscaped = true;
								break;
							}
						default :
							nextChrEscaped = false;
							returnStr += str[i];
							i++;
							break;
					}
				}
				i++;
				return returnStr;
			}

			function getObjArg() {
				var returnStr = '{';
				i++;
				while (!((!nextChrEscaped) && (str[i] == '}'))) {
					if (i == str.length) {
						throwError('invalid string argument at ' + i + ' in ' + str);
						return '{}';
					}
					switch (str[i]) {
						case '\\' :
							if (!nextChrEscaped) {
								i++;
								nextChrEscaped = true;
								break;
							}
						case '{' :
							returnStr += getObjArg();
							break;
						default :
							nextChrEscaped = false;
							returnStr += str[i];
							i++;
							break;
					}
				}
				i++;
				return returnStr + '}';
			}

			function getLitteralArg() {
				var closeParen = str.indexOf(')', i);
				var closeComma = str.indexOf(',', i);
				var endOfArgs = Math.max(str.indexOf(')', i), str.indexOf(',', i));
				if(closeComma > -1 && closeParen > -1) {
					endOfArgs = Math.min(str.indexOf(')', i), str.indexOf(',', i));
				}
				if (endOfArgs == -1) {
					throwError('invalid litteral at ' + i + ' in ' + str);
					return null;
				}
				//check to see if this is a function
				var nextOpen = str.indexOf('(', i);
				var start = i;
				if(nextOpen > -1 && nextOpen < endOfArgs) {
					i = nextOpen;
					getArgsArray();
					endOfArgs = i;
				}
				var returnLit = str.substring(start, endOfArgs).trim();
				i = endOfArgs;
				var numVal = +returnLit;
				if (!isNaN(numVal)){
					return numVal;
				}
				return {'lit':returnLit};
			}
		}

		/*
		* Private method that will act as privileged.
		* @return arguments which are passed to the function
		*/
		function returnTheArguments() {
			var args = [];
			for (var a = 0; a < arguments.length; a++) {
				if(typeof(arguments[a]) == 'string') {
					arguments[a] = arguments[a].replace(/\\'/g, "'");
				}
				args.push(arguments[a]);
			}
			return args;
		}

		/**
		 * creates a funcObj to place in the funcObjArray
		 *
		 * @param ptr obect (function pointer)
		 * @param args array (array of arguments to pass to the function)
		 *
		 * return object
		 */
		function createFuncObj(ptr, args) {
			return {"ptr":ptr, "args":args};
		}
		/*
		 * Private method that will act as privileged.
		 * Returns a value from the data object given a property name.
		 * The returned value will always be scalar
		 *  or whatever is returned if the located property is a function
		 *  unless ignoreScalar is true
		 *  then the returned value can be of any type (object, array, scalar, etc).
		 * If the value is a function the function will be executed and its
		 * return value will be returned.
		 *
		 * @param propertyName string
		 * @param dataObj	object
		 * @param ignoreScalar boolean
		 *
		 * @return mixed
		 */
		function getValue(propertyName, dataObj, ignoreScalar) {
			if (ignoreScalar == undefined) ignoreScalar = false;

			// if this is a function with arguments break it up
			var parsedFunctionFlag = separateToolAndArguments(propertyName);
			var arguments = [];
			var functionArguments = parsedFunctionFlag[2];
			if (functionArguments) {
				propertyName = parsedFunctionFlag[1];
				try {
					eval('arguments = returnTheArguments' + functionArguments);
				} catch (e) {
					throwError("Could not extract arguments from function string " + propertyName + functionArguments, e);
					return '^' + propertyName + functionArguments + '^';
				}
			}
			arguments.push(dataObj);
			
			var keyValues = propertyName.split(".");
			var targetValue = dataObj;
			// walk a path forward through the objects looking for the value
			// e.g. dataObj to Role to Action to Name action.name
			for (var j = 0; j < keyValues.length; j++) {
				if ((targetValue.hasOwnProperty(keyValues[j]))
						&& ((ignoreScalar)
							|| (j < keyValues.length - 1)
							|| (SCALARTYPESREGEX.test(typeof targetValue[keyValues[j]])))) {

					var targetValueVal = getTargetValue(targetValue[keyValues[j]], arguments);

					if(targetValueVal === null){
						return '^' + keyValues[j] + '^';
					}else{
						targetValue = targetValueVal;
					}
					
				} else {
					var resultObj = {"found":false};
					// didn't find the value
					// try in the currentLoopData
					// start with full dotted name and work backward to find value
					// e.g. Role.Action.Name

 					var finalProperty = propertyName.substr(propertyName.lastIndexOf('.')+1);
					if (finalProperty == "length") {
						var tmpProperty = propertyName.substring(0, propertyName.lastIndexOf('.'));
						var innerLoopName = tmpProperty.substring(tmpProperty.lastIndexOf('.')+1);
						var newProperty = tmpProperty.substring(0, tmpProperty.lastIndexOf('.'));

						searchObjForValue(dataObj.currentLoopData, newProperty, arguments, resultObj);
						if (resultObj.found) {
							resultObj.found = false;
							searchObjForValue(resultObj.targetValue, innerLoopName+'.length', arguments, resultObj);
							if (resultObj.found) {
								return resultObj.targetValue;
							}
						}
					}
					resultObj.found=false;
 
					searchObjForValue(dataObj.currentLoopData, propertyName, arguments, resultObj);
					if (!resultObj.found) {
						// try in the root of dataObj
						searchObjForValue(dataObj, propertyName, arguments, resultObj);
					}
					if (resultObj.found) return resultObj.targetValue;
					throwError('data not found ' + propertyName);
					return '^' + propertyName + '^';
				}
			}
			return targetValue;
		}

		/**
		 * TODO: need more commenting
		 * didn't find the value as an exact name in dataObj
		 * try in the currentLoopData
		 * start with full dotted name and work backward to find value
		 * e.g. Role.Action.Name
		 *
		 * @param dataObjToSearch object
		 * @param propertyName string
		 * @param dataObj object
		 * @param resultObj object
		 */
		function searchObjForValue(dataObjToSearch, propertyName, arguments, resultObj) {
			var rowName = propertyName;
			var columnNames = [];
			try {
				while (rowName.length > 0) {
					if (Object.prototype.hasOwnProperty.call(dataObjToSearch, rowName) &&
						  dataObjToSearch[rowName] !== undefined) {
						resultObj.targetValue = getTargetValue(dataObjToSearch[rowName], arguments);
						if (columnNames.length == 0) resultObj.found = true;
						while (columnNames.length) {
							var columnName = columnNames.pop();
							if (resultObj.targetValue !== null && resultObj.targetValue.hasOwnProperty(columnName)) {
								resultObj.found = true;
								resultObj.targetValue = getTargetValue(resultObj.targetValue[columnName], arguments);
							} else {
								break;
							}
						}
						break;
					}
					// didn't find value, take last name off full name and try again
					columnNames.push(rowName.substr(rowName.lastIndexOf('.') + 1));
					rowName = rowName.substring(0, rowName.lastIndexOf('.'));
				}
			}
			catch (error) {
				throwError("Error occurred while attempting to resolve property name: " + propertyName, error);
				throw error;
			}
		}

		/*
		 * Private method that will act as privileged.
		 * If source is a function it will call it with the given arguments,
		 * otherwise source is returned.
		 * @param source mixed
		 * @param dataObj object
		 * @param arguments array of arguments to pass if this is a function
		 * @return mixed string if source is a function
		 */
		function getTargetValue(source, arguments) {
            if (typeof source === 'function') {
                try {
                    return source.apply(me, arguments) + "";
                } catch (e) {
                    throwError("Data flag function failed.", e);
                    return "Data flag function failed";
                }
            }
			return source;
        }

		/*
		 * Private method that will act as privileged.
		 * Identifies all data tags in the HTML node and builds the dataFlagArray
		 * for further processing. Flags must be in the format: ^Data.Flag^
		 * Functions passed to processHtml in the data as pointers can be used and argurments passed.
		 *   e.g. ^functionPointerName("string", 12, "^user.firstName^", ^user.id^)^
		 *   The flags in the agruments will be replaced first then the function run passing it all the arguments.
		 *   The function must return a string that will be used to replace the flag.
		 * @param nodeObj DOM node object
		 * @param dataObj object
		 * @param escapeQuotes boolean set to true if all quotes in strings are to be escaped
		 *
		 * @returns newDataObj
		 */
		function replaceDataFlags(nodeObj, dataObj, escapeQuotes) {
			var dataFlagArray;
			if (typeof nodeObj == 'string') {
				dataFlagArray = nodeObj.match(DATAFLAGREGEX);
			} else if (nodeObj.nodeType == 8) { // comment node
				dataFlagArray = nodeObj.nodeValue.match(DATAFLAGREGEX);
			} else {
				var decodedStr = nodeObj.innerHTML.trim().htmlEntityDecode();
				var htmlStr = gaerdvark.utils.decodeURIStrings(decodedStr);
				for (var attrIdx = 0; attrIdx < nodeObj.attributes.length; attrIdx++) {
					if (nodeObj.attributes[attrIdx].nodeName == 'src') {
						nodeObj.attributes[attrIdx].nodeValue = gaerdvark.utils.decodeURIStrings(nodeObj.attributes[attrIdx].nodeValue.htmlEntityDecode());
					}
					htmlStr += nodeObj.attributes[attrIdx].nodeValue;
				}
				dataFlagArray = htmlStr.match(DATAFLAGREGEX);
			}
			var dataFlagObj = {};
			if (dataFlagArray) {
				//var length = dataFlagArray.length;
				for (var i = 0; i < dataFlagArray.length; i++) {
					if (!dataFlagObj.hasOwnProperty(dataFlagArray[i])) {
						// look for dataflags in arguments of dataflag functions
						// strip the leading and trailing ^ and check for embedded flags
						var embeddedFlags = dataFlagArray[i].substring(1, dataFlagArray[i].length - 1).match(DATAFLAGREGEX);
						
						if (embeddedFlags) {
							var funcString = dataFlagArray[i];
							for (var j = 0; j < embeddedFlags.length; j++) {
								var convertedValue = addFlagMap(embeddedFlags[j], embeddedFlags[j], dataFlagObj, dataObj, escapeQuotes);
								funcString = funcString.stringReplace(embeddedFlags[j], convertedValue);
							}
							addFlagMap(dataFlagArray[i], funcString, dataFlagObj, dataObj, escapeQuotes, dataFlagArray);
						} else {							
							addFlagMap(dataFlagArray[i], dataFlagArray[i], dataFlagObj, dataObj, escapeQuotes);
						}
					}
				}
				// move dataFlagObj to array of objects
				dataFlagArray = [];
				for (var flag in dataFlagObj) {
					dataFlagArray.push({"flag":flag, "value":dataFlagObj[flag]});
				}
				dataFlagArray.sort(compareStringLengthsLongFirst);
				return replaceDataFlagsInNode(nodeObj, dataFlagArray);
			}
			return null;
		}

		/**
		 * Check and add a value to data flag object
		 * Returns the converted value
		 * @param String keyToUse (with leading and trailing ^)
		 * @param String dataFlagToFind (with leading and trailing ^)
		 * @param Object dataFlagObj
		 * @returns mixed value
		 */
		function addFlagMap(keyToUse, dataFlagToFind, dataFlagObj, dataObj, escapeQuotes, dataFlagArray) {
			var dataFlag = dataFlagToFind.substring(1, dataFlagToFind.length - 1); // remove the leading and trailing ^
			if (dataFlag.indexOf('(')) {
				dataFlagObj[keyToUse] = getValue(dataFlag, dataObj);
			}
			if (dataFlagObj[keyToUse] === undefined
					|| dataFlagObj[keyToUse] === null) {
				return dataFlagObj[keyToUse];
			}

			if ((escapeQuotes) && (dataFlagObj[keyToUse].replace)) {
				dataFlagObj[keyToUse] = dataFlagObj[keyToUse].replace(/'/g, '\\\'').replace(/"/g, '\\\"');
			}
			return dataFlagObj[keyToUse];
		}

		/**
		 * Used to sort the dataFlagArray by flag string length shortest first.
		 * This is done to ensures that functions with dataflags as arguments
		 * get replaced before dataflags only.
		 */
		function compareStringLengthsLongFirst ( a, b ) {
		  if ( a.flag.length > b.flag.length )
		    return -1;
		  if ( a.flag.length < b.flag.length )
		    return 1;
		  return 0; // a and b are the same length
		}

		/**
		 * Returns an array
		 * @param {type} ancestorElement
		 * @returns {unresolved}
		 */
		function getCommentNodes(ancestorElement) {
			function traverseDom(curr_element, comments) { // this is the recursive function
				// base case: node is a comment node
				if (curr_element.nodeType === 8 || curr_element.nodeName === "#comment") {
					// You need this OR because some browsers won't support either nodType or nodeName... I think...
					comments.push(curr_element);
				} else if(curr_element.childNodes) {
					var childNodeCount = curr_element.childNodes.length;
					// recursive case: node is not a comment, process the children
					for (var i = 0; i < childNodeCount; i++) {
						traverseDom(curr_element.childNodes[i], comments);
					}
				}
			}
			var comments = [];
			traverseDom(ancestorElement, comments);
			return comments;
		}

		/*
		 * example of tool load text being added to html
			<div> <!-- Just a container this div is optional -->
				 <!-- TOOL LOAD MultiMedia({
				"media":[
					 {
						  "mimeType":"audio/mp3",
						  "fileUrl":"?blobWSK=agpzfmRzY21zbG1zcroBCxIRQmxvYnN0b3JlTWV0YWRhdGEiogFBTUlmdjk3Nk95NUZweFlaeWpyVlZiZHZoMGp5bS1MdDJrYzM5a01USG5rVXk2UEVYTGhWckZScEtOcXVpYU5kUVhybTJBWXlpOFhKc2ZRNmkxU2FVRmw1VWhDNjMwamlVblZVS2JWaVRBdTJHQzAyek1CMzBLc1NhMzhYZUdyRFMzRlBvbW1EaGJlcTV0RzVEakxJZDcwTXdqUVJEYlpDakEMogEGZHMuYW1l",
					 }
				],
				"preload":"auto",
				"autoplay":false,
				"cssSelectorAncestor":"#jp_controller_1",
				"fileName":"Giraffe",
				"displayController":true
				}); -->
			</div>
		 *
		 *
		 * @param {type} tempNode
		 * @returns {undefined}
		 */
		function checkForToolLoadElements(completedElement) {
			// make sure completedElement is an element
			if (completedElement.querySelectorAll) {
				// get the node list
				var toolLoadElements = completedElement.querySelectorAll("[" + TOOLLOADFLAG + "]");
				if (toolLoadElements.length > 0) {
					// go backwards in case the node list changes due to operations in loop
					for (var i = toolLoadElements.length - 1; i >= 0; i--) {
						var toolLoadElement = toolLoadElements[i];
						var commentNode = document.createComment(toolLoadElement.nodeName + ' toolload ' + toolLoadElement.getAttribute(TOOLLOADFLAG));
						toolLoadElement.parentNode.replaceChild(commentNode, toolLoadElement);
						processToolLoadComment(toolLoadElement, null, null, null, null, commentNode);
					}
				}
			}
		}

		/*
		 * Private method that will act as privileged.
		 * Replaces data flags within the HTML node, traversing the entire node tree as necessary.
		 * @param nodeObj DOM node object, either text or element
		 * @param dataFlagArray array of objects
		 * @return nodeObj
		 */
		function replaceDataFlagsInNode(nodeObj, dataFlagArray) {
			var flagStr = null;
			if (typeof nodeObj == 'string') {
				for (var i = 0; i < dataFlagArray.length; i++) {
					nodeObj.stringReplace(dataFlagArray[i].flag, dataFlagArray[i].value);
				}
				// be sure to return this nodeObj because strings are passed by value not by reference! Strings aren't pointers!
			} else if (nodeObj.nodeType == 8) { // comment node
				for (var i = 0; i < dataFlagArray.length; i++) {
					nodeObj.nodeValue = nodeObj.nodeValue.stringReplace(dataFlagArray[i].flag, dataFlagArray[i].value);
				}
			} else if (nodeObj.nodeType == 3) { // text node
				for (var i = 0; i < dataFlagArray.length; i++) {
					var flagStr = dataFlagArray[i].flag;
					var flagValue = dataFlagArray[i].value;
					if (nodeObj.nodeValue.indexOf(flagStr) > -1) {
						// check if the replacement value is html
						var tempNode = document.createElement('div');
						tempNode.innerHTML = '<span>just a holder</span>' + flagValue;
						if ((tempNode.childNodes.length == 2) && (tempNode.childNodes[1].nodeType == 3)) {
							// only a text node was produced, it wasn't html
							// have to run the text through the tempNode in case any of it has stuff like &nbsp;
							tempNode.innerHTML = nodeObj.textContent.stringReplace(flagStr, flagValue);
							nodeObj.nodeValue = tempNode.childNodes.length > 0 ? tempNode.childNodes[0].nodeValue : '';
						} else {
							// it is html, split the text node, insert the replacement html where the flagStr was
							tempNode.removeChild(tempNode.childNodes[0]); // remove the added span
							replaceDataFlagsInNode(tempNode, dataFlagArray); // replace any data flags in the replacement html
							var parent = nodeObj.parentNode;
							var textNodeValue = nodeObj.nodeValue;
							var flagStrIndex = textNodeValue.indexOf(flagStr);
							var preText = textNodeValue.substring(0, flagStrIndex);
							var postText = textNodeValue.substring(flagStrIndex + flagStr.length);
							if (preText) {
								try {
									parent.insertBefore(document.createTextNode(preText), nodeObj);
								} catch (e) {
									throwError('Failed to place text "' + preText + '" before data flag ' + flagStr, e);
								}
								// There may be more dataflags to replace in the text node that was just created, if so, replace them.
								if (nodeObj.previousSibling.nodeValue.search(DATAFLAGREGEX) > -1) replaceDataFlagsInNode(nodeObj.previousSibling, dataFlagArray);
							}
							try {
								while(tempNode.childNodes.length) {
									parent.insertBefore(tempNode.firstChild, nodeObj);
								}
							} catch(e) {
								throwError('Failed to replace html for data flag ' + flagStr, e);
							}
							if (postText) {
								try {
									parent.insertBefore(document.createTextNode(postText), nodeObj);
								} catch (e) {
									throwError('Failed to place text "' + postText + '" after data flag ' + flagStr, e);
								}
								// There may be more dataflags to replace in the text node that was just created, if so, replace them.
								if (nodeObj.previousSibling.nodeValue.search(DATAFLAGREGEX) > -1) replaceDataFlagsInNode(nodeObj.previousSibling, dataFlagArray);
							}
							try {
								parent.removeChild(nodeObj);
								break;
							} catch(e) {
								throwError('Failed to remove text node after data flag replacement ' + flagStr, e);
							}
						}
					}
				}
			} else {
				// check attributes
				for (var a = 0; a < nodeObj.attributes.length; a++) {
					nodeObj.attributes[a].nodeValue = nodeObj.attributes[a].nodeValue.replace(/%5E/g, '^');
					if (nodeObj.attributes[a].nodeValue.indexOf('^') > -1) {
						for (var i = 0; i < dataFlagArray.length; i++) {
							// changed this line so integer 0 would not be changed to empty string
							// var value = dataFlagArray[i].value || '';
							var value = dataFlagArray[i].value;
							if(STANDARDEVENTATTRIBUTES[nodeObj.attributes[a].name]) {
								value = value + '';
								value = value.replace(/'/g, "\\'");
							}
							nodeObj.attributes[a].nodeValue = nodeObj.attributes[a].nodeValue.stringReplace(dataFlagArray[i].flag, value);
						}
						if (nodeObj.attributes[a].nodeName == 'data-src') {
							var src = nodeObj.attributes[a].nodeValue.trim();
							if (src.length) {
								nodeObj.src = src;
							}
						}
						
						if (nodeObj.attributes[a].nodeName == 'data-poster') {
							var poster = nodeObj.attributes[a].nodeValue.trim();
							if (poster.length) {
								nodeObj.poster = poster;
							}
						}
						
						if (specialCaseNodeAttribute.hasOwnProperty(nodeObj.attributes[a].nodeName)) {
							specialCaseNodeAttribute[nodeObj.attributes[a].nodeName](nodeObj, a);
						}
					}
				}
				// check the children
				if (nodeObj.childNodes.length > 0) { // has children
					var htmlStr = nodeObj.innerHTML.replace(/%5E/g, '^');
					if (htmlStr.search(DATAFLAGREGEX) > -1) { // has a data flag
						for (var c = 0; c < nodeObj.childNodes.length; c++) { // check each child
							if ((nodeObj.childNodes[c].nodeType == 1) // element
										|| (
												((nodeObj.childNodes[c].nodeType == 3) || (nodeObj.childNodes[c].nodeType == 8)) // test or comment
												&& (nodeObj.childNodes[c].nodeValue.search(DATAFLAGREGEX) > -1))) // has data flag
									 {
								replaceDataFlagsInNode(nodeObj.childNodes[c], dataFlagArray);
							}
						}
					}
				}
			}
			return nodeObj; // must return nodeObj in case it is a string!
		}

		var processCommentMethod = {
			"if start":processIfStartIfElseComment,
			"if else":processIfStartIfElseComment,
			"ignore start":processIgnoreStartComment,
			"loop on":processLoopComment,
			"recurse on":processRecurseOnComment,
			"recurse place":processRecursePlaceComment,
			"dynamic on":processDynamicOnComment,
			"dynamicon place":processDynamicOnPlaceComment,
			"tool load":processToolLoadComment,
			"execute":processExecuteComment
		};
		/*
		 * Private method that will act as privileged.
		 * processHtmlLoops identifies loops (designated with
		 * <!-- LOOP on Abc.Xyz --> ... <-- LOOP end --> comments.) within the tool HTML.
		 * It is also called recursively for each node element of the source tree.
		 * @param sourceNode object, root node of the tool's HTML
		 * @param targetNode object, existing DOM node in which to append the sourceNode
		 * @param dataObj object, contains the data that will replace the data flags in the loops
		 */
		function processHtmlLoops(sourceNode, targetNode, dataObj) {
			for (var sourceNodeIndex = 0; sourceNodeIndex < sourceNode.childNodes.length; sourceNodeIndex++) {
				var currentNode = sourceNode.childNodes[sourceNodeIndex];
				switch (currentNode.nodeType) {
					case 1: // element
						processElementNode(currentNode, targetNode, dataObj);
						break;
					case 8: // comment
						var targetCommentNode = currentNode.cloneNode(true); // copy comment node
						targetNode.appendChild(targetCommentNode); // copy comment node
						var commentMatch = currentNode.nodeValue.match(COMMENTSWITCHREGEX);
						if (commentMatch) {
							commentNodesToDisable.push(targetCommentNode);
							sourceNodeIndex = processCommentMethod[commentMatch[1].toLowerCase()](currentNode, sourceNodeIndex, sourceNode, targetNode, dataObj, targetCommentNode);
						} else {
							if (currentNode.nodeValue.match(COMMENTENDREGEX)) {
								commentNodesToDisable.push(targetNode.lastChild);
							}
							// don't try to replace any data flags in html that has been commented out
							targetCommentNode.nodeValue = targetCommentNode.nodeValue.replace(/\^/g, '*');
						}
						break;
					default:
						targetNode.appendChild(currentNode.cloneNode(true));
				}
			}
		}

		function processElementNode(currentNode, targetNode, dataObj) {
			if (currentNode.hasAttribute(TOOLLOADFLAG)) {
				// create a comment node in the target then process the tool load instruction
				var commentNode = document.createComment(currentNode.nodeName + ' toolload ' + currentNode.getAttribute(TOOLLOADFLAG));
				targetNode.appendChild(commentNode);
				processToolLoadComment(currentNode, null, null, null, null, commentNode);
				return;
			}
			if (currentNode.attributes[DYNAMICONNODEFLAG]) {
				// node is to be replaced by a comment and node will be reproduced when asked for by label
				// get the information
				var dynamicInfo = me.dynamicOn.parseInstructions(currentNode.attributes[DYNAMICONNODEFLAG].nodeValue);
				// returns {docount, label, toolName, direction, initial}
				if (dynamicInfo) {
					// add a comment node as the place holder
					targetNode.appendChild(document.createComment('dynamicOn ' + dynamicInfo.label + ' ' + dynamicInfo.toolName + ' ' + dynamicInfo.direction + ' node place holder'));
					var tempNode = document.createElement('div');
					tempNode.appendChild(currentNode.cloneNode(true));
					tempNode.firstChild.removeAttribute(DYNAMICONNODEFLAG);
					me.dynamicOn.push(dynamicInfo.label, 'node', tempNode, currentNode, targetNode.lastChild, dynamicInfo.direction, dynamicInfo.doCount);
					if (dynamicInfo.initial) {
						me.dynamicOn.get(dynamicInfo.label, dataObj, null, null, true); // true for don't replace action flags
					}
				} else {
					throw new Error('can\'t process html, incorrectly formatted data-dynamicon attribute ' + currentNode.attributes[DYNAMICONNODEFLAG].nodeValue);
				}
			} else if (currentNode.attributes[LOOPONFLAG]) {
				processLooponAttributeNode(currentNode, targetNode, dataObj);
			} else {
				var tempTarget = currentNode.cloneNode(false);
				processHtmlLoops(currentNode, tempTarget, dataObj);
				targetNode.appendChild(tempTarget);
			}
		}

		function processLooponAttributeNode(currentNode, targetNode, dataObj) {
			var loopDataName = currentNode.attributes[LOOPONFLAG].nodeValue.trim();
			// get loop on data
			// we need an array back from getValue so use true to ignore requirement for scalar values
			var dataToLoopOn = getValue(loopDataName, dataObj, true);
			// clone the current node, need to do this becuase processHtmlLoops uses the nodes inside the div passed to it
			var sourceNode = currentNode.parentNode.cloneNode(false);
			sourceNode.appendChild(currentNode.cloneNode(true));
			// remove the attribute so we don't loop forever
			sourceNode.firstChild.removeAttribute(LOOPONFLAG);
			// reproduce node using data
			reproduceNodesInLoopOn(sourceNode, targetNode, dataToLoopOn, dataObj, loopDataName);
		}

		function processLoopComment(currentNode, sourceNodeIndex, sourceNode, targetNode, dataObj, targetCommentNode) {
			var loopComment = currentNode.nodeValue.match(LOOPONREGEX);
			// get loop on data
			// we need an array back from getValue so use true to ignore requirement for scalar values
			var dataToLoopOn = getValue(loopComment[1], dataObj, true);
			// remember all nodes inside loop
			var loopData = getEnclosedNodes(sourceNode, LOOPONREGEX, LOOPENDREGEX, sourceNodeIndex);
			var loopNodes = loopData.enclosedNodes;
			sourceNodeIndex = loopData.nextNodeIndex;
			// reproduce nodes using data
			reproduceNodesInLoopOn(loopNodes, targetNode, dataToLoopOn, dataObj, loopComment[1]);
			return sourceNodeIndex;
		}

		function reproduceNodesInLoopOn(loopNodes, targetNode, dataToLoopOn, dataObj, loopDataName) {
			for (var dataRow in dataToLoopOn) {
				dataObj.currentLoopData[loopDataName] = dataToLoopOn[dataRow];
				dataObj.currentLoopRecurseData.push(dataObj.currentLoopData[loopDataName]);
				dataObj.currentLoopData[loopDataName + '_PropertyName'] = dataRow;
				var tempTarget = document.createElement('div');
				processHtmlLoops(loopNodes, tempTarget, dataObj);
				replaceDataFlags(tempTarget, dataObj);
				while (tempTarget.childNodes.length > 0) {
					targetNode.appendChild(tempTarget.childNodes[0]);
				}
				delete dataObj.currentLoopData[loopDataName + '_PropertyName'];
				delete dataObj.currentLoopData[loopDataName];
				dataObj.currentLoopRecurseData.pop();
			}
		}

		function processDynamicOnComment(currentNode, sourceNodeIndex, sourceNode, targetNode, dataObj, targetCommentNode) {
			var dynamicComment = currentNode.nodeValue.match(DYNAMICONREGEX);
			// dymamic on for loading tools
			// save pointer this node (name type result domNode beforeAfter)
			// [1] = dynamic on label, tool name, optional ascending/descending
			// get the information
			var dynamicInfo = me.dynamicOn.parseInstructions(dynamicComment[1]);
			// returns {docount, label, toolName, direction, initial}
			// tools are ALWAYS deferred so initial isn't an option in the comment and is ignored in the return!
			if (dynamicInfo) {
				me.dynamicOn.push(dynamicInfo.label, 'tool', dynamicInfo.toolName, currentNode, targetCommentNode, dynamicInfo.direction, dynamicInfo.doCount);
			} else {
				throw new Error('can\'t process html, incorrectly formatted DYNAMIC ON	comment ' + currentNode.nodeValue);
			}
			return sourceNodeIndex;
		}

		/**
		 * Collect the nodes between the RECURSE ON and RECURSE END comments.
		 * If the current data being processed has the property named as what will be recursed on then
		 * it creates entries in currentRecurseObj and currentLoopRecurseData in the dataObj and processes the recurse nodes.
		 * @param node currentNode
		 * @param int sourceNodeIndex
		 * @param node sourceNode
		 * @param node targetNode
		 * @param object dataObj
		 * @returns int the index of the next node to be processed
		 */
		function processRecurseOnComment(currentNode, sourceNodeIndex, sourceNode, targetNode, dataObj, targetCommentNode) {
			var recurseOnComment = currentNode.nodeValue.match(RECURSEONREGEX);
			// recurse the html anytime the specified property exists in the data
			// remember all nodes inside loop
			var recurseObj = {};
			var recurseData = getEnclosedNodes(sourceNode, RECURSEONREGEX, RECURSEENDREGEX, sourceNodeIndex);
			recurseObj.recurseNodes = recurseData.enclosedNodes;
			// reproduce nodes using data
			if (Object.prototype.hasOwnProperty.call(dataObj, recurseOnComment[1])) {
				dataObj.currentRecurseObj[recurseOnComment[1]] = recurseObj;
				dataObj.currentLoopRecurseData.push(dataObj[recurseOnComment[1]]);
				var tempTarget = document.createElement('div');
				processHtmlLoops(recurseObj.recurseNodes, tempTarget, dataObj);
				replaceDataFlags(tempTarget, dataObj);
				while (tempTarget.childNodes.length > 0) {
					targetNode.appendChild(tempTarget.childNodes[0]);
				}
				delete dataObj.currentRecurseObj[recurseOnComment[1]];
				dataObj.currentLoopRecurseData.pop();
			}
			return recurseData.nextNodeIndex // sourceNodeIndex;
		}

		function processRecursePlaceComment(currentNode, sourceNodeIndex, sourceNode, targetNode, dataObj, targetCommentNode) {
			var recursePlaceComment = currentNode.nodeValue.match(RECURSEPLACEREGEX);
			// recurse if the most recent loop or recurse data object has
			// the recurse property name specified
			var recurseDataName = recursePlaceComment[1];
			var recursePropertyName = recursePlaceComment[2] == undefined ? recurseDataName : recursePlaceComment[2];
			if (dataObj.currentLoopRecurseData.length > 0) {
				var currentRecurseData = dataObj.currentLoopRecurseData[dataObj.currentLoopRecurseData.length - 1];
				if (Object.prototype.hasOwnProperty.call(currentRecurseData, recursePropertyName)) {
					var recurseDataObj = gaerdvark.utils.cloneObject(dataObj); // shallow clone fails on outline
					recurseDataObj[recurseDataName] = recurseDataObj.currentLoopRecurseData[recurseDataObj.currentLoopRecurseData.length - 1][recursePropertyName];
					var tempTarget2 = document.createElement('div');
					processHtmlLoops(dataObj.currentRecurseObj[recurseDataName].recurseNodes, tempTarget2, recurseDataObj);
					replaceDataFlags(tempTarget2, dataObj);
					while (tempTarget2.childNodes.length > 0) {
						targetNode.appendChild(tempTarget2.childNodes[0]);
					}
				}
			}
			return sourceNodeIndex;
		}

		function processDynamicOnPlaceComment(currentNode, sourceNodeIndex, sourceNode, targetNode, dataObj, targetCommentNode) {
			var dynamicOnPlaceComment = currentNode.nodeValue.match(DYNAMICONPLACEREGEX);
			// recurse using dynamic on label if the most recent loop or recurse data object has
			// the dynamicon data property name specified
			var dynamicOnLabel = dynamicOnPlaceComment[1];
			var dynamicOnDataName = dynamicOnPlaceComment[2];
			if ((dataObj.currentLoopRecurseData.length > 0)
					&& (Object.prototype.hasOwnProperty.call(dataObj.currentLoopRecurseData[dataObj.currentLoopRecurseData.length - 1], dynamicOnDataName))) {
				var dynamicOnDataObj = gaerdvark.utils.cloneObject(dataObj);  // shallow clone fails on outline in processRecursePlaceComment
				dynamicOnDataObj[dynamicOnDataName] = dynamicOnDataObj.currentLoopRecurseData[dynamicOnDataObj.currentLoopRecurseData.length - 1][dynamicOnDataName];
				var tempTarget2 = document.createElement('div');
				processHtmlLoops(me.dynamicOn.getFirstMatchingNode(dynamicOnLabel), tempTarget2, dynamicOnDataObj);
				replaceDataFlags(tempTarget2, dataObj);
				while (tempTarget2.childNodes.length > 0) {
					targetNode.appendChild(tempTarget2.childNodes[0]);
				}
			}
			return sourceNodeIndex;
		}

		function processExecuteComment(currentNode, sourceNodeIndex, sourceNode, targetNode, dataObj, targetCommentNode) {
			var executeComment = currentNode.nodeValue.match(EXECUTEFUNCTIONREGEX);
			// execute the function(s) specified now, passing the arguments specified and the current node, current data, node tree
			var funcObjArray = getFunctionsFromString(executeComment[1]); // returns array of functions:args objects
			for (var f = 0; f < funcObjArray.length; f++) {
				funcObjArray[f].args.unshift({"ourTarget":targetCommentNode, "dataObj":dataObj, "targetNode":targetNode}); // send at least part of the event object
				funcObjArray[f].ptr.apply(me, funcObjArray[f].args); // execute the function
			}
			return sourceNodeIndex;
		}

		function processToolLoadComment(currentNode, sourceNodeIndex, sourceNode, targetNode, dataObj, targetClonedNode) {
			var temp;
			// get the tool load instructions and mark the node as processed
			if (currentNode.nodeType === 8) {
				// comment node
				temp = currentNode.nodeValue;
			} else {
				// make the string look like the comment would have
				temp = "Tool Load " + currentNode.getAttribute(TOOLLOADFLAG);
			}
			// clean up the white space
			temp = temp.replace(/\s+/g," ");

			var toolLoadInstructions = temp.match(TOOLLOADREGEX);
			// comment or toolload node being replaced by a tool
			// get the list of tools
			var parsedActionValue = separateToolAndArguments(toolLoadInstructions[1]);
			// parsedActionValue [1] is the tool [2] is the arguments
			if ((parsedActionValue) && (parsedActionValue[1])) {
				var toolName = parsedActionValue[1];
				// add the tool to the requested list
				if(!htmlToolsRequested[toolName]) {
					var tools = toolName.split(',');
					htmlToolsRequested[toolName] = {"nodes":[], "available":false, "tools":[], "waiting":tools.length}; // tool not available and not placed
					// request the tool
					for(var i = 0; i < tools.length; i++) {
						var loadToolParam = new LoadToolParam(tools[i].trim(), htmlRequestedToolLoaded);
						loadToolParam.forTools = toolName;
						loadTool(loadToolParam);
					}
				}
				// keep a pointer to the comment node and mark it as not yet placed
				htmlToolsRequested[toolName].nodes.push({"node":targetClonedNode, "placed":false});
			}
			return sourceNodeIndex;
		}

		function processIfStartIfElseComment(currentNode, sourceNodeIndex, sourceNode, targetNode, dataObj, targetCommentNode) {
			var ifStartComment = currentNode.nodeValue.match(IFSTARTREGEX);
			var ifElseComment = currentNode.nodeValue.match(IFELSEREGEX);
			if (ifStartComment) {
				replaceDataFlags(targetCommentNode, dataObj, true);
				targetCommentNode.nodeValue = targetCommentNode.nodeValue.replace(/\s+/g, " ");
				ifStartComment = targetCommentNode.nodeValue.match(IFSTARTREGEX);
				var condition = ifStartComment[1].trim();
				if (condition == '') condition = 'false';
				var ifEval;
				var evalStr = 'ifEval = (' + (condition) + ');';
				eval(evalStr);
			}
			if ((ifElseComment) || ((ifStartComment) && (!ifEval))) {
				// tempNode was not true or it was true and now there is an else side to skip, so ignore nodes until else or end is found
				var ifCount = 1;
				do {
					try{
						sourceNodeIndex++;
						if (sourceNode.childNodes[sourceNodeIndex].nodeType == 8) {
							if (sourceNode.childNodes[sourceNodeIndex].nodeValue.search(IFSTARTREGEX) > -1) {
								ifCount++;
							} else if (sourceNode.childNodes[sourceNodeIndex].nodeValue.search(IFENDREGEX) > -1) {
								ifCount--;
								targetNode.appendChild(sourceNode.childNodes[sourceNodeIndex].cloneNode(true)); // copy the end comment
								commentNodesToDisable.push(targetNode.lastChild);
							} else if ((sourceNode.childNodes[sourceNodeIndex].nodeValue.search(IFELSEREGEX) > -1) && (ifCount == 1)) {
								ifCount--;
								targetNode.appendChild(sourceNode.childNodes[sourceNodeIndex].cloneNode(true)); // copy the else comment
								commentNodesToDisable.push(targetNode.lastChild);
							}
						}
					}catch(e){
						if(e.message.indexOf("sourceNode.childNodes[sourceNodeIndex] is undefined" > -1)){
							console.log("error detected in if comment processing. This could be a result of a no ended IF statement in HTML.");
						}
						throw e;
					}
				} while (ifCount);
			}
			return sourceNodeIndex;
		}

		function processIgnoreStartComment(currentNode, sourceNodeIndex, sourceNode, targetNode, dataObj, targetCommentNode) {
			// tempNode was not true or we it was true and now there is an else side to skip, so ignore nodes until else or end is found
			var ignoreCount = 1;
			do {
				sourceNodeIndex++;
				if (sourceNode.childNodes[sourceNodeIndex].nodeType == 8) {
					if (sourceNode.childNodes[sourceNodeIndex].nodeValue.search(IGNORESTARTREGEX) > -1) {
						ignoreCount++;
					} else if (sourceNode.childNodes[sourceNodeIndex].nodeValue.search(IGNOREENDREGEX) > -1) {
						ignoreCount--;
					}
				}
			} while (ignoreCount);
			return sourceNodeIndex;
		}

		/**
		 * collect all the nodes
		 */

		function getEnclosedNodes(sourceNode, enclosingOnRegex, enclosingEndRegex, nodeIndex) {
			var enclosedNodes = document.createElement('div');
			var insideEnclosures = 1;
			do {
				nodeIndex++;
				if (sourceNode.childNodes[nodeIndex].nodeType == 8) {
					if (sourceNode.childNodes[nodeIndex].nodeValue.search(enclosingOnRegex) > -1) {
						insideEnclosures++;
					} else if (sourceNode.childNodes[nodeIndex].nodeValue.search(enclosingEndRegex) > -1) {
						insideEnclosures--;
						if (insideEnclosures == 0) break;
					}
				}
				enclosedNodes.appendChild(sourceNode.childNodes[nodeIndex].cloneNode(true));
			} while (insideEnclosures > 0);
			nodeIndex--; // back up one to get the ending instruction on the next pass
			return {"enclosedNodes":enclosedNodes, "nextNodeIndex":nodeIndex};
		}

		function htmlRequestedToolLoaded(script) {
			var ptr = htmlToolsRequested[script.forTools];
			ptr.waiting--;
			ptr.tools.push(script.tool);
			if(ptr.waiting <= 0) {
				htmlToolsRequested[script.forTools].available = true; // tool is available
				// if the tool that is currently being processed has been actived then add the requested tool to the dom
				if (rootNodesUpdated) {
					addHtmlRequestedTool(script.forTools);
				}
			}
		}

		function addHtmlRequestedTool(tool) {
			// find the nodes to replace withing this parent tools rootNode
			var requestedActionNodes = htmlToolsRequested[tool].nodes;
			for (var n = 0; n < requestedActionNodes.length; n++) {
				if (!requestedActionNodes[n].placed) {
					requestedActionNodes[n].placed = true; // tool has been placed in document
					var node = requestedActionNodes[n].node;
					var parsedActionValue;
					if (node.nodeType === 8) {
						parsedActionValue = separateToolAndArguments(node.nodeValue);
					} else {
						parsedActionValue = separateToolAndArguments(node.getAttribute(TOOLLOADED));
					}
					var args = [];
					var toolLoadArguments = parsedActionValue[2];
					if (toolLoadArguments) {
						try {
							eval('args = returnTheArguments' + toolLoadArguments);
						} catch (e) {
							console.log(e);
						}
					}
					var toolLoadInstance = group.createTool(htmlToolsRequested[tool].tools); // create the tool
					toolLoadInstance.setParentTool(group.tool);
					toolLoadInstance.appendToNode(requestedActionNodes[n].node.parentNode, null, requestedActionNodes[n].node, true); // give it a node after the comment node
					toolLoadInstance.activate.apply(window, args); // activate the tool
				}
			}
		}

		/**
		 *	replacement for var toolLoadNameArgRegex = /^\s*(\S[^\(]+\S)\s*(\([^\)]*\))?\s*;?\s*$/; // separate the tool name from the arguments
		 *	becuase it doesn't work with parenthesis in the argument
		 *
		 *	@param string - toolname(args) string from comment
		 *	@return array - [loadToolString, toolName string, arguments string with starting and ending parenthesis]
		 */
		function separateToolAndArguments(loadToolStr) {
			var startArgParen = loadToolStr.indexOf('(');
			var endArgParen = loadToolStr.lastIndexOf(')');
			return [loadToolStr, loadToolStr.substring(0, startArgParen).trim(), loadToolStr.substring(startArgParen, endArgParen + 1)];
		}


		/**
		 * Creates the fail safe/redirect safe method to be used, must be in a seprate function so that
		 * the context of the function is not changed when called
		 *
		 * each entry in the funcObjArray is an object
		 * {"ptr":functionPointer, "Args":arrayOfArguments}
		 *
		 * @param funcObjArray array
		 * @returns {Function}
		 */
		function createRedirectSafeFunction(funcObjArray) {
			return function (evt) {
				var returnVal = false;
				try {
					// get a common name we can use to get to the node we are interested it
					// all of our code should reference evt.ourTarget
					if (!evt) evt = window.event;
					//ourTarget is the element that was clicked on
					evt.ourTarget = evt.target ? evt.target : evt.srcElement;
					//currentTarget is what the event is attached to
					if(!evt.currentTarget) {//IE 8-
						evt.currentTarget = evt.ourTarget;
						while(evt.currentTarget.getAttribute('data-on' + evt.type) == null) {
							evt.currentTarget = evt.currentTarget.parentElement;
							if(!evt.currentTarget.parentElement) {
								evt.currentTarget = evt.ourTarget;
								break;
							}
						}
					}
					// Safari does (or did) return the text node if the event was
					// directly related to it, this make it the parent of the text node
					if (evt.ourTarget.nodeType == 3) {
						evt.ourTarget = evt.ourTarget.parentNode;
					}
					for (var i = 0; i < funcObjArray.length; i++) {
						var args = gaerdvark.utils.cloneObject(funcObjArray[i].args);
						for(var arg in args) {
							if(typeof(args[arg]) == 'object' && args[arg].lit) {
								try {
									eval('args[arg] = ' + args[arg].lit);
								}
								catch (e) {
									throwError("attempting to call " + funcObjArray[i].ptr.name + " threw error when evaluating args[" + arg + "] = " + args[arg].lit, e);
									throw e;
								}
							}
						}
						args.unshift(evt);
						try {
							returnVal = funcObjArray[i].ptr.apply(me, args);
						} catch (e) {
							throwError("method: " + funcObjArray[i].ptr.name + " threw error while handling on" + evt.type + " \n Error Message: " + e.message, e);
						}
					}
				} catch(e) {
					throwError("createRedirectSafeFunction failed threw error", e);
				}
				return returnVal === true ? true : false;
			};
		}

		/**
		 * search for all dynamicOn nodes and comments and add unique data-doCount# string to the end
		 * used to ensure each node only gets placed in the doObject once
		 *
		 * @param node
		 * @param doCount int (do not pass on initial call, used only for recursion
		 * @returns count of dynamicOn nodes found
		 */
		function flagAllDynamicOn(node, doCount) {
			if (!processDynamicOn) return;
			if (typeof doCount != 'number') doCount = 0;
			if (node.nodeType == 1) { // element
				if (node.attributes[DYNAMICONNODEFLAG]) {
					// flag it
					node.attributes[DYNAMICONNODEFLAG].nodeValue += ' docount' + (++doCount);
				}
				for (var c = 0; c < node.childNodes.length; c++) {
					// check all the children
					doCount = flagAllDynamicOn(node.childNodes[c], doCount);
				}
			} else if (node.nodeType == 8) { // comment
				if (node.nodeValue.match(DYNAMICONREGEX)) {
					node.nodeValue += ' docount' + (++doCount);
				}
			}
			return doCount;
		}

		/**
		 * Checks to see if the function name given has permissions and if so that the user has
		 * permission to them
		 * This function used in IF START of some html
		 *
		 * @params string functionName the name of the function you want to check
		 * @params bool action optional, defaults to true, false means check a tool
		 */
		function permissionTo(functionName, action) {
			var checkFunction = hasActionPermission;
			if(action === false) {
				checkFunction = hasToolPermission;
			}
			var actions = permissionObj[functionName];
			if(actions) {
				for(var index in actions) {
					if(!checkFunction(actions[index], true)) {
						return false;
					}
				}
			}
			return true;
		}

		function disableProcessedCommentNodes() {
			var commentCount = commentNodesToDisable.length;
			for (var x = 0; x < commentCount; x++) {
				commentNodesToDisable[x].nodeValue = ". " + commentNodesToDisable[x].nodeValue;
			}
		}

		this.setRootNodesUpdated = function(booleanValue) {
			rootNodesUpdated = booleanValue ? true : false;
		};

		this.checkForHtmlAddedTools = function() {
			for (var action in htmlToolsRequested) {
				if (htmlToolsRequested[action].available) {
					addHtmlRequestedTool(action);
				}
			}
		};

		this.processHtmlNode = function (source, target, data) {
			if (!data) data = {};
			data.currentLoopData = {};
			data.currentRecurseObj = {};
			data.currentLoopRecurseData = [];
			processHtmlLoops(source, target, data);
			replaceDataFlags(target, data);
			delete data.currentLoopData;
			delete data.currentRecurseObj;
			delete data.currentLoopRecurseData;
		};

		/**
		 *	processHtml places the html string into a div then walks the node tree expanding
		 *	loops and replacing data flags with the values from the data object.
		 *	It then calls the post process if provided and AFTER the post process it then replaces the event
		 *	tools with the pointers provided in the tool object.
		 *
		 * @param innerHTMLstr mixed a html string or a single node element with or without children
		 * @param dataObj object (optional) data structure result from stored procedure (select from db)
		 * @param actionObject object (optional) object containing functions named the same as what is identified in the html events
		 * 			or executed during the processing with the Execute Function instruction.
		 * 			If actionObject is undefined or empty and a previous call was made to processHtml the previous actionObject will be used again.
		 * @param permissionObject object (optional) used to define what actions if any are needed for actions in the action object
		 * @param postProcess method pointer (optional) callback function (this object with completeObj property added will be passed as argument)
		 * @param {boolean} noDynamicOn set to true to ignore dynamicOn flags, e.g. for placeholder html where dynamicOn is useless
		 * @param {boolean} noDataToolLoad set to truo to stop the final ckecked for missed data-toolload attributes
		 *     noDataToolLoad was added so audio could be included in places like decorated content
		 *     we may have to do more later
		 */
		this.processHtml = function (innerHTMLstr, dataObj, actionObject, permissionObject, postProcess, noDynamicOn, noDataToolLoad) {

			// set up an error message so previous html tree will not be returned by mistake
			currentHTMLTree = document.createElement('div');

			if(innerHTMLstr === null || innerHTMLstr === undefined){
				currentHTMLTree.innerHTML = 'No html or dom node element was provided to processHtml.';
				return;
			}

			currentHTMLTree.innerHTML = 'An unexpected error occured while processing html.';

			var pihsObj = {
				'innerHTMLstr' :innerHTMLstr
				,'actionObj' : actionObject ? actionObject : {}
				,'permissionObj' : permissionObject ? permissionObject : {}
				,'dataObj' : dataObj ? gaerdvark.utils.cloneObject(dataObj, false) : {}
				,'postProcess' : postProcess
			};

			pihsObj.dataObj._ALL_DATA_ = gaerdvark.utils.cloneObject(pihsObj.dataObj, false);

			processDynamicOn = noDynamicOn ? false : true;
			var processDataToolLoad = noDataToolLoad ? false : true;

			me.dynamicOn.reset();

			// if provided action object is empty and stored actionObj is already set, don't replace the stored action object
			if ((gaerdvark.utils.propertyCount(pihsObj.actionObj) > 0) || (actionObj == null)) {
				// add default tools
				pihsObj.actionObj.addClassToNode = group.addClassToNode;
				pihsObj.actionObj.removeClassFromNode = group.removeClassFromNode;
				pihsObj.actionObj.toggleClassOfNode = group.toggleClassOfNode;
				pihsObj.actionObj.stopPropagation = group.stopPropagation;
				// make the tool object available everywhere in this class
				actionObj = pihsObj.actionObj;
			}

			permissionObj = pihsObj.permissionObj;

			try {
				rootNodesUpdated = false;
				var divObj = document.createElement('div');
				if (typeof(pihsObj.innerHTMLstr) == 'string') {
					// insert br then remove it, this is to fix ie because it does not instanciate the first comment node
					divObj.innerHTML = '<br />' + pihsObj.innerHTMLstr.trim();
					divObj.removeChild(divObj.getElementsByTagName('br')[0]);
				} else if ((typeof(pihsObj.innerHTMLstr) == 'object') && (pihsObj.innerHTMLstr).nodeName) {
					divObj.appendChild(pihsObj.innerHTMLstr.cloneNode(true)); // clone them so changes here don't change the original
				} else {
					throw new Error('argument is not a string or a node');
				}
				if (divObj.childNodes.length != 1) {
					throw new Error('view has no root node \n' + divObj.innerHTML);
				}
				pihsObj.completeObj = document.createElement('div');
				if (((pihsObj.dataObj) // there is a data obj
							&& (gaerdvark.utils.propertyCount(pihsObj.dataObj) > 0)) // and there is data
						|| (divObj.innerHTML.search(COMMENTFLAGSTHATMUSTBEPROCESSED))) { // or there is a comment that must be processed
					// find all the nodes with dynamicOn attribute and flag them so each only gets remembered once
					flagAllDynamicOn(divObj);

					commentNodesToDisable = [];
					me.processHtmlNode(divObj, pihsObj.completeObj, pihsObj.dataObj);
					disableProcessedCommentNodes();
				} else {
					// there is no dataObj or actionObj so don't waste time
					pihsObj.completeObj = divObj;
				}
				
				// run any global post processors, these come from the prototype.
				this.runPostProcessors(pihsObj);
				
				// now run any specific post processors for this call.
				if (pihsObj.postProcess) {
					pihsObj.postProcess(pihsObj);
				}
				
				
				// replaceActionFlags must be done for each instance of the finished html that is displayed on the page
				// the tools don't get copied when cloneNode is used
				currentHTMLTree = pihsObj.completeObj.firstChild;

				if ((currentHTMLTree.nodeType == 1)
						&& ((gaerdvark.utils.propertyCount(actionObj) > 3)
							|| (currentHTMLTree.innerHTML.indexOf('toggleClassOfNode'))
							|| (currentHTMLTree.innerHTML.indexOf('addClassToNode'))
							|| (currentHTMLTree.innerHTML.indexOf('removeClassFromNode'))
							|| (currentHTMLTree.innerHTML.indexOf('stopPropagation')))) {
					replaceActionFlags(currentHTMLTree);
				}

				if (processDataToolLoad) {
					// if there are tool load element in the html deal with them now
					checkForToolLoadElements(currentHTMLTree);
				}

			} catch (error) {
				console.log(error);
				throwError('could not processInnerHtml string', error);
			}
		};

		/*
		 * see processHtml below
		 */
		this.processHtmlAndReplace = function (innerHTMLstr, dataObj, actionObj, permissionObj, postProcess) {
			focusNode = null;
			me.processHtml(innerHTMLstr, dataObj, actionObj, permissionObj, postProcess);

			group.replaceRootNode();
			if (focusNode) {
				focusNode.focus();
				focusNode = null;
			}
		};

		/**
		 *
		 */
		this.processHtmlAndReturnTree = function (innerHTMLstr, dataObj, actionObject, permissionObject, postProcess, noDynamicOn, noDataToolLoad) {
			me.processHtml(innerHTMLstr, dataObj, actionObject, permissionObject, postProcess, noDynamicOn, noDataToolLoad);
			return this.getCurrentHtmlTree();
		}

		this.setCurrentHtmlTree = function (tree) {
			if (typeof (tree) == 'string') {
				var div = document.createElement('div');
				div.innerHTML = tree.trim();
				if (div.childNodes.length != 1) {
					throwError('view has no root node \n' + div.innerHTML);
					currentHTMLTree = document.createElement('div');
					currentHTMLTree.innerHTML = 'View has no root node.';
					return
				}
				currentHTMLTree = div.firstChild;
				return;
			}
			if ((typeof(tree) == 'object') && (tree.nodeName)) {
				currentHTMLTree = tree;
				return;
			}
			throwError('argument is not a string or a node');
			currentHTMLTree = document.createElement('div');
			currentHTMLTree.innerHTML = 'View is not a string or a node.';
		};

		this.getCurrentHtmlTree = function () {
			return currentHTMLTree;
		};

		this.unloadHtmlLoadedTools = function() {
			for (var toolName in htmlToolsRequested) {
				var tools = toolName.split(',');
				// unload each the tool
				for(var i = 0; i < tools.length; i++) {
					unloadTool(tools[i].trim());
				}
			}
			htmlToolsRequested = {};
		};

		/**
		 * Dynamic on XXXXXX tool YYYYYYY class
		 */
		this.dynamicOn = new function() {
			// doObject is one object for each dynamic on label
			// each property is an array of objects of {tool, htmlNode}
			var doObject = {}; // keep track of all dynamic on nodes

			/**
			 * creates an object to store details about the dynamic on instruction
			 *
			 * @param type string, "tool" or "node", must be lower case!
			 * @param result string, label of tool tool to load or a single node
			 * @param sourceNode
			 * @param domCommentNode node, node in dom where tool or html is to be placed
			 * @param direction string, "replace", "ascending", "descending", or "noplace"
			 * @param doCount
			 */
			function doObjectEntry(type, result, sourceNode, domCommentNode, direction, doCount) {
				this.type = type;
				this.result = result;
				this.sourceNode = sourceNode;
				this.domCommentNode = domCommentNode;
				this.domNode = null;
				this.lastToolInstance = null;
				this.direction = direction;
				this.doCount = doCount;
			}

			/**
			 * save each dynamic on instruction for later use
			 * htmlNode is the dynamic on comment node
			 * the tool will be appended just before this node
			 *
			 * @param label string, unique label for this dynamic on
			 * @param type string, "tool" or "node"
			 * @param result mixed, name of tool to load or a single node
			 * @param sourceNode node
			 * @param domCommentNode node, node in dom where tool or html is to be placed marked by a comment node
			 * @param direction string, "replace", "ascending" or "descending"
			 * @param doCount
			 *
			 */
			this.push = function(label, type, result, sourceNode, domCommentNode, direction, doCount) {
				if (!processDynamicOn) return;
				if (Object.prototype.hasOwnProperty.call(doObject, label)) {
					// if this comment has already been processed, just update the domCommentNode
					var thisDOLabel = doObject[label];
					for (var x = 0; x < thisDOLabel.length; x++) {
						if (thisDOLabel[x].doCount == doCount) {
							thisDOLabel[x].domCommentNode = domCommentNode;
							return;
						}
					}
				} else {
					doObject[label] = [];
				}
				doObject[label].push(new doObjectEntry(type, result, sourceNode, domCommentNode, direction, doCount));
				return;
			};

			/**
			 * parse the instructions and return the individual parts
			 * returned object will contain 5 properties
			 *  doCount (int)
			 *	 label (label name)
			 *	 toolName (tool if any)
			 *	 direction (ascending, descending, replace, or noplace) replace is default
			 *	 initial (boolean)
			 *
			 * @param instructions string
			 * @return mixed object on success, otherwise false
			 */
			this.parseInstructions = function(instructions) {
				var initial = false;
				// get and remove the label and docount from the instructions
				var result = instructions.match(/^\s*(\S+)(.*)\s+docount(\d+)\s*$/i);
				var label = result[1];
				instructions = result[2];
				var doCount = result[3];
				result = instructions.match(/^\s*(.*)\s+initial\s*$/i); // was initial specified
				var direction = 'replace';
				if (result) {
					initial = true;
					instructions = result[1]; // get the string without initial
				}
				result = instructions.match(/^\s*(.*)\s*(ascending|descending|replace|noplace)\s*$/);
				if (result) {
					instructions = result[1]; // get the tool name or label without the direction
					direction = result[2];
				}
				if (label) {
					return {"doCount":doCount, "label":label, "toolName":instructions.trim(), "direction":direction, "initial":initial};
				} else {
					return false;
				}
			};

			/**
			 * if label exists in dynamic on from html
			 * return an array of tools created from the tools
			 * associated with the dynamic on instructions
			 * each tool will be activated passing the data
			 *
			 * if parentNode is specified all matching dynamic on html and tools will be created at that
			 *		location ignoring any replace and previous instructions in the dynamic on comment or node
			 *
			 * @param label string
			 * @param data array of arguments (optional) to be passed to activate (getData of tool)
			 *		or a data object to pass to processHtmlLoops for a node
			 * @param parentNode dom node (optional) parent node to place new node into (if not null overrides ascending/descending)
			 * @param beforeNode dom node (optional) only used if parentNode is specified
			 *		sibling node to place new node before, null to place at end (overrides ascending/descending)
			 * @param dontReplaceActionFlags boolean (optional) if true action flags will not be replaced, used with initial option
			 * @param verifyToolLoadElementsAreProcessed boolean (optional) if true data-toolload elements
			 *		that were placed by a data argument will be searched for in the finished dynamicOn html
			 * @return array of object with properties for type, value, and focusNode if available.  type is 'tool' or 'node'
			 */
			this.get = function(label, data, parentNodeArg, beforeNode, dontReplaceActionFlags, verifyToolLoadElementsAreProcessed) {
				var resultArray = [];
				var parentNode;
				var doReplace = parentNodeArg ? false : true; // if parentNode specified ignore any replace instructions
				var replaceActionFlags = !dontReplaceActionFlags;
				if (Object.prototype.hasOwnProperty.call(doObject, label)) {
					var doEvent = doObject[label];
					for (var i = 0; i < doEvent.length; i++) {
						if (parentNodeArg) {
							if (beforeNode == undefined) beforeNode = null;
							parentNode = parentNodeArg;
						} else {
							var insertAfter = (doEvent[i].direction.toLowerCase() == 'descending'); // convert to boolean
							var commentNode = doEvent[i].domCommentNode;
							var oldNode = doEvent[i].domNode;
							//A tool may have replaced the oldNode pointer
							if (doEvent[i].type == 'tool' && doEvent[i].lastToolInstance) {
								oldNode = doEvent[i].lastToolInstance.getRootNode();
							}

							beforeNode = insertAfter ? commentNode.nextSibling : commentNode;
							parentNode = commentNode.parentNode;
						}

						if (doEvent[i].type == 'tool') {
							// instanciate the tool and add the root node
							var toolLoadInstance = group.createTool(doEvent[i].result); // create the tool
							toolLoadInstance.appendToNode(parentNode, null, beforeNode); // give it a node before the sibling node or at the end of the parent
							var initData = data ? data : [];
							if(!(initData instanceof Array)) {
								initData = [initData];
							}
							toolLoadInstance.activate.apply(window, initData);
							resultArray.push({"type":"tool", "value":toolLoadInstance});
						} else {
							var divNode = document.createElement('div');
							// processData
							focusNode = null;
							processDynamicOn = true;
							commentNodesToDisable = [];
							me.processHtmlNode(doEvent[i].result, divNode, data);
							if (verifyToolLoadElementsAreProcessed) {
								checkForToolLoadElements(divNode);
							}
							disableProcessedCommentNodes();
							if (replaceActionFlags) checkForActionFlags(divNode.firstChild);
							// keep pointer to nodes in resultArray before inserting into dom so we don't loose it
							resultArray.push({"type":"node", "value":divNode.firstChild, "focusNode":focusNode});
							// append nodes to the dom at the Dynamic on comment
							if (doEvent[i].direction.toLowerCase() != 'noplace') {
								parentNode.insertBefore(divNode.firstChild, beforeNode);
								if (focusNode) focusNode.focus();
							}
							me.checkForHtmlAddedTools();
							focusNode = null;
						}
						if ((doReplace) && (doEvent[i].direction.toLowerCase() == 'replace')) {
							doEvent[i].domNode = (beforeNode) ? beforeNode.previousSibling : parentNode.lastChild;
							if (doEvent[i].type == 'tool') {
								if(doEvent[i].lastToolInstance) doEvent[i].lastToolInstance.destruct();
								doEvent[i].lastToolInstance = toolLoadInstance;
							} else {
								if (oldNode && oldNode.parentNode == parentNode) parentNode.removeChild(oldNode);
							}
						}
					}
				} else {
					throwError('Dynamic On ' + label + ' requested but not provided in html');
				}
				return resultArray;
			};

			/**
			 * return a clone of the first node type found matching the label
			 * otherwise returns null
			 */
			this.getFirstMatchingNode = function(label) {
				if (!processDynamicOn) return null;
				if (Object.prototype.hasOwnProperty.call(doObject, label)) {
					var doEvent = doObject[label];
					for (var i = 0; i < doEvent.length; i++) {
						if (doEvent[i].type == 'node') {
							return doEvent[i].result.cloneNode(true);
						}
					}
				} else {
					throwError('Dynamic On ' + label + ' requested but not provided in html', new Error('Dynamic on error'));
				}
				return null;
			};

			/**
			 *	Used to put the dynamic on object back to its initial state
			 */
			this.reset = function() {
				if (!processDynamicOn) return;
				doObject = {}; // keep track of all dynamic on nodes
			};
		};

	};
	
	/**
	 * Internal shared property for accessing all of the global processors 
	 */
	ProcessHtml.prototype._processors = [];
	
	/**
	 * Runs all of the global processors that are needed to be executed with their custom rules.
	 */
	ProcessHtml.prototype.runPostProcessors = function(processObj) {
		if (gaerdvark.utils.isArray(ProcessHtml.prototype._processors) && ProcessHtml.prototype._processors.length) {
			try {
				// the process obj is worked on by reference.... not sure I like this but that is the way it is handled.
				gaerdvark.utils.forEachArray(ProcessHtml.prototype._processors, function(processor) {
					processor(processObj);
				});
			}
			catch (ex) {
				throwError("processor function threw error and postProcessing did not finish", ex);
			}
		}
	};
	
	/**
	 * Allows a processor function to be added to ProcessHtml for anyone in the system that wants to modify the HTML across
	 * the board.  This allows custom rule processing such as removing / adding functionality on a device / environment specific
	 * context that we may not want happening elsewhere.  One example of this is in the Dynactive iPad App.
	 * @param {function} The processor function to add that will receive the processObj before ProcessHTML returns control back to the Tool that called it.
	 */
	ProcessHtml.prototype.addPostProcessor = function(processor) {
		if (typeof processor != 'function') {
			throw new TypeError('processor must be a valid function');
		}
		ProcessHtml.prototype._processors.push(processor);
	};